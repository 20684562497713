import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Input from '@wtag/rcl-input';
import Button from '@wtag/rcl-button';
import SelectBox from '@wtag/rcl-select-box';
import { I18nText, CheckBox } from '@wtag/react-comp-lib';
import Alert from '@wtag/rcl-alert';
import routes from '../../../routes';
import httpClient from '../../../libraries/httpClient';
import selectBoxObjectMapper from '../../../helper/selectBoxObjectMapper';
import SignUpFormHeader from '../SignUpFormHeader';
import PhoneNumberInput from '../../PhoneNumberInput';

const OrganizationForm = ({ currencyOptions, planId, legalPaperRoutes }) => {
  const [acceptLegalPapers, setAcceptLegalPapers] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [proceedNext, setProceedNext] = useState(false);
  const [tenantName, setTenantName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userMobileNumber, setUserMobileNumber] = useState('');
  const [currency, setCurrency] = useState(null);
  const [errors, setErrors] = useState({});

  const createSignUp = () => {
    const params = {
      locale: I18n.locale,
      sign_up: {
        tenant_name: tenantName,
        user_email: userEmail,
        user_mobile_number: userMobileNumber,
        plan_id: planId,
        currency,
      },
    };
    httpClient
      .post(routes.signUp.create(), params)
      .then(() => setProceedNext(true))
      .catch(({ response }) => {
        if (response.status === 422) {
          setErrors(response.data.errors);
        }
        setProceedNext(false);
      });
  };

  const onNextClick = () => {
    setShowAlert(!acceptLegalPapers);

    if (acceptLegalPapers) {
      createSignUp();
    }
  };

  const onClickAcceptLegalPapers = (value) => {
    setAcceptLegalPapers(value);
    if (showAlert && value) {
      setShowAlert(false);
    }
  };

  const onInputChangeHandler = (inputValue, setState, errorKeyName) => {
    setState(inputValue);

    if (inputValue) {
      setErrors({ ...errors, [errorKeyName]: null });
    }
  };

  const handleCurrency = (option) => {
    const data = option ? option.value : null;

    onInputChangeHandler(data, setCurrency, 'currency');
  };

  const renderContent = () => {
    let content = (
      <div>
        <div className="sign-up__field">
          <Input
            onChange={(value) =>
              onInputChangeHandler(value, setTenantName, 'tenantName')
            }
            error={errors.tenantName}
            touched={true}
            label={
              <div className="required-field-wrapper">
                <I18nText id="sign_up.label.tenant_name" />
              </div>
            }
            value={tenantName}
          />
        </div>
        <div className="sign-up__field">
          <div className="sign-up__field-hint">
            <I18nText id="sign_up.organization.form.hint.user_email" />
          </div>
          <div>
            <Input
              onChange={(value) =>
                onInputChangeHandler(value, setUserEmail, 'userEmail')
              }
              error={errors.userEmail}
              touched={true}
              label={
                <div className="required-field-wrapper">
                  <I18nText id="sign_up.label.user_email" />
                </div>
              }
              value={userEmail}
            />
          </div>
        </div>
        <div className="sign-up__field">
          <div className="sign-up__field-hint">
            <I18nText id="sign_up.organization.form.hint.user_phone_number" />
          </div>
          <div>
            <PhoneNumberInput
              label={
                <div className="required-field-wrapper">
                  <I18nText id="sign_up.label.user_phone_number" />
                </div>
              }
              onChange={(value) =>
                onInputChangeHandler(
                  value,
                  setUserMobileNumber,
                  'userMobileNumber',
                )
              }
              error={errors.userMobileNumber}
              isInputTouched={errors.userMobileNumber !== null}
            />
          </div>
        </div>
        <div className="sign-up__field">
          <div className="sign-up__field-hint">
            <I18nText id="sign_up.organization.form.hint.currency" />
          </div>
          <div>
            <SelectBox
              errorMsg={errors.currency}
              options={currencyOptions}
              onChange={(option) => handleCurrency(option)}
              label={
                <div className="required-field-wrapper">
                  <I18nText id="sign_up.label.currency" />
                </div>
              }
              value={selectBoxObjectMapper(currencyOptions, currency)}
            />
          </div>
        </div>
        <div className="sign-up__field">
          <div className="sign-up__checkbox">
            <CheckBox
              label={
                <div className="sign-up__checkbox__legal-papers">
                  <span>
                    {I18n.t(
                      'components.sign_up.organization.form.legal_papers',
                    )}
                  </span>
                  <span>
                    <a href={legalPaperRoutes.policies.term}>
                      {I18n.t(
                        'components.sign_up.organization.form.legal_papers_term',
                      )}
                    </a>
                  </span>
                  <span>
                    {I18n.t(
                      'components.sign_up.organization.form.legal_papers_join',
                    )}
                  </span>
                  <span>
                    <a href={legalPaperRoutes.policies.privacy}>
                      {I18n.t(
                        'components.sign_up.organization.form.legal_papers_privacy',
                      )}
                    </a>
                  </span>
                </div>
              }
              size="small"
              onChange={(event) =>
                onClickAcceptLegalPapers(event.target.checked)
              }
            />
            <Alert
              className="sign-up__alert"
              isVisible={showAlert}
              type="danger"
              hideClose={true}
              isIconVisible={false}
            >
              <I18nText id="sign_up.organization.form.alert_text" />
            </Alert>
          </div>
        </div>
        <div>
          <Button
            version="v2"
            label={<I18nText id="sign_up.organization.form.button" />}
            onClick={onNextClick}
            type="primary"
            size="small"
          />
        </div>
      </div>
    );
    if (proceedNext) {
      content = (
        <div className="sign-up__field">
          <div className="sign-up__field-hint">
            <I18nText id="sign_up.organization.form.confirmation_text" />
          </div>
        </div>
      );
    }
    return content;
  };

  return (
    <div className="sign-up__form-container">
      <SignUpFormHeader
        step={1}
        text={<I18nText id="sign_up.organization.form.header" />}
      />
      {renderContent()}
    </div>
  );
};

OrganizationForm.propTypes = {
  currencyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  planId: PropTypes.number.isRequired,
  legalPaperRoutes: PropTypes.shape({
    policies: PropTypes.shape({
      term: PropTypes.string.isRequired,
      privacy: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default OrganizationForm;
