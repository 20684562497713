/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Alert as RclAlert } from '@wtag/react-comp-lib';

const Alert = props => {
  const { status } = props;

  const alertStatuses = {
    failure: 'urgent',
    success: 'success',
    notice: 'highlight',
  };

  return <RclAlert {...props} type={alertStatuses[status]} />;
};

Alert.defaultProps = {
  status: 'notice'
}

Alert.propTypes = {
  status: PropTypes.oneOf([ 'failure', 'success', 'notice'])
}

export default Alert;
