import React from 'react';
import PropTypes from 'prop-types';
import Careers from '../../../../shared/components/Careers/index';

// eslint-disable-next-line react/prop-types
const CareersBody = ({
  className,
  additionalProps,
  recaptchaKey,
  recaptchaWidgetSrc,
}) => {
  return (
    <div className={`container-full ${className}`}>
      <Careers
        additionalProps={additionalProps}
        recaptchaKey={recaptchaKey}
        recaptchaWidgetSrc={recaptchaWidgetSrc}
      />
    </div>
  );
};

CareersBody.defaultProps = {
  className: '',
  additionalProps: PropTypes.shape({
    signUpUrl: null,
  }),
};

CareersBody.propTypes = {
  className: PropTypes.string,
  additionalProps: PropTypes.shape({
    routes: PropTypes.shape({
      features: {
        platform: PropTypes.string,
      },
      pricing: {
        platform: PropTypes.string,
      },
      resources: {
        faqs: PropTypes.string,
        tutorials: PropTypes.string,
        glossary: PropTypes.string,
        documentation: PropTypes.string,
      },
      company: {
        about_us: PropTypes.string,
        careers: PropTypes.string,
        logos: PropTypes.string,
      },
      policies: {
        term: PropTypes.string,
        privacy: PropTypes.string,
        security: PropTypes.string,
      },
    }),
    recaptchaKey: PropTypes.string.isRequired,
    recaptchaWidgetSrc: PropTypes.string.isRequired,
    platformFeaturesRoutes: PropTypes.shape({
      platformFeaturesProfiles: PropTypes.string,
      platformFeaturesSearch: PropTypes.string,
      platformFeaturesBooking: PropTypes.string,
      platformFeaturesMobileApp: PropTypes.string,
      platformFeaturesInvoicing: PropTypes.string,
      platformFeaturesOnlineBookingTool: PropTypes.string,
      platformFeaturesReporting: PropTypes.string,
      platformFeaturesTaskManagement: PropTypes.string,
      platformFeaturesVisaAdvisory: PropTypes.string,
      platformFeaturesCorporateTravel: PropTypes.string,
      platformFeaturesLeisurePackages: PropTypes.string,
      platformFeaturesChat: PropTypes.string,
    }),
  }),
  recaptchaKey: PropTypes.string.isRequired,
  recaptchaWidgetSrc: PropTypes.string.isRequired,
};

export default CareersBody;
