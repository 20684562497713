import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@wtag/rcl-modal';
import Icon from '@wtag/rcl-icon';
import Button from '@wtag/rcl-button';
import Link from '@wtag/rcl-link';

const DowngradeModal = props => {
  const { isVisible, planName, planDetailPageUrl, onReject } = props;

  return (
    <Modal
      className="downgrade-modal"
      isVisible={isVisible}
      onOutsideClick={onReject}
    >
      <Modal.Title className="downgrade-modal__title" align="center">
        <Icon
          className="downgrade-modal__title-icon"
          name="warning"
          showBGColor={true}
          size="huge"
          color="warning"
        />
        <div className="downgrade-modal__title-text">
          {I18n.t(
            'components.pricing.plans.subscription.downgrade.modal.title',
          )}
        </div>
      </Modal.Title>
      <Modal.Content className="downgrade-modal__content" align="center">
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: I18n.t(
              'components.pricing.plans.subscription.downgrade.modal.content_html',
              {
                planName: I18n.t(planName, {
                  scope: 'components.plans',
                }),
              },
            ),
          }}
        />
      </Modal.Content>
      <Modal.Footer className="downgrade-modal__footer">
        <Link
          href={planDetailPageUrl}
          type="button"
          size="small"
          modifier="primary"
        >
          {I18n.t(
            'components.pricing.plans.subscription.downgrade.modal.confirm_action_text',
          )}
        </Link>
        <Button
          className="downgrade-modal__footer-button"
          label={I18n.t(
            'components.pricing.plans.subscription.downgrade.modal.rejection_action_text',
          )}
          type="default"
          size="small"
          onClick={onReject}
        />
      </Modal.Footer>
    </Modal>
  );
};

DowngradeModal.defaultProps = {
  isVisible: false,
  onReject: () => {},
};

DowngradeModal.propTypes = {
  isVisible: PropTypes.bool,
  planName: PropTypes.string.isRequired,
  planDetailPageUrl: PropTypes.string.isRequired,
  onReject: () => {},
};

export default DowngradeModal;
