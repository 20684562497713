import React, { useEffect, useState } from 'react';
import { debounce } from 'throttle-debounce';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Input from '@wtag/rcl-input';
import Button from '@wtag/rcl-button';
import {
  AccordionSidePanel,
  AccordionSidePanelItem,
  Spinner,
  I18nText,
} from '@wtag/react-comp-lib';

import SanitizedHTML from '../../../../shared/components/SanitizedHTML';

import httpClient from '../../../../shared/libraries/httpClient';
import routes from '../../../../shared/routes';

const MIN_LENGTH_TO_SEARCH = 1;

const searchFeatures = (featureList, searchParam) => {
  const filteredFeaturesBySearchParam = [];

  const filterByParam = featureList => {
    featureList.forEach(feature => {
      const nestedFeatureList = feature.nestedPlatformFeatures;
      const isMatched = feature.title.toLowerCase().includes(searchParam);

      if (isMatched) {
        filteredFeaturesBySearchParam.push(feature);
      } else if (nestedFeatureList?.length > 0) {
        filterByParam(nestedFeatureList);
      }
    });
  };

  filterByParam(featureList);

  return filteredFeaturesBySearchParam;
};

const PlatformFeaturesBody = ({ className }) => {
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [searchParam, setSearchParam] = useState('');
  const [features, setFeatures] = useState([]);
  const [mappedFeatures, setMappedFeatures] = useState([]);
  const [description, setDescription] = useState(null);
  const [isDescriptionLoading, setIsDescriptionLoading] = useState(false);
  const [initialPath, setInitialPath] = useState(null);
  const [isDescriptionDrawerVisible, setIsDescriptionDrawerVisible] = useState(
    false,
  );

  const onClickHandler = async feature => {
    setIsDescriptionLoading(true);
    setDescription(feature.description);

    if (feature.description) {
      setIsDescriptionDrawerVisible(true);
    }

    setIsDescriptionLoading(false);
  };

  const mapFeatures = (data, itemLevel) => {
    return data.map(feature => {
      return (
        <AccordionSidePanelItem
          key={feature.id}
          itemID={feature.id}
          title={feature.title}
          itemLevel={itemLevel ? itemLevel + 1 : 1}
          hasChildItems={feature.nestedPlatformFeatures?.length > 0}
          fetchChildItems={() => mapFeatures(feature?.nestedPlatformFeatures)}
          isInitiallyExplored={true}
          onClick={() => onClickHandler(feature)}
        />
      );
    });
  };

  const fetchFeatures = async () => {
    const { data } = await httpClient.get(
      routes.public.staticPages.platformFeatures(),
    );

    setFeatures(data.features);
  };

  useEffect(() => {
    fetchFeatures();
  }, []);

  useEffect(() => {
    setMappedFeatures(mapFeatures(features));
    setInitialPath(features.length > 0 ? features[0].id : null);
    setDescription(features.length > 0 ? features[0].description : null);

    if (features.length > 0) {
      setIsFirstLoad(false);
    }
  }, [features]);

  useEffect(
    debounce(1000, () => {
      const filteredFeatures =
        searchParam.length > MIN_LENGTH_TO_SEARCH
          ? searchFeatures(features, searchParam)
          : features;
      if (
        filteredFeatures.length > 0 ||
        searchParam.length > MIN_LENGTH_TO_SEARCH
      ) {
        setMappedFeatures(mapFeatures(filteredFeatures));
      }
    }),
    [searchParam],
  );

  const handleInputChange = value => {
    setSearchParam(value.toLowerCase());
  };

  const descriptionContent = () => {
    let content;
    if (isDescriptionLoading) {
      content = (
        <div className="feature-wrapper__content-description-spinner">
          <Spinner size="huge" bgColor="neutral" />
        </div>
      );
    } else if (description) {
      content = (
        <div className="feature-wrapper__content-description-container">
          <SanitizedHTML html={description} />
        </div>
      );
    } else {
      content = (
        <div className="feature-wrapper__content-description-empty-text">
          <I18nText id="heroTab.features.platform.empty_text" />
        </div>
      );
    }
    return content;
  };

  const content = isFirstLoad ? (
    <div className="feature-wrapper__spinner">
      <Spinner size="huge" bgColor="neutral" />
    </div>
  ) : (
    <div className="grid feature-wrapper__content">
      <div className="col-12 col-md-3">
        <div className="feature-wrapper__content-side-panel">
          {features.length > 0 && (
            <AccordionSidePanel
              className={className}
              exploredPath={[initialPath]}
            >
              {mappedFeatures}
            </AccordionSidePanel>
          )}
        </div>
      </div>
      <div className="col-9 hidden-xxs hidden-xs hidden-sm">
        <div className="feature-wrapper__content-description">
          {descriptionContent()}
        </div>
      </div>
    </div>
  );

  return (
    <div className="container-full">
      <div className="grid justify-center feature-wrapper">
        <div className="col-xlg-10 col-lg-10 col-md-10 col-12">
          <div className="grid">
            <div className="col-12 col-md-4">
              <Input
                placeholder={I18n.t('components.features.platform.search')}
                value={searchParam}
                onChange={handleInputChange}
                isClearable={true}
              />
            </div>
          </div>
          {content}
        </div>
      </div>
      <div
        className={classNames(
          'col-12',
          'col-bleed',
          'feature-wrapper-description-drawer',
          {
            'feature-wrapper-description-drawer--visible': isDescriptionDrawerVisible,
          },
        )}
      >
        <div className="feature-wrapper__content-description">
          <Button
            onClick={() => setIsDescriptionDrawerVisible(false)}
            label={<I18nText id="support.back" />}
            version="v2"
            size="small"
          />
          {descriptionContent()}
        </div>
      </div>
    </div>
  );
};

PlatformFeaturesBody.defaultProps = {
  className: '',
  additionalProps: PropTypes.shape({
    routes: null,
    platformFeaturesRoutes: null,
  }),
};

PlatformFeaturesBody.propTypes = {
  className: PropTypes.string,
  additionalProps: PropTypes.shape({
    routes: PropTypes.shape({
      features: {
        platform: PropTypes.string,
      },
      pricing: {
        platform: PropTypes.string,
      },
      resources: {
        faqs: PropTypes.string,
        tutorials: PropTypes.string,
        glossary: PropTypes.string,
        documentation: PropTypes.string,
      },
      company: {
        about_us: PropTypes.string,
        careers: PropTypes.string,
        logos: PropTypes.string,
      },
      policies: {
        term: PropTypes.string,
        privacy: PropTypes.string,
        security: PropTypes.string,
      },
    }),
    platformFeaturesRoutes: PropTypes.shape({
      platformFeaturesProfiles: PropTypes.string,
      platformFeaturesSearch: PropTypes.string,
      platformFeaturesBooking: PropTypes.string,
      platformFeaturesMobileApp: PropTypes.string,
      platformFeaturesInvoicing: PropTypes.string,
      platformFeaturesOnlineBookingTool: PropTypes.string,
      platformFeaturesReporting: PropTypes.string,
      platformFeaturesTaskManagement: PropTypes.string,
      platformFeaturesVisaAdvisory: PropTypes.string,
      platformFeaturesCorporateTravel: PropTypes.string,
      platformFeaturesLeisurePackages: PropTypes.string,
      platformFeaturesChat: PropTypes.string,
    }),
  }),
};

export default PlatformFeaturesBody;
