import React from 'react';
import PropTypes from 'prop-types';
import AboutUs from '../../../../shared/components/AboutUs/index';

const AboutUsBody = ({ className }) => {
  return (
    <div className={`${className}`}>
      <AboutUs />
    </div>
  );
};

AboutUsBody.defaultProps = {
  className: '',
};

AboutUsBody.propTypes = {
  className: PropTypes.string,
};

export default AboutUsBody;
