import React from 'react';
import { I18nText } from '@wtag/react-comp-lib';
import PropTypes from 'prop-types';

import { ENTERPRISE as EnterprisePlanName } from './plans';

const isEnterpirse = plan => {
  return plan.name === EnterprisePlanName;
};

const WorkspacesFeature = ({ plan }) => {
  if (isEnterpirse(plan)) {
    return I18n.t('components.billing.plan.status.5');
  }
  return plan.workspaces !== null ? (
    <div>
      {I18n.t(plan.workspaces, {
        scope: 'components.billing.plan.status',
      })}
    </div>
  ) : (
    <I18nText id="billing.plan.status.unlimited" />
  );
};

WorkspacesFeature.propTypes = {
  plan: PropTypes.shape({
    workspaces: PropTypes.number,
  }).isRequired,
};

export default WorkspacesFeature;
