import React from 'react';
import PropTypes from 'prop-types';

const SignUpIconHeader = ({ text, icon }) => {
  return (
    <div className="sign-up-icon">
      <div className="sign-up-icon__text">{text}</div>
      {icon && (
        <div className="sign-up-icon__icon">
          <img src={icon} alt="header-icon" />
        </div>
      )}
    </div>
  );
};

SignUpIconHeader.defaultProps = {
  icon: null,
};

SignUpIconHeader.propTypes = {
  text: PropTypes.node.isRequired,
  icon: PropTypes.node,
};

export default SignUpIconHeader;
