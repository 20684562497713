import platformFeaturesLogo from 'affiliateImages/tab-logos/box.svg';
import platformPricingLogo from 'affiliateImages/tab-logos/graph.svg';
import aboutUsLogo from 'affiliateImages/tab-logos/globe.svg';
import careersLogo from 'affiliateImages/tab-logos/certificate.svg';
import mediaLogo from 'affiliateImages/tab-logos/bulls_eye.svg';
import termsLogo from 'affiliateImages/tab-logos/document.svg';
import securityLogo from 'affiliateImages/tab-logos/sheild.svg';
import privacyLogo from 'affiliateImages/tab-logos/document_search.svg';
import faqsLogo from 'affiliateImages/tab-logos/headset.svg';
import documentationLogo from 'affiliateImages/tab-logos/document_seal.svg';
import glossaryLogo from 'affiliateImages/tab-logos/table_list.svg';
import tutorialsLogo from 'affiliateImages/tab-logos/laptop.svg';
import PlatformFeaturesBody from './tabBodies/PlatformFeaturesBody';
import PlatformPricingBody from './tabBodies/PlatformPricingBody';
import AboutUsBody from './tabBodies/AboutUsBody';
import CareersBody from './tabBodies/CareersBody';
import MediaBody from './tabBodies/MediaBody';
import TermsBody from './tabBodies/TermsBody';
import SecurityBody from './tabBodies/SecurityBody';
import PrivacyBody from './tabBodies/PrivacyBody';
import FaqBody from './tabBodies/Faq/FaqBody';
import DocumentationBody from './tabBodies/DocumentationBody';
import GlossaryBody from './tabBodies/Glossary/GlossaryBody';
import TutorialsBody from './tabBodies/TutorialsBody';

const TabContent = {
  platformFeatures: {
    body: PlatformFeaturesBody,
    logo: platformFeaturesLogo,
  },
  platformPricing: {
    body: PlatformPricingBody,
    logo: platformPricingLogo,
  },
  faqs: {
    body: FaqBody,
    logo: faqsLogo,
  },
  tutorials: {
    body: TutorialsBody,
    logo: tutorialsLogo,
  },
  glossary: {
    body: GlossaryBody,
    logo: glossaryLogo,
  },
  documentation: {
    body: DocumentationBody,
    logo: documentationLogo,
  },
  aboutUs: {
    body: AboutUsBody,
    logo: aboutUsLogo,
  },
  careers: {
    body: CareersBody,
    logo: careersLogo,
  },
  logos: {
    body: MediaBody,
    logo: mediaLogo,
  },
  tos: {
    body: TermsBody,
    logo: termsLogo,
  },
  privacy: {
    body: PrivacyBody,
    logo: privacyLogo,
  },
  security: {
    body: SecurityBody,
    logo: securityLogo,
  },
};

export default TabContent;
