import React from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';
import TabContent from './TabContent';
import HeroTabContainer from './HeroTabContainer';

const HeroTabWrapper = ({
  page,
  additionalProps,
  recaptchaKey,
  recaptchaWidgetSrc,
}) => {
  const { routes } = additionalProps;

  const featuresTabs = [
    {
      tabNum: 1,
      title: <I18nText id="heroTab.features.platform.title" />,
      slogan: <I18nText id="heroTab.features.platform.slogan" />,
      logo: TabContent.platformFeatures.logo,
      body: TabContent.platformFeatures.body,
      url: routes.features.platform,
    },
  ];
  const pricingTabs = [
    {
      tabNum: 1,
      title: <I18nText id="heroTab.pricing.platform.title" />,
      slogan: <I18nText id="heroTab.pricing.platform.slogan" />,
      logo: TabContent.platformPricing.logo,
      body: TabContent.platformPricing.body,
      url: routes.pricing.platform,
    },
  ];
  const resourcesTabs = [
    {
      tabNum: 1,
      title: <I18nText id="heroTab.resources.faq.title" />,
      slogan: <I18nText id="heroTab.resources.faq.slogan" />,
      logo: TabContent.faqs.logo,
      body: TabContent.faqs.body,
      url: routes.resources.faqs,
    },
    {
      tabNum: 2,
      title: <I18nText id="heroTab.resources.tutorials.title" />,
      slogan: <I18nText id="heroTab.resources.tutorials.slogan" />,
      logo: TabContent.tutorials.logo,
      body: TabContent.tutorials.body,
      url: routes.resources.tutorials,
    },
    {
      tabNum: 3,
      title: <I18nText id="heroTab.resources.glossary.title" />,
      slogan: <I18nText id="heroTab.resources.glossary.slogan" />,
      logo: TabContent.glossary.logo,
      body: TabContent.glossary.body,
      url: routes.resources.glossary,
    },
    // TODO: Enable documentation tab when it's ready to show
    // {
    //   tabNum: 4,
    //   title: <I18nText id="heroTab.resources.documentation.title" />,
    //   slogan: <I18nText id="heroTab.resources.documentation.slogan" />,
    //   logo: TabContent.documentation.logo,
    //   body: TabContent.documentation.body,
    //   url: routes.resources.documentation,
    // },
  ];
  const companyTabs = [
    {
      tabNum: 1,
      title: <I18nText id="heroTab.company.aboutus.title" />,
      slogan: <I18nText id="heroTab.company.aboutus.slogan" />,
      logo: TabContent.aboutUs.logo,
      body: TabContent.aboutUs.body,
      url: routes.company.aboutUs,
    },
    {
      tabNum: 2,
      title: <I18nText id="heroTab.company.careers.title" />,
      slogan: <I18nText id="heroTab.company.careers.slogan" />,
      logo: TabContent.careers.logo,
      body: TabContent.careers.body,
      url: routes.company.careers,
    },
    {
      tabNum: 3,
      title: <I18nText id="heroTab.company.logos.title" />,
      slogan: <I18nText id="heroTab.company.logos.slogan" />,
      logo: TabContent.logos.logo,
      body: TabContent.logos.body,
      url: routes.company.logos,
    },
  ];
  const policyTabs = [
    {
      tabNum: 1,
      title: <I18nText id="heroTab.policies.tos.title" />,
      slogan: <I18nText id="heroTab.policies.tos.slogan" />,
      logo: TabContent.tos.logo,
      body: TabContent.tos.body,
      url: routes.policies.term,
    },
    {
      tabNum: 2,
      title: <I18nText id="heroTab.policies.privacy.title" />,
      slogan: <I18nText id="heroTab.policies.privacy.slogan" />,
      logo: TabContent.privacy.logo,
      body: TabContent.privacy.body,
      url: routes.policies.privacy,
    },
    {
      tabNum: 3,
      title: <I18nText id="heroTab.policies.security.title" />,
      slogan: <I18nText id="heroTab.policies.security.slogan" />,
      logo: TabContent.security.logo,
      body: TabContent.security.body,
      url: routes.policies.security,
    },
  ];

  const getTabs = () => {
    switch (page) {
      case 'features':
        return featuresTabs;
      case 'pricing':
        return pricingTabs;
      case 'resources':
        return resourcesTabs;
      case 'company':
        return companyTabs;
      default:
        return policyTabs;
    }
  };

  return (
    <div className="hero-slider">
      <HeroTabContainer
        tabs={getTabs()}
        page={page}
        additionalProps={additionalProps}
        recaptchaKey={recaptchaKey}
        recaptchaWidgetSrc={recaptchaWidgetSrc}
      />
    </div>
  );
};

HeroTabWrapper.defaultProps = {
  additionalProps: PropTypes.shape({
    routes: null,
    platformFeaturesRoutes: null,
  }),
};

HeroTabWrapper.propTypes = {
  page: PropTypes.string.isRequired,
  additionalProps: PropTypes.shape({
    routes: PropTypes.shape({
      features: {
        platform: PropTypes.string.isRequired,
      },
      pricing: {
        platform: PropTypes.string.isRequired,
      },
      resources: {
        faqs: PropTypes.string.isRequired,
        tutorials: PropTypes.string.isRequired,
        glossary: PropTypes.string.isRequired,
        documentation: PropTypes.string.isRequired,
      },
      company: {
        about_us: PropTypes.string.isRequired,
        careers: PropTypes.string.isRequired,
        logos: PropTypes.string.isRequired,
      },
      policies: {
        term: PropTypes.string.isRequired,
        privacy: PropTypes.string.isRequired,
        security: PropTypes.string.isRequired,
      },
    }),
    platformFeaturesRoutes: PropTypes.shape({
      platformFeaturesProfiles: PropTypes.string,
      platformFeaturesSearch: PropTypes.string,
      platformFeaturesBooking: PropTypes.string,
      platformFeaturesMobileApp: PropTypes.string,
      platformFeaturesInvoicing: PropTypes.string,
      platformFeaturesOnlineBookingTool: PropTypes.string,
      platformFeaturesReporting: PropTypes.string,
      platformFeaturesTaskManagement: PropTypes.string,
      platformFeaturesVisaAdvisory: PropTypes.string,
      platformFeaturesCorporateTravel: PropTypes.string,
      platformFeaturesLeisurePackages: PropTypes.string,
      platformFeaturesChat: PropTypes.string,
    }),
  }),
  recaptchaKey: PropTypes.string.isRequired,
  recaptchaWidgetSrc: PropTypes.string.isRequired,
};

export default HeroTabWrapper;
