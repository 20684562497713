import React from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';
import organizationIcon from 'affiliateImages/sign-up/organization.svg';
import SignUpIconHeader from '../SignUpIconHeader';
import OrganizationForm from './OrganizationForm';

const SignUpOrganization = ({ currencyOptions, planId, routes }) => {
  return (
    <div className="sign-up">
      <div className="col-12 col-grid align-end">
        <div className="sign-up__sign-in">
          <div className="sign-up__sign-in-hint">
            <I18nText id="sign_up.organization.sign_in.hint" />
          </div>

          <a className="sign-up__sign-in-button" href={routes.signInUrl}>
            <I18nText id="sign_up.organization.sign_in.button_label" />
          </a>
        </div>
      </div>
      <div className="sign-up__container">
        <div className="col-6 sign-up__icon">
          <SignUpIconHeader
            icon={organizationIcon}
            text={<I18nText id="sign_up.organization.header" />}
          />
        </div>
        <div className="col-xlg-6 col-lg-6 col-md-6 col-12 sign-up__form">
          <OrganizationForm
            currencyOptions={currencyOptions}
            planId={planId}
            legalPaperRoutes={routes}
          />
        </div>
      </div>
    </div>
  );
};

SignUpOrganization.propTypes = {
  currencyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  planId: PropTypes.number.isRequired,
  routes: PropTypes.shape({
    signInUrl: PropTypes.string.isRequired,
  }).isRequired,
};

export default SignUpOrganization;
