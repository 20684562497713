import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';
import Button from '@wtag/rcl-button';
import Input from '@wtag/rcl-input';
import moment from 'moment';

import organizationIcon from 'affiliateImages/sign-up/organization.svg';
import SignUpIconHeader from '../SignUpIconHeader';
import SignUpFormHeader from '../SignUpFormHeader';
import httpClient from '../../../libraries/httpClient';
import routes from '../../../routes';
import PhoneNumberInput from '../../PhoneNumberInput';

const INITIAL_STEP = 'initial';
const CONFIRM_CODE = 'confirmCode';
const CHANGE_NUMBER = 'changeNumber';

// https://stackoverflow.com/questions/68397821/react-js-countdown-timer-using-moment-js
const calculateDuration = (eventTime) =>
  moment.duration(
    Math.max(eventTime - Math.floor(Date.now() / 1000), 0),
    'seconds',
  );

function CountDown({ eventTime, interval, onZero }) {
  if (!eventTime) {
    return null;
  }
  const [duration, setDuration] = useState(calculateDuration(eventTime));
  const timerRef = useRef(0);
  const timerCallback = useCallback(() => {
    setDuration(calculateDuration(eventTime));
  }, [eventTime]);

  useEffect(() => {
    timerRef.current = setInterval(timerCallback, interval);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [eventTime]);

  useEffect(() => {
    if (duration.asSeconds() === 0) {
      onZero();
    }
  }, [duration.toISOString()]);

  return (
    <span>
      {duration.minutes()}:{duration.seconds().toString().padStart(2, '0')}
    </span>
  );
}

CountDown.propTypes = {
  eventTime: PropTypes.number.isRequired,
  interval: PropTypes.number.isRequired,
  onZero: PropTypes.func.isRequired,
};

const SignUpConfirmation = ({
  signUpId,
  urls,
  initialPhoneNumber,
  initialSmsVerificationAvailableAt,
}) => {
  let initialStateForStep = INITIAL_STEP;
  if (initialSmsVerificationAvailableAt) {
    initialStateForStep = CONFIRM_CODE;
  }
  const [step, setStep] = useState(initialStateForStep);
  const [smsVerificationAvailableAt, setSmsVerificationAvailableAt] = useState(
    initialSmsVerificationAvailableAt
      ? moment(initialSmsVerificationAvailableAt)
      : null,
  );
  const [verificationCode, setVerificationCode] = useState('');
  const [resendAvailable, setResendAvailable] = useState(
    smsVerificationAvailableAt
      ? smsVerificationAvailableAt.isBefore(moment())
      : true,
  );
  const [userMobileNumber, setUserMobileNumber] = useState(initialPhoneNumber);
  const [errors, setErrors] = useState({});
  const [inputEnabled, setInputEnabled] = useState(false);

  const sendCode = () => {
    return httpClient
      .put(routes.signUp.requestPhoneValidation(signUpId))
      .then((response) => {
        setStep(CONFIRM_CODE);
        setSmsVerificationAvailableAt(
          moment(response.data.sendingAvailableAgainAt),
        );
        setResendAvailable(false);
        setInputEnabled(true);
      })
      .catch(({ response }) => {
        setInputEnabled(false);
        setStep(CONFIRM_CODE);
        if (response.status === 429 || response.status === 400) {
          if (response.data.sendingAvailableAgainAt) {
            setSmsVerificationAvailableAt(
              moment(response.data.sendingAvailableAgainAt),
            );
            setResendAvailable(false);
          }
          setErrors({
            base: I18n.t(response.data.error, {
              scope: 'components.sign_up.confirmation.errors',
            }),
          });
        }
      });
  };

  const validateCode = () => {
    const params = { code: verificationCode };
    return httpClient
      .put(routes.signUp.validatePhoneNumber(signUpId), params)
      .then(response => {
        window.location.replace(response.data.nextStepUrl);
      })
      .catch(({ response }) => {
        if (response.status === 400) {
          setErrors({
            base: I18n.t('components.sign_up.confirmation.validation_failed'),
          });
        }
      });
  };

  const updatePhoneNumber = () => {
    const params = {
      locale: I18n.locale,
      user_mobile_number: userMobileNumber,
    };
    return httpClient
      .put(routes.signUp.updatePhoneNumber(signUpId), params)
      .then(response => {
        setUserMobileNumber(response.data.phoneNumber);
        setStep(CONFIRM_CODE);
        setErrors({});
        if (resendAvailable) {
          sendCode();
        }
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          setErrors(response.data.errors);
        }
      });
  };

  const setMobileVerificationCode = code => {
    const digitsAsCode = code.replace(/\D+/g, '');
    setVerificationCode(digitsAsCode);
  };

  let content;
  switch (step) {
    case INITIAL_STEP:
      content = (
        <div>
          <SignUpFormHeader
            text={<I18nText id="sign_up.confirmation.title" />}
          />
          <div className="sign-up__field-hint">
            <p>
              <I18nText id="sign_up.confirmation.explanation.part1" />
            </p>
            <p>{userMobileNumber}</p>
            <p>
              <I18nText id="sign_up.confirmation.explanation.part2" />
            </p>
          </div>
          <div className="sign-up__actions">
            <Button
              version="v2"
              label={<I18nText id="sign_up.confirmation.send_code" />}
              onClick={sendCode}
              type="primary"
              size="small"
            />
            <Button
              version="v2"
              label={
                <I18nText id="sign_up.confirmation.change_number.button" />
              }
              onClick={() => setStep(CHANGE_NUMBER)}
              size="small"
            />
          </div>
        </div>
      );
      break;

    case CONFIRM_CODE:
      content = (
        <div>
          <SignUpFormHeader
            text={<I18nText id="sign_up.confirmation.title" />}
          />
          <div className="sign-up__field">
            <div
              className="sign-up__field-hint"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: I18n.t('components.sign_up.confirmation.please_check', {
                  phoneNumber: userMobileNumber,
                }),
              }}
            />
            <div>
              <Input
                onChange={setMobileVerificationCode}
                touched={verificationCode !== ''}
                label={
                  <div className="required-field-wrapper">
                    <I18nText id="sign_up.confirmation.verification_code" />
                  </div>
                }
                value={verificationCode}
                error={errors.verificationCode}
                type="number"
                disabled={!inputEnabled}
              />
            </div>
            {errors.base && (
              <div className="sign-up__error-text">{errors.base}</div>
            )}
          </div>
          <div className="sign-up__field">
            <div className="sign-up__field-hint">
              <I18nText id="sign_up.confirmation.didnt_get_code" />
              <button
                type="button"
                className="sign-up__resend-code-button"
                onClick={sendCode}
                disabled={!resendAvailable}
              >
                <I18nText id="sign_up.confirmation.resend_code" />
              </button>
              {!resendAvailable && (
                <span>
                  <I18nText id="sign_up.confirmation.resend_code_in" />{' '}
                  <CountDown
                    eventTime={smsVerificationAvailableAt.unix()}
                    interval={1000}
                    onZero={() => setResendAvailable(true)}
                  />
                </span>
              )}
            </div>
          </div>
          <div className="sign-up__actions">
            <Button
              version="v2"
              label={<I18nText id="sign_up.organization.form.button" />}
              onClick={validateCode}
              type="primary"
              size="small"
              disabled={verificationCode === ''}
            />
            <Button
              version="v2"
              label={
                <I18nText id="sign_up.confirmation.change_number.button" />
              }
              onClick={() => setStep(CHANGE_NUMBER)}
              size="small"
            />
          </div>
        </div>
      );
      break;

    case CHANGE_NUMBER:
      content = (
        <div>
          <SignUpFormHeader
            text={<I18nText id="sign_up.confirmation.change_number.title" />}
          />
          <div className="sign-up__field-hint">
            <I18nText id="sign_up.organization.form.hint.user_phone_number" />
          </div>
          <div className="sign-up__field">
            <PhoneNumberInput
              label={
                <div className="required-field-wrapper">
                  <I18nText id="sign_up.label.user_phone_number" />
                </div>
              }
              onChange={setUserMobileNumber}
              error={errors.userMobileNumber}
              isInputTouched={userMobileNumber !== null}
            />
          </div>
          <div className="sign-up__actions">
            <Button
              version="v2"
              label={
                <I18nText id="sign_up.confirmation.change_number.update" />
              }
              onClick={updatePhoneNumber}
              type="primary"
              size="small"
            />
            <Button
              version="v2"
              label={
                <I18nText id="sign_up.confirmation.change_number.cancel" />
              }
              onClick={() =>
                setStep(
                  smsVerificationAvailableAt ? CONFIRM_CODE : INITIAL_STEP,
                )
              }
              size="small"
            />
          </div>
        </div>
      );
      break;

    default:
      break;
  }

  return (
    <div className="sign-up">
      <div className="col-12 col-grid align-end">
        <div className="sign-up__sign-in">
          <div className="sign-up__sign-in-hint">
            <I18nText id="sign_up.organization.sign_in.hint" />
          </div>

          <a className="sign-up__sign-in-button" href={urls.signInUrl}>
            <I18nText id="sign_up.organization.sign_in.button_label" />
          </a>
        </div>
      </div>
      <div className="sign-up__container">
        <div className="col-6 sign-up__icon">
          <SignUpIconHeader
            icon={organizationIcon}
            text={<I18nText id="sign_up.organization.header" />}
          />
        </div>
        <div className="col-xlg-6 col-lg-6 col-md-6 col-12 sign-up__form">
          <div className="sign-up__form-container">{content}</div>
        </div>
      </div>
    </div>
  );
};

SignUpConfirmation.defaultProps = {
  initialSmsVerificationAvailableAt: null,
};

SignUpConfirmation.propTypes = {
  signUpId: PropTypes.string.isRequired,
  urls: PropTypes.shape({
    signInUrl: PropTypes.string.isRequired,
  }).isRequired,
  initialPhoneNumber: PropTypes.string.isRequired,
  initialSmsVerificationAvailableAt: PropTypes.string,
};

export default SignUpConfirmation;
