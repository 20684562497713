import React from 'react';
import PropTypes from 'prop-types';

const PrivacyBody = ({ className }) => {
  return (
    <div className={`container-full policy ${className}`}>
      <div className="grid justify-center">
        <div className="col-xlg-10 col-lg-10 col-md-10 col-12">
          <div className="policy__text">
            <h1>Welltravel Data Policy</h1>

            <p>Date of Last Revision: June 1st, 2020</p>

            <p>
              This policy describes what information we process, collect and how it is shared. As
              you review our policy, keep in mind that it applies to all Welltravel hosted services
              and these do not have a separate privacy policy or that link to this policy, which we
              call the “Welltravel Services” or “Services.”
            </p>

            <h5>I. What kinds of information do we collect?</h5>

            <p>
              Depend on about you and your activities on and off Welltravel from third-party
              partners, such as information from a partner when we jointly offer services about your
              interactions with them.
              <br />
              <br />
              Things you do and information you provide. We collect the content and other
              information you provide when you use our Services, including when you sign up for an
              account, create or share information, and message or communicate with others. This can
              include information in or about the content you provide, such as the location of a
              traveler or the date a file was created. We also collect information about how you use
              our Services, such as the types of content you view or engage with or the frequency
              and duration of your activities.
              <br />
              <br />
              Things others do and information they provide. We also collect content and information
              that other users (e.g. within your travel agency) provide when they use our Services,
              including information that relate to you, such as when they send a message to you, or
              upload, sync or import information relating to you.
              <br />
              <br />
              Your networks and connections. We collect information about the travelers you are
              serving and how you interact with them. We also collect contact information you
              provide if you upload, sync or import this information (e.g. customer profiles) from
              another Third-Party service.
              <br />
              <br />
              Information about payments. If you use our Services for purchases or financial
              transactions (like when you buy or book something through our applications) we collect
              information about the purchase or transaction.
              <br />
              <br />
              Device information we collect information from or about the computers, phones, or
              other devices where you install or access our Services, depending on the permissions
              you’ve granted. We may associate the information we collect from your different
              devices, which helps us provide consistent Services across your devices. Here are some
              examples of the information we collect: Attributes such as the operating system,
              hardware version, device settings, file and software names and types, battery and
              signal strength, and device identifiers. Device locations, including specific
              geographic locations, such as through GPS, Bluetooth, or WiFi signals.
              <br />
              <br />
              For a better experience, while using our Mobile App, we may require your permission to
              access data from your device & may use, store & upload these for below use cases.
              <br />
              <br />
              <b>Personal info -</b> Name, Email address, Address, Birthdate, and Phone number, to
              create your profile, make bookings etc.
              <br />
              <b>Camera and gallery -</b> Access and upload images for services like travel expense,
              travel document, and to use as profile picture.
              <br />
              <b>Files -</b> Access and upload files for services like travel expense, travel
              document etc.
              <br />
              <b>Location -</b> Approximate location and Precise location, to show nearby services.
              <br />
              <b>Financial info -</b> May require your Credit Card information to make bookings.
              <br />
              <b>Device Info -</b> Device or other IDs for different services like detecting OS
              information.
              <br />
              <br />
              The information that we request will be retained by us and used as described in this
              privacy policy.
              <br />
              <br />
              The app also does use third-party services that may collect information used to
              identify you.
              <br />
              <br />
              Link to the privacy policy of third-party service providers used by the app
              <br />
              <ul className="policy__text-list">
                <li>
                  <a href="https://marketingplatform.google.com/about/analytics/terms/us/">
                    Google Analytics for Firebase
                  </a>
                </li>
                <li>
                  <a href="https://firebase.google.com/terms/crashlytics">Firebase Crashlytics</a>
                </li>
                <li>
                  <a href="https://policies.google.com/privacy">Google Play Services</a>
                </li>
              </ul>
              Connection information such as the name of your mobile operator or ISP, browser type,
              language and time zone, mobile phone number and IP address.
              <br />
              <br />
              Information from websites and apps that use our Services. We collect information when
              you visit or use third-party websites and apps that use our Services. This includes
              information about the websites and apps you visit, your use of our Services on those
              websites and apps, as well as information the developer or publisher of the app or
              website provides to you or us.
              <br />
              <br />
              Information from third-party partners. We receive information about you and your
              activities on and off Welltravel from third-party partners, such as information from a
              partner when we jointly offer services about your interactions with them.
            </p>
            <h5>II. How do we use this information?</h5>
            <p>
              We are passionate about creating engaging and customized experiences for users. We use
              all of the information we have to help us provide and support our Services. Here’s
              how:
              <br />
              <br />
              Provide, improve and develop Services. We are able to deliver our Services,
              personalize content, and make suggestions for you and your travellers by using this
              information to understand how you use and interact with our Services and how the
              travelers you serve are interested in on and off our Services. We also use information
              we have to provide suggestions to you. For example, we are able to suggest that one of
              your travellers has a certain booking pattern and thus it would be good if you get in
              touch with him at a certain point of time. When we have location information, we use
              it to tailor our Services for you and your travelers, like helping you locate your
              travellers during critical developments at certain destinations (e.g. in case of
              natural disaster). We conduct surveys and research, test features in development, and
              analyze the information we have to evaluate and improve products and services, develop
              new products or features, and conduct audits and troubleshooting activities.
              <br />
              <br />
              Show and measure ads and services. We use the information we have to improve our news,
              information, advertising and measurement systems so we can show you and your
              travellers relevant news, information, ads on and off our Services and measure the
              effectiveness and reach of news, information, ads and services.
              <br />
              <br />
              Promote safety and security. We use the information we have to help verify accounts
              and activity, and to promote safety and security on and off of our Services, such as
              by investigating suspicious activity or violations of our terms or policies. We work
              hard to protect your account using teams of engineers, automated systems, and advanced
              technology such as encryption and machine learning. We also offer easy-to-use security
              tools that add an extra layer of security to your account. For more information about
              promoting safety on Welltravel, visit the Welltravel Security Page.
              <br />
              <br />
              We use cookies and similar technologies to provide and support our Services and each
              of the uses outlined and described in this section of our policy.
            </p>
            <h5>III. How is this information shared?</h5>
            <p>
              Sharing On Our Services. Users use our Services share information with you and third
              parties like reservations systems. We make this possible by sharing your information
              in the following ways: Users you share and communicate with. When you share and
              communicate using our Services, you choose the audience who can see what you share.
              For example, when you create a deal (e.g. packaged tour) on Welltravel, you select the
              audience for the deal, such as a customized group of individuals. Public information
              is any information you share with a public audience, as well as information on your
              Public Booking Engine. Public information is available to anyone on or off our
              Services and can be seen or accessed through online search engines, APIs, and offline
              media, such as on TV. In some cases, travelers you share and communicate with may
              download or re-share this content with others on and off our Services. Apps, websites
              and third-party integrations on or using our Services When you use third-party
              Accounts/PCC’s, websites or other services that use, or are integrated with, our
              Services, they may receive information about what you book. For example, when you
              create reservations with third party reservations systems the information collected by
              these Accounts/PCC’s, websites or integrated services is subject to their own terms
              and policies.
              <br />
              <br />
              Sharing within Welltravel companies. We share information we have about you within the
              family of companies that are part of Welltravel (e.g. between Welltravel International
              LTD. and Welltravel South Africa LTD).
              <br />
              <br />
              New owner. If the ownership or control of all or part of our Services or their assets
              changes, we may transfer your information to the new owner.
              <br />
              <br />
              Sharing With Third-Party Partners and Customers We work with third party companies who
              help us provide and improve our Services or who use news, information and advertising
              or related products, which makes it possible to operate our companies and provide
              services to users around the world. Here are the types of third parties we can share
              information with about you:
              <br />
              <br />
              Advertising, Measurement, Analytics Services and other Third-Parties in the domain of
              technology and travel (Non-Personally Identifiable Information Only). We want our
              news, information and advertising to be as relevant and interesting as the other
              information you find on our Services. With this in mind, we use all of the information
              we have about you to show your travellers e.g. relevant deals. We do not share
              information that personally identifies you (personally identifiable information is
              information like name or email address that can by itself be used to contact you or
              identifies who you are) with advertising, measurement or analytics partners unless you
              give us permission. We may provide these Third-Parties with information about the
              reach and effectiveness of their news, information or advertising without providing
              information that personally identifies you, or if we have aggregated the information
              so that it does not personally identify you. For example, we may tell any Third-Party
              how its news, information or ads performed, or how many users viewed their news,
              information or ads or booked a deal after seeing an ad, or provide non-personally
              identifying demographic information (such as 25 year old female, in Madrid, who likes
              beach holiday) to these partners to help them understand their audience or customers,
              but only after the advertiser has agreed to abide by our advertiser guidelines.
              <br />
              <br />
              Vendors, service providers and other partners. We transfer information to vendors,
              service providers, and other partners who globally support our business, such as
              providing technical infrastructure services, analyzing how our Services are used,
              measuring the effectiveness of news, information, ads and services, providing customer
              service, facilitating payments, or conducting academic research and surveys. These
              partners must adhere to strict confidentiality obligations in a way that is consistent
              with this Data Policy and the agreements we enter into with them.
            </p>
            <h5>IV. How can I manage or delete information about me?</h5>
            <p>
              You can manage the content and information you share when you use Welltravel through
              our applications. You can also download information associated with your Welltravel
              account through the download facilities we provide. We store data for as long as it is
              necessary to provide products and services to you and others, including those
              described above. Information associated with your account will be kept upon
              deactivation and while your account is deactivated, unless we no longer need the data
              to provide products and services. You can deactivate your account any time. When you
              deactivate your account, we archive information you have generated, such as your
              bookings and your customers profiles. If you do not want to deactivate your account,
              but want to delete your account on Welltravel Services, you may get in touch with us.
            </p>
            <h5>V. How do we respond to legal requests or prevent harm?</h5>
            <p>
              We may access, preserve and share your information in response to a legal request
              (like a search warrant, court order or subpoena) if we have a good faith belief that
              the law requires us to do so. This may include responding to legal requests from
              jurisdictions outside of Switzerland where we have a good faith belief that the
              response is required by law in that jurisdiction, affects users in that jurisdiction,
              and is consistent with internationally recognized standards. We may also access,
              preserve and share information when we have a good faith belief it is necessary to:
              detect, prevent and address fraud and other illegal activity; to protect ourselves,
              you and others, including as part of investigations; or to prevent death or imminent
              bodily harm. For example, we may provide information to third-party partners about the
              reliability of your account to prevent fraud and abuse on and off of our Services.
              Information we receive about you, including financial transaction data related to
              purchases made with Welltravels Services, may be accessed, processed and retained for
              an extended period of time when it is the subject of a legal request or obligation,
              governmental investigation, or investigations concerning possible violations of our
              terms or policies, or otherwise to prevent harm. We also will retain information from
              accounts disabled for violations of our terms to prevent repeat abuse or other
              violations of our terms.
            </p>
            <h5>VI. How our global services operate?</h5>
            <p>
              Welltravel may share information internally within our family of companies or with
              third parties for purposes described in this policy. Information collected within the
              European Economic Area (“EEA”) may, for example, be transferred to countries outside
              of the EEA for the purposes as described in this policy. We utilize standard contract
              clauses approved by the European Commission, adopt other means under European Union
              law, and obtain your consent to legitimize data transfers from the EEA to the United
              States and other countries. You can contact us using the information provided below
              with questions or concerns. We also may resolve disputes you have with us in
              connection with our privacy policies and practices through TRUSTe. You can contact
              TRUSTe through their website.
            </p>
            <h5>VII. How will we notify you of changes to this policy?</h5>

            <p>
              We’ll notify you before we make changes to this policy and give you the opportunity to
              review the revised policy before continuing to use our Services.
            </p>
            <h5>VIII. How to contact Welltravel with questions?</h5>

            <p>
              Questions about these Private Policy of Service should be addressed to{' '}
              <a href="mailto:legal@welltravel.com">legal@welltravel.com</a> or by mail at:
            </p>

            <address>
              Welltravel International Limited <br />
              Prime Center 1, 8060 <br />
              Zurich Airport, Switzerland
            </address>
          </div>
        </div>
      </div>
    </div>
  );
};

PrivacyBody.defaultProps = {
  className: '',
};

PrivacyBody.propTypes = {
  className: PropTypes.string,
};

export default PrivacyBody;
