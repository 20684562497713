import React from 'react';
import PropTypes from 'prop-types';

export default function TabBody({
  tabs,
  selectedIndex,
  additionalProps,
  recaptchaKey,
  recaptchaWidgetSrc,
}) {
  const Body = tabs[selectedIndex].body;

  return (
    <div className="hero-body">
      <Body
        className="hero-body__content--active"
        additionalProps={additionalProps}
        recaptchaKey={recaptchaKey}
        recaptchaWidgetSrc={recaptchaWidgetSrc}
      />
    </div>
  );
}

TabBody.defaultProps = {
  additionalProps: PropTypes.shape({
    routes: null,
    signUpUrl: null,
    platformFeaturesRoutes: null,
  }),
};

TabBody.propTypes = {
  selectedIndex: PropTypes.number.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabNum: PropTypes.node.isRequired,
      body: PropTypes.function, // react functional component
    }),
  ).isRequired,
  additionalProps: PropTypes.shape({
    routes: PropTypes.shape({
      features: {
        platform: PropTypes.string,
      },
      pricing: {
        platform: PropTypes.string,
      },
      resources: {
        faqs: PropTypes.string,
        tutorials: PropTypes.string,
        glossary: PropTypes.string,
        documentation: PropTypes.string,
      },
      company: {
        about_us: PropTypes.string,
        careers: PropTypes.string,
        logos: PropTypes.string,
      },
      policies: {
        term: PropTypes.string,
        privacy: PropTypes.string,
        security: PropTypes.string,
      },
    }),
    signUpUrl: PropTypes.string,
    recaptchaKey: PropTypes.string.isRequired,
    recaptchaWidgetSrc: PropTypes.string.isRequired,
    platformFeaturesRoutes: PropTypes.shape({
      platformFeaturesProfiles: PropTypes.string,
      platformFeaturesSearch: PropTypes.string,
      platformFeaturesBooking: PropTypes.string,
      platformFeaturesMobileApp: PropTypes.string,
      platformFeaturesInvoicing: PropTypes.string,
      platformFeaturesOnlineBookingTool: PropTypes.string,
      platformFeaturesReporting: PropTypes.string,
      platformFeaturesTaskManagement: PropTypes.string,
      platformFeaturesVisaAdvisory: PropTypes.string,
      platformFeaturesCorporateTravel: PropTypes.string,
      platformFeaturesLeisurePackages: PropTypes.string,
      platformFeaturesChat: PropTypes.string,
    }),
  }),
  recaptchaKey: PropTypes.string.isRequired,
  recaptchaWidgetSrc: PropTypes.string.isRequired,
};
