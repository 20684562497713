import React from 'react';
import PropTypes from 'prop-types';
import Tutorials from '../../../../shared/components/Tutorials/index';

const TutorialsBody = ({ className }) => {
  return (
    <div className={`container-full ${className} tutorials__container`}>
      <div className="grid justify-center">
        <div className="col-xlg-10 col-lg-10 col-md-10 col-12">
          <Tutorials />
        </div>
      </div>
    </div>
  );
};

TutorialsBody.defaultProps = {
  className: '',
};

TutorialsBody.propTypes = {
  className: PropTypes.string,
};

export default TutorialsBody;
