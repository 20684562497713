import React, { useState, useEffect } from 'react';
import Input from '@wtag/rcl-input';
import Button from '@wtag/rcl-button';
import Icon from '@wtag/rcl-icon';
import { I18nText } from '@wtag/react-comp-lib';
import Modal from '@wtag/rcl-modal';
import PropTypes from 'prop-types';
import scriptjs from 'scriptjs';
import Recaptcha from 'react-recaptcha';
import httpClient from '../../shared/libraries/httpClient';
import routes from '../../shared/routes';

const ContactPage = props => {
  const { signInUrl, linkText, recaptchaKey, recaptchaWidgetSrc } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [isMailSent, setIsMailSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const mapLanguageCode = () => {
    const currentLocale = I18n.locale;
    return currentLocale.slice(0, 2);
  };

  useEffect(() => {
    scriptjs(recaptchaWidgetSrc);
  }, []);

  const sendMail = () => {
    setIsMailSent(true);
    httpClient
      .put(routes.staticPage.contactUs(), {
        email_address: emailAddress,
        recaptcha_token: recaptchaToken,
      })
      .then(({ data }) => {
        if (data.error) {
          setErrorMessage(I18n.t('components.footer.contact_us.error'));
        } else {
          setModalOpen(false);
          setEmailAddress('');
          setErrorMessage('');
        }
      })
      .catch(error => {
        setErrorMessage(
          (error.response.status === 422 && error.response.data.errors) ||
            I18n.t('components.footer.contact_us.error'),
        );
      });
  };

  const resetData = () => {
    setModalOpen(false);
    setEmailAddress('');
    setIsVerified(false);
    setIsMailSent(false);
  };

  const disableSubmitButton = () => {
    return !(emailAddress && isVerified);
  };

  const verifyCallBack = response => {
    setRecaptchaToken(response);
    setIsVerified(!!response);
  };

  return (
    <>
      <Button
        version="v2"
        size="small"
        className={props.buttonClassName}
        fullWidth={false}
        type="primary"
        label={linkText}
        onClick={() => {
          setModalOpen(true);
        }}
      />
      <Modal
        className="modal__wrapper contact-us__modal"
        isVisible={modalOpen}
        onOutsideClick={() => setModalOpen(false)}
      >
        <>
          <div className="modal__header">
            <Modal.Title>
              <I18nText id="footer.contact_us.header" />
            </Modal.Title>
            <div
              onClick={() => setModalOpen(false)}
              onKeyDown={() => setModalOpen(false)}
              role="presentation"
            >
              <Icon name="close" size="normal" />
            </div>
          </div>
          <Modal.Content>
            <div className="grid">
              <div className="contact-us col-12 col-bleed-x">
                <div className="contact-us__header">
                  <span>
                    <I18nText id="footer.contact_us.description" />
                  </span>
                  <Input
                    label={<I18nText id="footer.contact_us.email" />}
                    onChange={value => setEmailAddress(value)}
                    value={emailAddress}
                    isClearable={false}
                    error={errorMessage}
                    touched={true}
                  />
                </div>
                {recaptchaKey && (
                  <Recaptcha
                    sitekey={recaptchaKey}
                    render="explicit"
                    hl={mapLanguageCode()}
                    verifyCallback={verifyCallBack}
                    size="normal"
                  />
                )}
              </div>
            </div>
          </Modal.Content>
          <div className="contact-us__footer">
            <Modal.Footer>
              <div className="contact-us__action">
                <Button
                  className="contact-us__action-submit-button"
                  label={<I18nText id="footer.contact_us.submit" />}
                  size="small"
                  disabled={disableSubmitButton() || isMailSent}
                  onClick={sendMail}
                  type="primary"
                />
                <Button
                  className="contact-us__action-cancel-button"
                  label={<I18nText id="footer.contact_us.cancel" />}
                  size="small"
                  onClick={resetData}
                />
              </div>
              <div className="contact-us__sign-in">
                <a href={signInUrl}>
                  {I18n.t('components.footer.contact_us.customer')}
                </a>
              </div>
            </Modal.Footer>
          </div>
        </>
      </Modal>
    </>
  );
};

ContactPage.defaultProps = {
  buttonClassName: null,
};

ContactPage.propTypes = {
  signInUrl: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  recaptchaKey: PropTypes.string.isRequired,
  recaptchaWidgetSrc: PropTypes.string.isRequired,
  buttonClassName: PropTypes.string,
};

export default ContactPage;
