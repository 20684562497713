import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import HeroTab from './HeroTab';

const HeroTabContainer = ({
  tabs,
  page,
  additionalProps,
  recaptchaKey,
  recaptchaWidgetSrc,
}) => {
  const [previousTab, setPreviousTab] = useState(0);

  const onBackButtonEvent = () => {
    const getPrevTab = tabs.find(tab => tab.url === window.location.pathname);

    return getPrevTab && setPreviousTab(getPrevTab.tabNum - 1);
  };

  useEffect(() => {
    window.onpopstate = onBackButtonEvent;
  }, []);

  return (
    <div className="hero-slider">
      <HeroTab
        currentTab={previousTab}
        onChange={setPreviousTab}
        tabs={tabs}
        page={page}
        additionalProps={additionalProps}
        recaptchaKey={recaptchaKey}
        recaptchaWidgetSrc={recaptchaWidgetSrc}
      />
    </div>
  );
};

HeroTabContainer.defaultProps = {
  additionalProps: PropTypes.shape({
    routes: null,
    platformFeaturesRoutes: null,
  }),
};

HeroTabContainer.propTypes = {
  page: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabNum: PropTypes.number.isRequired,
      title: PropTypes.node.isRequired,
      slogan: PropTypes.node.isRequired,
      logo: PropTypes.node.isRequired,
      body: PropTypes.func.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  additionalProps: PropTypes.shape({
    routes: PropTypes.shape({
      features: {
        platform: PropTypes.string,
      },
      pricing: {
        platform: PropTypes.string,
      },
      resources: {
        faqs: PropTypes.string,
        tutorials: PropTypes.string,
        glossary: PropTypes.string,
        documentation: PropTypes.string,
      },
      company: {
        about_us: PropTypes.string,
        careers: PropTypes.string,
        logos: PropTypes.string,
      },
      policies: {
        term: PropTypes.string,
        privacy: PropTypes.string,
        security: PropTypes.string,
      },
    }),
    recaptchaKey: PropTypes.string.isRequired,
    recaptchaWidgetSrc: PropTypes.string.isRequired,
    platformFeaturesRoutes: PropTypes.shape({
      platformFeaturesProfiles: PropTypes.string,
      platformFeaturesSearch: PropTypes.string,
      platformFeaturesBooking: PropTypes.string,
      platformFeaturesMobileApp: PropTypes.string,
      platformFeaturesInvoicing: PropTypes.string,
      platformFeaturesOnlineBookingTool: PropTypes.string,
      platformFeaturesReporting: PropTypes.string,
      platformFeaturesTaskManagement: PropTypes.string,
      platformFeaturesVisaAdvisory: PropTypes.string,
      platformFeaturesCorporateTravel: PropTypes.string,
      platformFeaturesLeisurePackages: PropTypes.string,
      platformFeaturesChat: PropTypes.string,
    }),
  }),
  recaptchaKey: PropTypes.string.isRequired,
  recaptchaWidgetSrc: PropTypes.string.isRequired,
};

export default HeroTabContainer;
