import React from 'react';
import { I18nText } from '@wtag/react-comp-lib';
import xl from 'affiliateImages/xle.svg';
import macd from 'affiliateImages/mcd.png';
import macdonalds from 'affiliateImages/Mcdonalds.png';

const Customer = () => {
  return (
    <div className="container">
      <div className="grid homepage__customer-section">
        <div className="col-xl-6 col-lg-6 col-md-12 col-12">
          <div className="homepage__customer-section-header">
            <I18nText id="homepage.features.mcd.title" />
          </div>
          <div className="homepage__customer-section-sub-header">
            <I18nText id="homepage.features.mcd.sub_title" />
          </div>
          <div className="homepage__customer-section-active-user-card">
            <span className="homepage__customer-section-active-user-card-number">
              100,000+
            </span>
            <span className="homepage__customer-section-active-user-card-text">
              <I18nText id="homepage.features.mcd.text7" />
            </span>
          </div>
          <div className="homepage__customer-section-text">
            <I18nText id="homepage.features.mcd.text" />
          </div>
          <div className="homepage__customer-section-text homepage__customer-section-text--fade homepage__customer-section-text--small">
            <I18nText id="homepage.features.mcd.big_text1" />
          </div>
          <div className="homepage__customer-section-text homepage__customer-section-text--fade homepage__customer-section-text--small">
            <I18nText id="homepage.features.mcd.big_text2" />
          </div>
        </div>
        <div className="offset-xl-1 offset-lg-1 offset-md-0 offset-0 col-xl-5 col-lg-5 col-md-12 col-12 col-bleed-x">
          <div className="col-xl-6 col-lg-6 col-md-12 col-12 col-bleed-x homepage__customer-section-operator">
            <div className="col-12 col-bleed-x homepage__customer-section-operator-header">
              <I18nText id="homepage.features.mcd.text3" />
            </div>
            <div className="col-12 col-bleed homepage__customer-section-operator-company">
              <div className="col-5 col-bleed homepage__customer-section-operator-compnay-img">
                <img src={xl} alt="xl" className="flex-img" />
              </div>
              <div className="col-7 col-bleed-y homepage__customer-section-operator-compnay-name">
                <I18nText id="homepage.features.mcd.text4" />
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-12 col-bleed-x homepage__customer-section-clients">
            <div className="col-12 col-bleed-x homepage__customer-section-clients-header">
              <I18nText id="homepage.features.mcd.text1" />
            </div>
            <div className="col-12 col-bleed homepage__customer-section-clients-company">
              <div className="col-2 col-bleed homepage__customer-section-clients-compnay-img">
                <img src={macd} alt="macdonals" className="flex-img" />
              </div>
              <div className="col-10 col-bleed-y homepage__customer-section-clients-compnay-name">
                <I18nText id="homepage.features.mcd.text2" />
              </div>
            </div>
          </div>
          <img src={macdonalds} alt="client-site" className="flex-img" />
        </div>
      </div>
    </div>
  );
};

export default Customer;
