import React from 'react';
import { I18nText } from '@wtag/react-comp-lib';
import settings from '../../../../assets/images/settings.svg';

export const settingsLinks = [
  {
    icon: settings,
    name: <I18nText id="sideNav.settings" />,
  },
];

export default settingsLinks;
