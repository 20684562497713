import React, { useState } from 'react';
import { I18nText } from '@wtag/react-comp-lib';
import { Accordion, AccordionItem } from '@wtag/rcl-accordion';

const faqList = [
  {
    id: 1,
    title: <I18nText id="pricing.plans.faq_section.key_one" />,
    content: <I18nText id="pricing.plans.faq_section.value_one" />,
  },
  {
    id: 2,
    title: <I18nText id="pricing.plans.faq_section.key_two" />,
    content: <I18nText id="pricing.plans.faq_section.value_two" />,
  },
  {
    id: 3,
    title: <I18nText id="pricing.plans.faq_section.key_three" />,
    content: <I18nText id="pricing.plans.faq_section.value_three" />,
  },
  {
    id: 4,
    title: <I18nText id="pricing.plans.faq_section.key_four" />,
    content: <I18nText id="pricing.plans.faq_section.value_four" />,
  },
  {
    id: 5,
    title: <I18nText id="pricing.plans.faq_section.key_five" />,
    content: <I18nText id="pricing.plans.faq_section.value_five" />,
  },
  {
    id: 6,
    title: <I18nText id="pricing.plans.faq_section.key_six" />,
    content: <I18nText id="pricing.plans.faq_section.value_six" />,
  },
  {
    id: 7,
    title: <I18nText id="pricing.plans.faq_section.key_seven" />,
    content: <I18nText id="pricing.plans.faq_section.value_seven" />,
  },
  {
    id: 8,
    title: <I18nText id="pricing.plans.faq_section.key_eight" />,
    content: <I18nText id="pricing.plans.faq_section.value_eight" />,
  },
  {
    id: 9,
    title: <I18nText id="pricing.plans.faq_section.key_nine" />,
    content: <I18nText id="pricing.plans.faq_section.value_nine" />,
  },
];

const FAQPricing = () => {
  const [activeAccordionItemId, setActiveAccordionItemId] = useState();

  return (
    <Accordion>
      {faqList.map(item => (
        <AccordionItem
          key={item.id}
          onClick={setActiveAccordionItemId}
          isActive={activeAccordionItemId === item.id}
          header={item.title}
          id={item.id}
        >
          {item.content}
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default FAQPricing;
