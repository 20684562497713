/* eslint-disable react/no-array-index-key */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Input from '@wtag/rcl-input';
import Button from '@wtag/rcl-button';
import { I18nText, Textarea } from '@wtag/react-comp-lib';
import { Accordion, AccordionItem } from '@wtag/rcl-accordion';
import map from 'affiliateImages/Map.png';
import cto from 'affiliateImages/cto.png';
import cqso from 'affiliateImages/cqso.png';
import idea from 'affiliateImages/idea.png';
import planeTravel from 'affiliateImages/plane_travel.png';
import remoteWork from 'affiliateImages/remote_work.png';
import quotation from 'affiliateImages/quotation.svg';

import ContactPage from '../../../public/components/ContactPage';

const speakers = {
  cto: {
    name: 'Mujadded Al Rabbani Alif',
    image: <img src={cto} alt="CTO" className="flex-img" />,
    quote: <I18nText id="careers.cto_quote" />,
  },
  cqso: {
    name: 'Raffael Schmid',
    image: <img src={cqso} alt="CQSO" className="flex-img" />,
    quote: <I18nText id="careers.cqso_quote" />,
  },
};

// TODO: these are dummy data now, after getting real data these will be replaced along with proper PDF files with all translations

const availableJobPositions = [
  {
    id: 1,
    title: 'CCO (Chief Commercial Officer)',
    content:
      "We are looking to hire a visionary CCO to oversee our company’s client processes, and to ensure superb customer experience. The CCO will optimize our company's operating capabilities, employ strategies to maximize customer satisfaction and contribute to marketing initiatives. The CCO will assist in compiling the company's budget and targets, and employ strategies to safeguard and develop the customer base. You will team up with management to implement business strategies. To ensure success you should have a clear understanding of our company’s core values and culture. Top candidates will be exemplary leaders, have superb business acumen, have sound understanding of business strategies and have a sound Travel industry record.",
    pdf: '/p/resources/job_description?post=CCO',
  },
  {
    id: 2,
    title: 'CRM (Customer Relationship Manager)',
    content:
      'We are looking for a Client Relations Manager to create and nurture long-term relationships with our customers. You will resolve any issues that arise to ensure customers are satisfied with our services. In this role, you should be an excellent communicator who’s able to grasp customer needs and brainstorm ways to fulfill them. If you have working knowledge in travel industry/ticketing or Card Services, if you think you are highly organized and ability to take and work on initiative, if you are highly customer-focused and results-oriented then we would like to meet you.',
    pdf: '/p/resources/job_description?post=CRM',
  },
  /* As these positions are not available for now */
  // {
  // id: 3,
  //   title: 'Junior Software Engineer',
  //   content:
  //     'We are looking for passionate Software Engineer to design, develop and install software solutions. Software Engineer responsibilities include gathering user requirements, defining system functionality and writing code in various languages, like Java, Ruby on Rails or .NET programming languages. An ideal candidate should be familiar with the software development life cycle (SDLC) from preliminary system analysis to tests and deployment. Ultimately, the role of the Software Engineer is to build high-quality, innovative and fully performing software that complies with coding standards and technical design.',
  //   pdf: '/p/resources/job_description',
  // },
  // {
  // id: 4,
  //   title: 'Junior Software Engineer (iOS)',
  //   content:
  //     'We are looking for passionate Software Engineer to design, develop and install software solutions. Software Engineer responsibilities include gathering user requirements, defining system functionality and writing code in various languages, like Java, Ruby on Rails or .NET programming languages. An ideal candidate should be familiar with the software development life cycle (SDLC) from preliminary system analysis to tests and deployment. Ultimately, the role of the Software Engineer is to build high-quality, innovative and fully performing software that complies with coding standards and technical design.',
  //   pdf: '/p/resources/job_description',
  // },
  // {
  // id: 5,
  //   title: 'Software Engineer (Ruby on Rails)',
  //   content:
  //     'We are looking for passionate Software Engineer to design, develop and install software solutions. Software Engineer responsibilities include gathering user requirements, defining system functionality and writing code in various languages, like Java, Ruby on Rails or .NET programming languages. An ideal candidate should be familiar with the software development life cycle (SDLC) from preliminary system analysis to tests and deployment. Ultimately, the role of the Software Engineer is to build high-quality, innovative and fully performing software that complies with coding standards and technical design.',
  //   pdf: '/p/resources/job_description',
  // },
  // {
  // id: 6,
  //   title: 'Senior Software Engineer (DotNet)',
  //   content:
  //     'We are looking for passionate Software Engineer to design, develop and install software solutions. Software Engineer responsibilities include gathering user requirements, defining system functionality and writing code in various languages, like Java, Ruby on Rails or .NET programming languages. An ideal candidate should be familiar with the software development life cycle (SDLC) from preliminary system analysis to tests and deployment. Ultimately, the role of the Software Engineer is to build high-quality, innovative and fully performing software that complies with coding standards and technical design.',
  //   pdf: '/p/resources/job_description',
  // },
  // {
  // id: 7,
  //   title: 'Software Engineer (React.js / Vue.js / JavaScript)',
  //   content:
  //     'We are looking for passionate Software Engineer to design, develop and install software solutions. Software Engineer responsibilities include gathering user requirements, defining system functionality and writing code in various languages, like Java, Ruby on Rails or .NET programming languages. An ideal candidate should be familiar with the software development life cycle (SDLC) from preliminary system analysis to tests and deployment. Ultimately, the role of the Software Engineer is to build high-quality, innovative and fully performing software that complies with coding standards and technical design.',
  //   pdf: '/p/resources/job_description',
  // },
  // {
  // id: 8,
  //   title: 'Software Engineer (Android)',
  //   content:
  //     'We are looking for passionate Software Engineer to design, develop and install software solutions. Software Engineer responsibilities include gathering user requirements, defining system functionality and writing code in various languages, like Java, Ruby on Rails or .NET programming languages. An ideal candidate should be familiar with the software development life cycle (SDLC) from preliminary system analysis to tests and deployment. Ultimately, the role of the Software Engineer is to build high-quality, innovative and fully performing software that complies with coding standards and technical design.',
  //   pdf: '/p/resources/job_description',
  // },
  // {
  // id: 9,
  //   title: 'Senior Software Engineer (Ruby on Rails)',
  //   content:
  //     'We are looking for passionate Software Engineer to design, develop and install software solutions. Software Engineer responsibilities include gathering user requirements, defining system functionality and writing code in various languages, like Java, Ruby on Rails or .NET programming languages. An ideal candidate should be familiar with the software development life cycle (SDLC) from preliminary system analysis to tests and deployment. Ultimately, the role of the Software Engineer is to build high-quality, innovative and fully performing software that complies with coding standards and technical design.',
  //   pdf: '/p/resources/job_description',
  // },
  // {
  // id: 10,
  //   title: 'UI/UX Engineer',
  //   content:
  //     'We are looking for passionate Software Engineer to design, develop and install software solutions. Software Engineer responsibilities include gathering user requirements, defining system functionality and writing code in various languages, like Java, Ruby on Rails or .NET programming languages. An ideal candidate should be familiar with the software development life cycle (SDLC) from preliminary system analysis to tests and deployment. Ultimately, the role of the Software Engineer is to build high-quality, innovative and fully performing software that complies with coding standards and technical design.',
  //   pdf: '/p/resources/job_description',
  // },
];

// TODO: these are dummy data now, after getting real data these will be replaced along with proper PDF files with all translations

const Careers = ({ additionalProps, recaptchaKey, recaptchaWidgetSrc }) => {
  const cvUploadInput = useRef();
  const [resumeSenderEmail, setResumeSenderEmail] = useState();
  const [activeAccordionItemId, setActiveAccordionItemId] = useState();

  return (
    <div className="grid justify-center">
      <div className="col-xlg-10 col-lg-10 col-md-10 col-12 col-bleed-y">
        <div className="careers">
          <section className="full-width tertiary-light-bg careers__map">
            <div className="col-grid align-center">
              <div className="col-xlg-10 col-lg-10 col-md-10 col-12 col-bleed-y">
                <div className="careers__map-header">
                  <I18nText id="careers.header1" />
                </div>
              </div>
              <div className="col-xlg-10 col-lg-10 col-md-10 col-12 col-bleed-y">
                <div className="careers__map-sub-header">
                  <I18nText id="careers.sub_header1" />
                </div>
              </div>
              <div className="careers__map-img">
                <img src={map} alt="map" className="img-responsive" />
              </div>
            </div>
          </section>
          <section className="careers__position">
            <div className="careers__position-header">
              <I18nText id="careers.header2" />
            </div>
            <div className="careers__position-sub-header">
              <I18nText id="careers.sub_header2" />
            </div>
            <div className="careers__position-open">
              <Accordion>
                {availableJobPositions.map(item => (
                  <AccordionItem
                    key={item.id}
                    onClick={setActiveAccordionItemId}
                    isActive={activeAccordionItemId === item.id}
                    header={item.title}
                    id={item.id}
                  >
                    {item.content}
                    <div className="careers__position-button">
                      <a
                        className="button button--v2 button--small button--primary"
                        rel="noopener noreferrer"
                        target="_blank"
                        href={item.pdf}
                      >
                        <I18nText id="brochureCard.download" />
                      </a>
                    </div>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
          </section>
          <section className="full-width secondary-bg careers__company-description">
            <div className="grid">
              <div className="col-grid offset-1 col-xlg-4 col-lg-4 col-md-4 col-10 justify-center">
                <div className="careers__company-description-header">
                  <I18nText id="careers.header3" />
                </div>
              </div>
              <div className="col-grid offset-1 col-xlg-5 col-lg-5 col-md-5 col-10 justify-center">
                <div className="careers__company-description-sub-header">
                  <I18nText id="careers.sub_header3" />
                </div>
              </div>
            </div>
          </section>
          <section className="full-width primary-bg">
            <div className="grid">
              <div className="col-grid offset-xlg-1 offset-lg-1 offset-md-1 offset-3 col-xlg-2 col-lg-2 col-md-3 col-6 align-center careers__cto-image">
                {speakers.cto.image}
              </div>
              <div className="col-grid offset-1 col-xlg-7 col-lg-7 col-md-6 col-10">
                <img className="careers__cto-quotation-sign" src={quotation} alt="quotaion" />
                <div className="careers__cto-quote">{speakers.cto.quote}</div>
                <div className="careers__cto-name">
                  {speakers.cto.name} <br /> CTO
                </div>
              </div>
            </div>
          </section>
          <section className="full-width">
            <div className="grid">
              <div className="col-grid offset-1 col-10 justify-center">
                <div className="careers__tasks-header">
                  <I18nText id="careers.header4" />
                </div>
                <div className="careers__tasks-sub-header">
                  <I18nText id="careers.sub_header4" />
                </div>
              </div>
              <div className="offset-1 col-10 col-bleed-y">
                <div className="grid">
                  <div className="col-grid offset-xlg-0 offset-lg-0 offset-md-0 offset-1 col-xlg-auto col-lg-auto col-md-auto col-10">
                    <img
                      src={idea}
                      alt="Ideation"
                      className="careers__tasks-task-icon align-self-start"
                    />
                    <div className="careers__tasks-task-name">
                      <I18nText id="careers.tasks.ideation.name" />
                    </div>
                    <div className="careers__tasks-task-description">
                      <I18nText id="careers.tasks.ideation.description" />
                    </div>
                  </div>
                  <div className="col-grid offset-1 col-xlg-auto col-lg-auto col-md-auto col-10">
                    <img
                      src={planeTravel}
                      alt="Serve Clients"
                      className="careers__tasks-task-icon align-self-start"
                    />
                    <div className="careers__tasks-task-name">
                      <I18nText id="careers.tasks.service.name" />
                    </div>
                    <div className="careers__tasks-task-description">
                      <I18nText id="careers.tasks.service.description" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="full-width primary-bg careers__remote-work-section">
            <img
              src={remoteWork}
              alt="remote work"
              className="img-responsive careers__remote-work-section-cover"
            />
            <div className="secondary-overlay" />
            <div className="grid">
              <div className="col-grid offset-1 col-10 justify-center">
                <div className="careers__remote-work-header">
                  <I18nText id="careers.header5" />
                </div>
                <div className="careers__remote-work-benefits">
                  <I18nText id="careers.remote_work.benefits.one" />
                  <I18nText id="careers.remote_work.benefits.two" />
                  <I18nText id="careers.remote_work.benefits.three" />
                  <I18nText id="careers.remote_work.benefits.four" />
                  <I18nText id="careers.remote_work.benefits.five" />
                  <I18nText id="careers.remote_work.benefits.six" />
                </div>
              </div>
            </div>
          </section>
          <section className="full-width secondary-bg careers__learning-quote">
            <div className="grid">
              <div className="col-grid offset-1 col-xlg-4 col-lg-4 col-md-4 col-10 justify-center">
                <div className="careers__learning-quote-header">
                  <I18nText id="careers.header6" />
                </div>
              </div>
              <div className="col-grid offset-1 col-xlg-5 col-lg-5 col-md-5 col-10 justify-center">
                <div className="careers__learning-quote-sub-header">
                  <I18nText id="careers.sub_header6" />
                </div>
              </div>
            </div>
          </section>
          <section className="full-width primary-bg careers__cqso">
            <div className="grid">
              <div className="col-grid offset-xlg-1 offset-lg-1 offset-md-1 offset-3 col-xlg-2 col-lg-2 col-md-3 col-6 align-center careers__cqso-image">
                {speakers.cqso.image}
              </div>
              <div className="col-grid offset-1 col-xlg-7 col-lg-7 col-md-6 col-10">
                <img className="careers__cqso-quotation-sign" src={quotation} alt="quotaion" />
                <div className="careers__cqso-quote">{speakers.cqso.quote}</div>
                <div className="careers__cqso-name">
                  {speakers.cqso.name} <br /> CQSO
                </div>
              </div>
            </div>
          </section>
          <section className="full-width tertiary-light-bg careers__send-cv">
            <div className="grid">
              <div className="col-grid offset-1 col-xlg-4 col-lg-4 col-md-4 col-10">
                <div className="careers__send-cv-header">
                  <I18nText id="careers.header8" />
                </div>
                <div className="careers__send-cv-sub-header">
                  <I18nText id="careers.sub_header8" />
                </div>
              </div>
              <form className="col-grid offset-1 col-xlg-5 col-lg-5 col-md-5 col-10">
                <div className="col-12 col-bleed-x">
                  <Input
                    label={<I18nText id="careers.send_resume.labels.email" />}
                    required={true}
                    value={resumeSenderEmail}
                    onChange={value => setResumeSenderEmail(value)}
                    isClearable={false}
                  />
                </div>
                <div className="col-12 col-bleed-x">
                  <div className="input__label input__label--required">
                    <I18nText id="careers.send_resume.labels.file_input" />
                  </div>
                  <input ref={cvUploadInput} type="file" hidden={true} required={true} />
                  <Button
                    className="align-self-start"
                    version="v2"
                    label={<I18nText id="careers.send_resume.labels.file_select_button" />}
                    onClick={() => {
                      cvUploadInput.current.click();
                    }}
                  />
                </div>
                <div className="col-12 col-bleed-x">
                  <Textarea
                    label={<I18nText id="careers.send_resume.labels.message" />}
                    size="tiny"
                    required={true}
                  />
                </div>
                <div className="col-12 col-bleed-x careers__apply-button">
                  <Button
                    className="align-self-start"
                    version="v2"
                    label={<I18nText id="careers.send_resume.labels.apply" />}
                    primary={true}
                  />
                </div>
              </form>
            </div>
          </section>
          <section className="full-width careers__contact">
            <div className="grid">
              <div className="col-grid offset-1 col-xlg-4 col-lg-4 col-md-4 col-10 justify-center">
                <div className="careers__contact-header ">
                  <I18nText id="careers.header7" />
                </div>
              </div>
              <div className="col-grid offset-1 col-xlg-5 col-lg-5 col-md-5 col-10 justify-center contact-us__wrapper">
                <div className="careers__contact-sub-header">
                  <I18nText id="careers.sub_header7" />
                </div>
                <div className="careers__contact-sub-header-button">
                  <ContactPage
                    linkText={
                      <span className="button button--v2 button--normal button--primary ">
                        <I18nText id="homepage.features.support.contact_us" />
                      </span>
                    }
                    signInUrl={additionalProps.routes.signInUrl}
                    recaptchaKey={recaptchaKey}
                    recaptchaWidgetSrc={recaptchaWidgetSrc}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

Careers.defaultProps = {
  additionalProps: {
    routes: {
      signInUrl: '',
    },
  },
  recaptchaKey: '',
  recaptchaWidgetSrc: '',
};

Careers.propTypes = {
  additionalProps: PropTypes.shape({
    routes: PropTypes.shape({
      signInUrl: PropTypes.string,
    }),
  }),
  recaptchaKey: PropTypes.string,
  recaptchaWidgetSrc: PropTypes.string,
};

export default Careers;
