import React from 'react';
import PropTypes from 'prop-types';
import Media from '../../../../shared/components/Media/index';

const MediaBody = ({
  className,
  additionalProps,
  recaptchaKey,
  recaptchaWidgetSrc,
}) => {
  return (
    <div className={`${className}`}>
      <Media
        additionalProps={additionalProps}
        recaptchaKey={recaptchaKey}
        recaptchaWidgetSrc={recaptchaWidgetSrc}
      />
    </div>
  );
};

MediaBody.defaultProps = {
  className: '',
  additionalProps: {
    routes: {
      signInUrl: '',
    },
  },
};

MediaBody.propTypes = {
  className: PropTypes.string,
  additionalProps: PropTypes.shape({
    routes: PropTypes.shape({
      signInUrl: PropTypes.string,
    }),
  }),
  recaptchaKey: PropTypes.string.isRequired,
  recaptchaWidgetSrc: PropTypes.string.isRequired,
};

export default MediaBody;
