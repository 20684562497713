import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import IconButton from '@wtag/rcl-icon-button';
import { Avatar, I18nText } from '@wtag/react-comp-lib';

const ProfileMenu = ({ links, currentUser, currentUsersAvatar }) => {
  const { signOutUrl, privacyUrl, settingsUrl, termsUrl } = links;
  const { first_name: firstName, last_name: lastName, email } = currentUser;
  const iconLabelText = (
    <div className="navbar__user-settings-iconLabel">
      <I18nText id="profile_menu.edit" />
    </div>
  );

  return (
    <div className="navbar__user-settings-menu">
      <div className="navbar__user-settings-avatar">
        <Avatar
          src={currentUsersAvatar}
          firstName={firstName}
          lastName={lastName}
          size="huge"
        />
        <a href={settingsUrl}>
          <IconButton
            version="v2"
            isIconOnly={true}
            icon={<Icon name="edit" />}
            size="large"
            label={iconLabelText}
            labelPlacement="bottom"
          />
        </a>
      </div>
      <div className="navbar__user-settings-user-info">
        <div>
          {firstName} {lastName}
        </div>
        <div>{email}</div>
      </div>
      <div className="navbar__user-settings-actions">
        <a className="button button--v2 button--small" href={signOutUrl}>
          <I18nText id="profile_menu.sign_out" />
        </a>
      </div>
      <div className="navbar__user-settings-policy-links">
        <a href={privacyUrl} target="_blank" rel="noopener noreferrer">
          <I18nText id="heroTab.policies.privacy.slogan" />
        </a>
        <a href={termsUrl} target="_blank" rel="noopener noreferrer">
          <I18nText id="heroTab.policies.tos.slogan" />
        </a>
      </div>
    </div>
  );
};

ProfileMenu.defaultProps = {
  currentUsersAvatar: null,
};

ProfileMenu.propTypes = {
  links: PropTypes.shape({
    signOutUrl: PropTypes.string.isRequired,
    privacyUrl: PropTypes.string.isRequired,
    settingsUrl: PropTypes.string.isRequired,
    termsUrl: PropTypes.string.isRequired,
  }).isRequired,
  currentUser: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  currentUsersAvatar: PropTypes.string,
};

export default ProfileMenu;
