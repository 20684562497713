import React from 'react';
import { I18nText } from '@wtag/react-comp-lib';
import brochureImage from 'affiliateImages/brochure.png';

const BrochureCard = () => {
  return (
    <div className="grid justify-center">
      <div className="col-lg-6 col-md-6 col-12 col-grid align-center brochure-card__image">
        <img src={brochureImage} alt="product_brochure" />
      </div>
      <div className="col-lg-4 col-md-4 col-12 col-grid align-start justify-center">
        <div className="brochure-card__title">
          <I18nText id="brochureCard.title" />
        </div>
        <div className="brochure-card__text">
          <I18nText id="brochureCard.text" />
        </div>
        <a
          className="button button--v2 button--small button--primary"
          rel="noopener noreferrer"
          target="_blank"
          href="/p/resources/brochure"
        >
          <I18nText id="brochureCard.download" />
        </a>
      </div>
    </div>
  );
};

export default BrochureCard;
