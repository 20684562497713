import React, { useEffect, useState, useRef } from 'react';
import { debounce } from 'throttle-debounce';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SpinnerIcon from 'affiliateIcons/spinner.svg';

import Input from '@wtag/rcl-input';
import Icon from '@wtag/rcl-icon';
import IconButton from '@wtag/rcl-icon-button';
import { I18nText, Spinner } from '@wtag/react-comp-lib';
import SanitizedHTML from '../../../../../shared/components/SanitizedHTML';

import httpClient from '../../../../../shared/libraries/httpClient';
import routes from '../../../../../shared/routes';

import ContactPage from '../../../ContactPage';

const MIN_LENGTH_TO_SEARCH = 1;

const GlossaryBody = ({ className, additionalProps }) => {
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [glossaries, setGlossaries] = useState([]);
  const [groupedGlossaries, setGroupedGlossaries] = useState({});
  const [keysOfGroupedGlossaries, setKeysOfGroupedGlossaries] = useState([]);
  const [searchParam, setSearchParam] = useState('');
  const [recaptchaKey, setRecaptchaKey] = useState('');
  const [recaptchaWidgetSrc, setRecaptchaWidgetSrc] = useState('');

  const fetchGlossaries = useRef(
    debounce(500, async inputParam => {
      setIsLoading(true);
      const { data } = await httpClient.get(
        routes.public.staticPages.glossaries({ q: inputParam }),
      );
      setIsFirstLoad(false);
      setIsLoading(false);
      setGlossaries(data.glossaries);
      setRecaptchaKey(data.recaptchaKey);
      setRecaptchaWidgetSrc(data.recaptchaWidgetSrc);
    }),
  );

  const handleInputChange = value => {
    setSearchParam(value);
  };

  const resetSearchParam = () => {
    setSearchParam('');
  };

  const postIcon = () => {
    if (searchParam.length > MIN_LENGTH_TO_SEARCH) {
      if (isLoading) {
        return <img src={SpinnerIcon} alt="spinner" />;
      }
      return (
        <IconButton
          size="tiny"
          isIconOnly={true}
          icon={<Icon name="close" />}
          onClick={resetSearchParam}
        />
      );
    }
    if (searchParam === '') {
      if (!isFirstLoad && isLoading) {
        return <img src={SpinnerIcon} alt="spinner" />;
      }
    }
    return null;
  };

  const groupGlossariesByAlphabet = () => {
    const glossariesAfterGrouping = {};

    glossaries.forEach(glossary => {
      const firstCharacterOfGlossary = glossary.term.charAt(0).toUpperCase();

      if (glossariesAfterGrouping[firstCharacterOfGlossary]) {
        glossariesAfterGrouping[firstCharacterOfGlossary].push(glossary);
      } else {
        glossariesAfterGrouping[firstCharacterOfGlossary] = [glossary]; // First item entry in the array
      }
    });

    return {
      glossaries: glossariesAfterGrouping,
      keys: Object.keys(glossariesAfterGrouping).sort(),
    };
  };

  useEffect(() => {
    if (searchParam === '' || searchParam.length > MIN_LENGTH_TO_SEARCH) {
      fetchGlossaries.current(searchParam);
    }
  }, [searchParam]);

  useEffect(() => {
    const {
      glossaries: alphabeticallyGroupedGlossaries,
      keys: sortedKeysOfGlossaries,
    } = groupGlossariesByAlphabet();

    setGroupedGlossaries(alphabeticallyGroupedGlossaries);
    setKeysOfGroupedGlossaries(sortedKeysOfGlossaries);
  }, [glossaries]);

  const browserURL = document.location.hash.substring(1);

  useEffect(() => {
    if (keysOfGroupedGlossaries?.length > 0 && browserURL) {
      document.getElementById(browserURL).scrollIntoView(true);
    }
  }, [keysOfGroupedGlossaries]);

  return (
    <div className={`container-full ${className} glossary`}>
      <section className="full-width tertiary-light-bg glossary__description">
        <div className="grid">
          <div className="col-grid offset-1 col-xlg-5 col-lg-5 col-md-5 col-10 justify-center">
            <div className="glossary__description-header">
              <I18nText id="heroTab.resources.glossary.title" />
            </div>
            <div className="glossary__description-sub-header">
              <I18nText id="heroTab.resources.glossary.sub_title" />
            </div>
          </div>
          <div className="col-grid offset-1 col-xlg-4 col-lg-4 col-md-4 col-10 justify-center">
            <div className="glossary__search-box">
              <Input
                placeholder={I18n.t('components.features.platform.search')}
                postIcon={postIcon()}
                value={searchParam}
                onChange={handleInputChange}
                isClearable={false}
              />
            </div>
            <div className="glossary__item-navigators">
              {keysOfGroupedGlossaries.map(navigator => (
                <a
                  key={navigator}
                  href={`#${navigator}`}
                  className="glossary__item-navigators-link"
                >
                  {navigator}
                </a>
              ))}
            </div>
          </div>
        </div>
      </section>
      <div
        className={classNames('glossary__contents', [
          {
            'glossary__contents--loading': isFirstLoad,
            'glossary__contents--unavailable':
              !isLoading && glossaries.length === 0,
          },
        ])}
      >
        {keysOfGroupedGlossaries.map((glossaryKey, index) => (
          <section
            key={glossaryKey}
            id={`${glossaryKey}`}
            className={classNames('full-width glossary__contents-group', {
              'tertiary-light-bg': index % 2 !== 0,
            })}
          >
            <div className="grid">
              <div className="col-grid offset-1 col-10 justify-center">
                <div className="glossary__contents-group-header">
                  {glossaryKey}
                </div>
                {groupedGlossaries[glossaryKey].map(glossary => (
                  <div
                    key={`${glossary.category}_${glossary.term}`}
                    className="glossary__contents-group-term"
                  >
                    <div className="glossary__contents-group-term-title">
                      {glossary.term}
                    </div>
                    <div className="glossary__contents-group-term-definition">
                      <SanitizedHTML html={glossary.definition} />
                    </div>
                  </div>
                ))}
                <span
                  className="glossary__contents-group-back-to-top-button"
                  onClick={() => window.scrollTo(0, 0)}
                  role="button"
                  tabIndex={0}
                >
                  <I18nText id="heroTab.resources.glossary.back_to_top" />
                </span>
              </div>
            </div>
          </section>
        ))}
        {isFirstLoad && (
          <>
            <Spinner bgColor="nutral" size="large" />
            <I18nText id="heroTab.resources.glossary.loading_text" />
          </>
        )}
        {!isLoading && glossaries.length === 0 && (
          <>
            <Icon name="invalid" color="warning" />
            <I18nText id="heroTab.resources.shared.result_unavailable" />
          </>
        )}
      </div>
      <section className="full-width secondary-bg glossary__contact">
        <div className="grid">
          <div className="col-grid offset-1 col-xlg-4 col-lg-4 col-md-4 col-10 justify-center">
            <div className="glossary__contact-header">
              <I18nText id="heroTab.resources.glossary.contact.header" />
            </div>
          </div>
          <div className="col-grid offset-1 col-xlg-5 col-lg-5 col-md-5 col-10 justify-center contact-us__wrapper">
            <div className="glossary__contact-sub-header">
              <I18nText id="heroTab.resources.glossary.contact.sub_header" />
            </div>
            <ContactPage
              linkText={
                <span className="button button--v2 button--normal button--primary">
                  <I18nText id="homepage.features.support.contact_us" />
                </span>
              }
              signInUrl={additionalProps.routes.signInUrl}
              recaptchaKey={recaptchaKey}
              recaptchaWidgetSrc={recaptchaWidgetSrc}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

GlossaryBody.defaultProps = {
  className: '',
  additionalProps: {
    routes: {
      signInUrl: '',
    },
  },
};

GlossaryBody.propTypes = {
  className: PropTypes.string,
  additionalProps: PropTypes.shape({
    routes: PropTypes.shape({
      signInUrl: PropTypes.string,
    }),
  }),
};

export default GlossaryBody;
