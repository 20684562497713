import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Amount } from '@wtag/react-comp-lib';
import Link from '@wtag/rcl-link';
import Button from '@wtag/rcl-button';
import WorkspacesFeature from '../../../shared/helper/workspaceFeature';
import DowngradeModal from './Subcription/DowngradeModal';

const MONTH_INDICATOR_KEY = 'monthly';
const YEAR_INDICATOR_KEY = 'yearly';

const PlanCard = props => {
  const {
    className,
    id,
    currentPlan,
    currency,
    name,
    period,
    price,
    searches,
    bookings,
    travelers,
    supportTicket,
    phone,
    channels,
    workspaces,
    domain,
    affiliateDeactivated,
    isDowngrading,
    canChangePlan,
    showSignUpLink,
    planDetailPageUrl,
    disablePlanChange,
    signUpUrl,
  } = props;
  const [showDowngradeModal, setShowDowngradeModal] = useState(false);
  const isCurrentlyActivePlan = currentPlan && id === currentPlan.id;
  const isFreePlan = parseFloat(price) <= 0;

  const handleDowngradeModalState = () => {
    setShowDowngradeModal(prevState => !prevState);
  };

  return (
    <>
      <DowngradeModal
        isVisible={showDowngradeModal}
        planName={name}
        planDetailPageUrl={planDetailPageUrl}
        onReject={handleDowngradeModalState}
      />
      <div
        className={classNames(
          'plan-card',
          {
            'plan-card--active': isCurrentlyActivePlan,
            'plan-card--disabled':
              !isCurrentlyActivePlan && affiliateDeactivated,
          },
          className,
        )}
        data-selected={I18n.t('components.pricing.plans.selected')}
      >
        <h4 className="plan-card__title">
          {I18n.t(name, {
            scope: 'components.plans',
          })}
        </h4>
        <div className="plan-card__price">
          {price && price === 'TBD' ? price : null}
          {price && price !== 'TBD' ? (
            <Amount
              currency={currency}
              currencyDisplay="code"
              value={price}
              minFractionDigit={1}
              returnStringOnly={true}
            />
          ) : null}
          {!price ? `${currency} ---` : null}
        </div>
        <div className="plan-card__subscription-period">
          {isFreePlan &&
            I18n.t('components.pricing.plans.free_plan_card_help_text')}
          {!isFreePlan &&
            period === MONTH_INDICATOR_KEY &&
            I18n.t('components.pricing.plans.monthly_subscription_text')}
          {!isFreePlan &&
            period === YEAR_INDICATOR_KEY &&
            I18n.t('components.pricing.plans.yearly_subscription_text')}
        </div>
        <div className="plan-card__divider" />
        <ul className="plan-card__feature-list">
          <li className="plan-card__feature-list-item">
            <span className="plan-card__feature-list-item-name">
              {I18n.t('components.pricing.plans.features.searches')}
            </span>
            <span className="plan-card__feature-list-item-value">
              {searches
                ? I18n.t('components.billing.plan.status.unlimited')
                : '-'}
            </span>
          </li>
          <li className="plan-card__feature-list-item">
            <span className="plan-card__feature-list-item-name">
              {I18n.t('components.pricing.plans.features.bookings')}
            </span>
            <span className="plan-card__feature-list-item-value">
              {bookings
                ? I18n.t('components.billing.plan.status.unlimited')
                : '-'}
            </span>
          </li>
          <li className="plan-card__feature-list-item">
            <span className="plan-card__feature-list-item-name">
              {I18n.t('components.pricing.plans.features.profiles')}
            </span>
            <span className="plan-card__feature-list-item-value">
              {travelers
                ? I18n.t('components.billing.plan.status.unlimited')
                : '-'}
            </span>
          </li>
          <li className="plan-card__feature-list-item">
            <span className="plan-card__feature-list-item-name">
              {I18n.t('components.pricing.plans.features.workspaces')}
            </span>
            <span className="plan-card__feature-list-item-value">
              <WorkspacesFeature plan={{ name, workspaces }} />
            </span>
          </li>
          <li className="plan-card__feature-list-item">
            <span className="plan-card__feature-list-item-name">
              {I18n.t('components.pricing.plans.features.tickets')}
            </span>
            <span className="plan-card__feature-list-item-value">
              {supportTicket
                ? I18n.t('components.pricing.plans.features.available')
                : '-'}
            </span>
          </li>
          <li className="plan-card__feature-list-item">
            <span className="plan-card__feature-list-item-name">
              {I18n.t('components.pricing.plans.features.phone')}
            </span>
            <span className="plan-card__feature-list-item-value">
              {phone > 0
                ? I18n.t('components.billing.plan.phone_unlimited', {
                    plan_phone: phone,
                  })
                : '-'}
            </span>
          </li>
          <li className="plan-card__feature-list-item">
            <span className="plan-card__feature-list-item-name">
              {I18n.t('components.pricing.plans.features.channels')}
            </span>
            <span className="plan-card__feature-list-item-value">
              {channels !== null
                ? I18n.t(channels, {
                    scope: 'components.billing.plan.status',
                  })
                : I18n.t('components.billing.plan.status.unlimited')}
            </span>
          </li>
          <li className="plan-card__feature-list-item">
            <span className="plan-card__feature-list-item-name">
              {I18n.t('components.pricing.plans.features.domain')}
            </span>
            <span className="plan-card__feature-list-item-value">
              {domain
                ? I18n.t('components.pricing.plans.features.available')
                : '-'}
            </span>
          </li>
        </ul>
        {canChangePlan && isDowngrading ? (
          <Button
            className="plan-card__button"
            label={I18n.t('components.pricing.plans.downgrade')}
            size="normal"
            type="default"
            block={true}
            disabled={affiliateDeactivated || disablePlanChange}
            onClick={handleDowngradeModalState}
          />
        ) : null}
        {canChangePlan && !isDowngrading ? (
          <Link
            className="plan-card__button"
            href={planDetailPageUrl}
            type="button"
            size="normal"
            modifier={isDowngrading ? 'default' : 'primary'}
            fullWidth={true}
            disabled={affiliateDeactivated || disablePlanChange}
          >
            {I18n.t('components.pricing.plans.upgrade')}
          </Link>
        ) : null}
        {showSignUpLink ? (
          <Link
            className="plan-card__button"
            href={signUpUrl}
            type="button"
            size="normal"
            modifier={isDowngrading ? 'default' : 'primary'}
            fullWidth={true}
            disabled={affiliateDeactivated}
          >
            {I18n.t('components.pricing.plans.actions.start')}
          </Link>
        ) : null}
      </div>
    </>
  );
};

PlanCard.defaultProps = {
  className: '',
  channels: null,
  workspaces: null,
  canChangePlan: true,
  disablePlanChange: false,
  showSignUpLink: false,
  planDetailPageUrl: '',
  signUpUrl: '',
};

PlanCard.propTypes = {
  className: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  currentPlan: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    price: PropTypes.string.isRequired,
  }).isRequired,
  currency: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  searches: PropTypes.bool.isRequired,
  bookings: PropTypes.bool.isRequired,
  travelers: PropTypes.bool.isRequired,
  supportTicket: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  channels: PropTypes.number,
  workspaces: PropTypes.number,
  domain: PropTypes.string.isRequired,
  affiliateDeactivated: PropTypes.bool.isRequired,
  isDowngrading: PropTypes.bool.isRequired,
  canChangePlan: PropTypes.bool,
  disablePlanChange: PropTypes.bool,
  showSignUpLink: PropTypes.bool,
  planDetailPageUrl: PropTypes.string,
  signUpUrl: PropTypes.string,
};

export default PlanCard;
