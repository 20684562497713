import React, { useState } from 'react';
import Input from '@wtag/rcl-input';
import { RTabs } from '@wtag/rcl-rtabs';
import { I18nText } from '@wtag/react-comp-lib';
import GettingStarted from './GettingStarted';
import OrderManagement from './OrderManagement';
import OnlineBookingEngine from './OnlineBookingEngine';
import CorporateBooking from './CorporateBooking';

import {
  gettingStarted,
  orderManagement,
  onlineBookingEngine,
  corporateBooking,
} from '../../helper/tutorialsData';

const videosByCategory = [
  {
    id: 1,
    title: <I18nText id="tutorials.title1" />,
    collection: gettingStarted,
    getContentComponent: items => <GettingStarted items={items} />,
  },
  {
    id: 2,
    title: <I18nText id="tutorials.title3" />,
    collection: orderManagement,
    getContentComponent: items => <OrderManagement items={items} />,
  },
  {
    id: 3,
    title: <I18nText id="tutorials.title4" />,
    collection: onlineBookingEngine,
    getContentComponent: items => <OnlineBookingEngine items={items} />,
  },
  {
    id: 4,
    title: <I18nText id="tutorials.title5" />,
    collection: corporateBooking,
    getContentComponent: items => <CorporateBooking items={items} />,
  },
];

const filterCollection = (array, keyWordToSearch) =>
  array.filter(item => item.name.toLowerCase().includes(keyWordToSearch));

const Tutorials = () => {
  const [searchParam, setSearchParam] = useState('');

  const filteredVideosByCategory = videosByCategory
    .map(category => {
      const { id, title, collection, getContentComponent } = category;
      const filteredCollection = filterCollection(
        collection,
        searchParam.toLowerCase(),
      );

      return {
        id,
        title,
        collection: filteredCollection,
        content: getContentComponent(filteredCollection),
      };
    })
    .filter(category => category.collection.length !== 0);

  return (
    <div className="tutorials">
      <div>
        <div className="tutorials__wrapper">
          <div>
            <p className="tutorials__header">
              <I18nText id="tutorials.header1" />
            </p>
            <p className="tutorials__sub-header">
              <I18nText id="tutorials.sub_header1" />
            </p>
          </div>
          <div className="tutorials__subs-button">
            <a
              href="https://www.youtube.com/channel/UCijR-nmt7VgBAe3o_zdAw6g?sub_confirmation=1"
              target="_blank"
              rel="noopener noreferrer"
              className="button button--v2 button--small button--primary"
            >
              <I18nText id="tutorials.button_text" />
            </a>
          </div>
        </div>
      </div>
      <div className="tutorials__tabs-wrapper">
        <div className="tutorials__wrapper">
          <p className="tutorials__big-header">
            <I18nText id="tutorials.header2" />
          </p>
          <div className="tutorials__search">
            <Input
              value={searchParam}
              onChange={value => setSearchParam(value)}
              placeholder={I18n.t('components.support.search')}
              touched={true}
              isClearable={true}
            />
          </div>
        </div>
        <div className="tutorials__tabs">
          {filteredVideosByCategory.length > 0 ? (
            <RTabs
              items={filteredVideosByCategory}
              tabsWrapperClass="rtabs__tab--min-width"
            />
          ) : (
            <div className="glossary__contents__unavailable">
              <I18nText id="heroTab.resources.shared.result_unavailable" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Tutorials;
