import React from 'react';
import PropTypes from 'prop-types';

const SecurityBody = ({ className }) => {
  return (
    <div className={`container-full policy ${className}`}>
      <div className="grid justify-center">
        <div className="col-xlg-10 col-lg-10 col-md-10 col-12">
          <div className="policy__text">
            <h1>Welltravel Security</h1>
            <h1>Security Practices and Procedures at Welltravel</h1>

            <p>Date of Last Revision: June 1st, 2020</p>

            <div>
              Security here at Welltravel is not taken lightly. Below,
              we&apos;ll outline both the physical and technical procedures we
              use to ensure your data is kept safe.
            </div>

            <h3>Technical Security and Encryption</h3>

            <div>
              Whenever your data is in transit between you and us, everything is
              encrypted, and sent using HTTPS. We protect your login from brute
              force attacks with rate limiting, and all passwords are filtered
              from all our logs and are one-way encrypted using industry
              standard bcrypt.
            </div>

            <h3>Security Through Coding Practices</h3>

            <div>
              Since so many security exploits take advantage of coding errors,
              part of security is having well-tested, well-reviewed code. At
              Welltravel, when code is written it requires at least 2 other
              developers to review the work before it makes it to our test
              servers. Once it&apos;s on our test servers, we make sure
              everything is working through a quality assurance process. When
              the code finally makes it to production it has had a lot of
              eyeballs on it. Developing this way means that it takes more time
              to get things done, but it also means that fewer mistakes get by.
            </div>

            <h3>Local Equipment Security</h3>

            <div>
              At the most basic level, our main physical space is locked and
              alarmed during off hours. In the event of a break-in, we may lose
              some expensive monitors, but since our servers don&apos;t reside
              in our buildings, they aren&apos;t vulnerable to smash-and-grab
              robberies. Customer data isn&apos;t on the laptops or stationary
              computers our employees use as they work. They connect over the
              the web using an encrypted connection (the same way your web
              browser does). Even still, local computers are password protected
              and encrypted.
            </div>

            <h3>Personnel Security</h3>

            <div>
              Welltravel is a small company, so thankfully we are able to hire
              some brilliant people who care about its success. Our employee
              turnover is extremely low (especially for the tech industry). To
              protect company data, including customer data, all employees sign
              a non-disclosure agreement when hired.
            </div>

            <h3>Security Response</h3>

            <div>
              We encourage all customers report any concerns of abuse, exploits,
              and other types of incidents on the following e-mail:
              <a href="mailto:legal@welltravel.com">legal@welltravel.com</a>.
              These reports are immediately escalated to our advanced support
              team.
            </div>

            <h3>Security Culture</h3>

            <div>
              Lastly, a word about the culture here in general. Most of us who
              work at Welltravel are also users of our software. Our personal
              data is in the same database as our customers. To date, we&apos;ve
              never had a breach or issue related to data theft. If that ever
              happened, we understand that the goodwill and reputation
              we&apos;ve been building over the years would vanish. It would be
              a major blow to all of our personal careers. This is another
              reason we go to such lengths described above.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SecurityBody.defaultProps = {
  className: '',
};

SecurityBody.propTypes = {
  className: PropTypes.string,
};

export default SecurityBody;
