import React from 'react';
import PropTypes from 'prop-types';

export default function HeroContent(props) {
  const { id, logo, slogan } = props;

  return (
    <div className={`hero-content content${id}`}>
      <img className="hero-content__logo" src={logo} alt="logo" />
      <h1 className="hero-content__slogan">{slogan}</h1>
      <img
        className="hero-content__background-logo"
        src={logo}
        alt="background-logo"
      />
    </div>
  );
}

HeroContent.defaultProps = {
  slogan: '',
};

HeroContent.propTypes = {
  id: PropTypes.number.isRequired,
  slogan: PropTypes.node,
  logo: PropTypes.node.isRequired,
};
