/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import {
  SideNav as RclSideNav,
  SideNavBrand,
  SideNavLink,
  SideNavList,
} from '@wtag/rcl-side-nav';
import { I18nText } from '@wtag/react-comp-lib';
import brandingIconWelltravel from '../../../../assets/images/welltravel.png';
import brandingLogoWelltravel from '../../../../assets/images/welltravel_full.png';

const SideNav = props => {
  const { supportNotificationCount, signedInProps } = props;
  const { signedIn, signedInAsAdmin } = signedInProps;
  const { sidebarRoutes } = props;
  const settingsURL = sidebarRoutes.settings;
  const currentLocation = window.location.pathname;

  const items = [
    {
      id: 0,
      icon: <Icon name="dashboard" />,
      url: sidebarRoutes.dashboard,
      label: <I18nText id="sideNav.dashboard" />,
      isPrivate: false,
    },
    {
      id: 1,
      icon: <Icon name="chartLine" />,
      url: sidebarRoutes.billingIndex,
      label: <I18nText id="sideNav.billing" />,
      isPrivate: sidebarRoutes.billingIndex === null,
    },
    {
      id: 2,
      icon: <Icon name="headsetMic" />,
      url: sidebarRoutes.supportTickets,
      label: <I18nText id="sideNav.support" />,
      notificationsCount: supportNotificationCount,
      isPrivate: sidebarRoutes.supportTickets === null,
    },
    {
      id: 3,
      icon: <Icon name="widgets" />,
      url: sidebarRoutes.workspaces,
      label: <I18nText id="sideNav.workspaces" />,
      isPrivate: sidebarRoutes.workspaces === null,
    },
    {
      id: 4,
      icon: <Icon name="personLine" />,
      url: sidebarRoutes.users,
      label: <I18nText id="sideNav.users" />,
      isPrivate: sidebarRoutes.users === null,
    },
    {
      id: 5,
      icon: <Icon name="af360" />,
      url: sidebarRoutes.affiliatesThreeSixty,
      label: <I18nText id="sideNav.affiliates" />,
      isPrivate: !signedInAsAdmin,
    },
  ];

  const settings = {
    icon: <Icon name="settings" />,
    url: settingsURL,
    label: <I18nText id="sideNav.settings" />,
    isPrivate: false,
  };

  return (
    signedIn && (
      <div>
        <RclSideNav className="sidebar">
          <SideNavBrand
            icon={brandingIconWelltravel}
            logo={brandingLogoWelltravel}
            url="/"
          />
          <SideNavList>
            {items.map(item => (
              <SideNavLink
                key={item.id}
                icon={item.icon}
                url={item.url}
                label={item.label}
                notificationsCount={item.notificationsCount}
                activeLink={currentLocation}
                isPrivate={item.isPrivate}
              />
            ))}
          </SideNavList>
          <SideNavList type="footer">
            <SideNavLink
              icon={settings.icon}
              url={settings.url}
              label={settings.label}
              isPrivate={settings.isPrivate}
              activeLink={currentLocation}
            />
          </SideNavList>
        </RclSideNav>
      </div>
    )
  );
};

SideNav.propTypes = {
  supportNotificationCount: PropTypes.number.isRequired,
  sidebarRoutes: PropTypes.shape({
    affiliatesThreeSixty: PropTypes.string,
    billingIndex: PropTypes.string,
    dashboard: PropTypes.string,
    settings: PropTypes.string,
    supportTickets: PropTypes.string,
    users: PropTypes.string,
    workspaces: PropTypes.string,
  }).isRequired,
  signedInProps: PropTypes.shape({
    signedIn: PropTypes.bool,
    signedInAsAdmin: PropTypes.bool,
  }).isRequired,
};

export default SideNav;
