import React from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';
import headphone from 'affiliateImages/headphone.svg';
import twitter from 'affiliateImages/logo-X-black.png';
import youtube from 'affiliateImages/youtube-logo.svg';
import ContactPage from '../ContactPage';

const Services = props => {
  const { routes, recaptchaKey, recaptchaWidgetSrc } = props;
  return (
    <div className="homepage__service">
      <div className="container">
        <div className="grid">
          <div className="col-xlg-3 col-lg-3 col-md-3 align-self-center hidden-sm hidden-xs hidden-xxs">
            <img
              src={headphone}
              alt="headphone"
              className="homepage__service-img"
            />
          </div>
          <div className="col-xlg-9 col-lg-9 col-md-9 col-12">
            <p className="homepage__service-header">
              <I18nText id="homepage.features.support.title" />
            </p>
            <p className="homepage__service-text">
              <I18nText id="homepage.features.support.text" />
            </p>
            <div className="homepage__service-btn-group">
              <div className="contact-us__wrapper">
                <ContactPage
                  linkText={
                    <I18nText id="homepage.features.support.contact_us" />
                  }
                  signInUrl={routes.signInUrl}
                  recaptchaKey={recaptchaKey}
                  recaptchaWidgetSrc={recaptchaWidgetSrc}
                />
              </div>
              <a
                href={routes.faqs}
                className="button button--v2 button--small"
                target="_blank"
                rel="noopener noreferrer"
              >
                <I18nText id="homepage.features.support.view_faq" />
              </a>
              <a
                href="https://twitter.com/welltravelHQ"
                target="_blank"
                rel="noopener noreferrer"
                className="homepage__service-social-button"
              >
                <img src={twitter} alt="twitter" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCijR-nmt7VgBAe3o_zdAw6g?sub_confirmation=1"
                target="_blank"
                rel="noopener noreferrer"
                className="homepage__service-social-button"
              >
                <img src={youtube} alt="Youtube" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Services.propTypes = {
  routes: PropTypes.shape({
    faqs: PropTypes.string.isRequired,
    signInUrl: PropTypes.string.isRequired,
  }).isRequired,
  recaptchaKey: PropTypes.string.isRequired,
  recaptchaWidgetSrc: PropTypes.string.isRequired,
};

export default Services;
