import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const WorkspaceTag = ({ acronym, workspaceIndex }) => {
  const tagColor = () => {
    const colorIndex = workspaceIndex % 8;
    switch (colorIndex) {
      case 0:
        return 'workspace-tag--unfocused-cerulean';
      case 1:
        return 'workspace-tag--analytical-sapphire';
      case 2:
        return 'workspace-tag--implicit-steel';
      case 3:
        return 'workspace-tag--undercover-sky';
      case 4:
        return 'workspace-tag--credible-dove';
      case 5:
        return 'workspace-tag--bleary-dove';
      case 6:
        return 'workspace-tag--processed-steel';
      case 7:
        return 'workspace-tag--trifling-orchid';
      default:
    }
    return null;
  };
  return (
    <span className={classNames('workspace-tag', tagColor())}>{acronym}</span>
  );
};

WorkspaceTag.defaultProps = {
  workspaceIndex: null,
};

WorkspaceTag.propTypes = {
  acronym: PropTypes.string.isRequired,
  workspaceIndex: PropTypes.number,
};

export default WorkspaceTag;
