import urlTemplate from 'url-template';

function url(template, params) {
  const myParams = { ...params };
  if (myParams.format === undefined) {
    myParams.format = 'json';
  }
  return urlTemplate.parse(template).expand(myParams);
}

const routes = {
  dropdownOptions: {
    list: params =>
      url('/dropdown-options/{type}{.format}{?q,page,per_page}{&filterParams*}', params),
  },
  affiliates: {
    list: params =>
      url(
        '/affiliates{.format}{?q,page,column,deactivated,direction,plan_ids[]*,workspace_ids[]*,user_ids[]*}',
        params,
      ),
    workspaceOptions: params => url('/affiliates/workspace_options{.format}{?q}', params),
    fetch: params => url('/affiliates/{id}{.format}', params),
    update: params => url('/affiliates/{id}{.format}', params),
    deactivate: params => url('/affiliates/{id}/deactivate{.format}', params),
    credentials: params => url('/affiliates/{id}/credentials{.format}', params),
    logs: {
      list: params => url('/affiliates/{affiliate_id}/affiliate_logs{.format}', params),
      create: params => url('/affiliates/{affiliate_id}/affiliate_logs{.format}', params),
    },
    showPage: params => url('/affiliates/{affiliate_id}', params),
  },
  countries: {
    list: () => url('/countries{.format}'),
  },
  cities: {
    list: params => url('/cities{.format}{?city_name}', params),
    single: params => url('/cities/{city_id}{.format}', params),
  },
  dashboard: {
    briefingTasks: params => url('/dashboard/briefing_tasks{.format}', params),
  },
  billing: {
    credit_cards: {
      list: params =>
        url('/affiliates/{affiliate_id}/billing/stripe_credit_cards{.format}', params),
      update: params =>
        url('/affiliates/{affiliate_id}/billing/stripe_credit_cards/{id}{.format}', params),
    },
    invoices: {
      fetch: params => url('/billing/invoices/{id}{.format}', params),
      list: params =>
        url(
          '/affiliates/{affiliate_id}/billing/invoices{.format}{?state,page,column,direction,min_amount,max_amount}',
          params,
        ),
      create: params => url('/affiliates/{affiliate_id}/billing/invoices{.format}', params),
      chargeCreditCard: params =>
        url('/affiliates/{affiliate_id}/billing/invoices/charge_credit_card{.format}', params),
      delete: params => url('/billing/invoices/{id}{.format}{?cancellation_reason}', params),
      pdf_details: params => url('/billing/invoices/{id}/pdf_details{.format}', params),
    },
    transactions: {
      fetch: params => url('/billing/transactions/{id}{.format}', params),
      list: params =>
        url(
          '/affiliates/{affiliate_id}/billing/transactions{.format}{?page,column,direction,min_amount,max_amount,transaction_type}',
          params,
        ),
      pdf_details: params => url('/billing/transactions/{id}/pdf_details{.format}', params),
    },
    wireTransferPayments: {
      create: params =>
        url('/affiliates/{affiliate_id}/billing/wire_transfer_payments{.format}', params),
    },
    plans: {
      list: params =>
        url('/affiliates/{affiliate_id}/billing/plans{.format}{?period,public_only}', params),
      fetch: params => url('/affiliates/{affiliate_id}/billing/plans/{id}{.format}', params),
      update: params => url('/affiliates/{affiliate_id}/billing/plans/{id}{.format}', params),
    },
    showPage: params => url('/affiliates/{affiliate_id}/billing', params),
    fetch: params => url('/affiliates/{affiliate_id}/billing{.format}', params),
    subscriptions: {
      upgrade: {
        detail: params =>
          url(
            '/affiliates/{affiliate_id}/billing/subscriptions/upgrades/new{?identifier,period}',
            params,
          ),
        fetch: params =>
          url(
            '/affiliates/{affiliate_id}/billing/subscriptions/upgrades/new{.format}{?identifier,period}',
            params,
          ),
        changePlan: params =>
          url('/affiliates/{affiliate_id}/billing/subscriptions/upgrades{.format}{?id}', params),
      },
      downgrade: {
        detail: params =>
          url(
            '/affiliates/{affiliate_id}/billing/subscriptions/downgrades/new{?identifier,period}',
            params,
          ),
        fetch: params =>
          url(
            '/affiliates/{affiliate_id}/billing/subscriptions/downgrades/new{.format}{?identifier,period,deactivate_workspaces[]*,deactivate_channels[]*}',
            params,
          ),
        changePlan: params =>
          url(
            '/affiliates/{affiliate_id}/billing/subscriptions/downgrades{.format}{?plan_id}',
            params,
          ),
        cancel: params =>
          url('/affiliates/{affiliate_id}/billing/subscriptions/downgrades/{id}{.format}', params),
      },
    },
  },
  credentials: {
    new: params => url('/affiliates/{affiliateId}/credentials/new{.format}{?supplier}', params),
    create: params => url('/affiliates/{affiliateId}/credentials{.format}{?supplier}', params),
    edit: params => url('/credentials/{id}/edit{.format}{?supplier}', params),
    update: params => url('/credentials/{id}{.format}{?supplier}', params),
    delete: params => url('/credentials/{id}{.format}{?supplier}', params),
  },
  settings: {
    index: params => url('/settings{.format}', params),
  },
  bankDetails: {
    fetch: params => url('/bank_details{.format}{?currency_code}', params),
  },
  staticPage: {
    contactUs: params => url('/p/contact_us{.format}{?email_address,recaptcha_token}', params),
  },
  support: {
    tickets: {
      assign: params => url('/tickets/{support_ticket_id}/assign{.format}', params),
      create: params => url('/tickets{.format}', params),
      list: params =>
        url(
          '/tickets{.format}{?q,affiliate_id,assigned_to_id,start_date,end_date,statuses[]*}',
          params,
        ),
      new: params => url('/tickets/new{.format}', params),
    },
    ticketMessages: params =>
      url('/tickets/{support_ticket_id}/support_ticket_messages{.format}', params),
    state: {
      changeTicketState: params => url('/tickets/{support_ticket_id}/change_ticket_state', params),
    },
    message: {
      update: params => url('/tickets/{support_ticket_id}/support_ticket_messages/{id}', params),
      delete: params => url('/tickets/{support_ticket_id}/support_ticket_messages/{id}', params),
      send: params =>
        url('/tickets/{support_ticket_id}/support_ticket_messages/{id}/send_message', params),
      saveDraft: params =>
        url('/tickets/{support_ticket_id}/support_ticket_messages/{id}/save_draft', params),
      saveInternal: params =>
        url(
          '/tickets/{support_ticket_id}/support_ticket_messages/{id}/save_internal_message',
          params,
        ),
      schedule: params =>
        url('/tickets/{support_ticket_id}/support_ticket_messages/{id}/schedule_message', params),
    },
  },
  users: {
    list: params =>
      url(
        '/users{.format}{?q,page,affiliate_id,column,direction,state,statuses[]*,teams[]*}',
        params,
      ),
    listInvites: params =>
      url(
        '/users/invites{.format}{?q,page,affiliate_id,column,direction,statuses[]*,teams[]*}',
        params,
      ),
    resendInvite: params => url('/users/{userId}/resend_invite', params),
    verifyEmail: params => url('/users/{userId}/verify', params),
    reverifyEmail: params => url('/users/{userId}/reverify', params),
    reactivate: params => url('/users/{userId}/reactivate', params),
    update: params => url('/users/{id}', params),
    updatePassword: params => url('/passwords', params),
    multiFactorAuth: params => url('/mfas', params),
    create: params => url('/users{.format}', params),
    destroy: params => url('/users/{userId}{.format}', params),
    invite: params => url('/users/invite{.format}', params),
  },
  subscriptions: {
    old: {
      list: params => url('/affiliates/{affiliate_id}/subscriptions{.format}', params),
    },
  },
  invites: {
    confirm: params => url('/invites/{confirmationToken}', params),
  },
  plans: {
    list: () => url('/plans{.format}'),
  },
  teams: {
    list: params => url('/teams{.format}{?q,page,affiliate_id,direction}', params),
    create: params => url('/teams{.format}', params),
    update: params => url('/teams/{id}', params),
    destroy: params => url('/teams/{teamId}{.format}', params),
  },
  workspace: {
    fetch: params =>
      url('/affiliates/{affiliate_id}/workspaces{.format}{?q,page,column,direction,state}', params),
    update: params => url('/affiliates/{affiliate_id}/workspaces/{id}', params),
    create: params => url('/affiliates/{affiliate_id}/workspaces{.format}', params),
    delete: params => url('/affiliates/{affiliate_id}/workspaces/{id}', params),
  },
  signUp: {
    create: params => url('/sign_ups', params),
    update: params => url('/sign_ups/{id}', params),
    fetch: params => url('/sign_ups/{id}{.format}', params),
    requestPhoneValidation: id => url('/sign_ups/{id}/request_mobile_verification_code', { id }),
    updatePhoneNumber: id => url('/sign_ups/{id}/mobile_number', { id }),
    validatePhoneNumber: id => url('/sign_ups/{id}/confirm_mobile', { id }),
  },
  faqs: {
    fetch: params => url('/p/faqs/{id}{.format}{?id}', params),
    update: {
      thumbUp: params => url('/p/faqs/thumb_up{.format}{?id}', params),
      thumbDown: params => url('/p/faqs/thumb_down{.format}{?id}', params),
    },
  },
  public: {
    staticPages: {
      faq: {
        search: params => url('/p/resources/faqs{.format}{?q}', params),
      },
      glossaries: params => url('/p/resources/glossary{.format}{?q}', params),
      platformFeatures: params => url('/p/features/applications{.format}{?q}', params),
    },
  },
};

export default routes;
