import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';
import support from 'affiliateImages/support.svg';
import cancel from 'affiliateImages/cancel.svg';
import cost from 'affiliateImages/cost.svg';
import device from 'affiliateImages/device.svg';
import location from 'affiliateImages/location.svg';
import pay from 'affiliateImages/pay.svg';
import reward from 'affiliateImages/reward.svg';
import update from 'affiliateImages/update.svg';
import PlatformPlans from '../../PlatformPlans';
import SupportSection from '../../SupportSection';
import FAQPricing from '../../FAQPricing';

const PlatformPricingBody = ({ className, additionalProps }) => {
  return (
    <div className={classNames('platform-plans__section', `${className}`)}>
      <PlatformPlans signUpUrl={additionalProps.signUpUrl} />
      <div className="platform-plans__payment-section grid justify-center">
        <div className="col-xlg-10 col-lg-10 col-md-10 col-12">
          <div className="justify-center">
            <div className="grid">
              <div className="col-lg-6">
                <div className="platform-plans__section-header">
                  <I18nText id="pricing.plans.section_one.header" />
                </div>
                <ul className="platform-plans__section-list">
                  <li>
                    <span>
                      <I18nText id="pricing.plans.section_one.list_one" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <I18nText id="pricing.plans.section_one.list_two" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <I18nText id="pricing.plans.section_one.list_three" />
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6">
                <div className="platform-plans__section-description">
                  <I18nText id="pricing.plans.section_one.description" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="platform-plans__advantage-section">
        <div className="platform-plans__advantage-section-bg">
          <div className="grid justify-center">
            <div className="col-xlg-10 col-lg-10 col-md-10 col-12">
              <div className="justify-center">
                <div className="platform-plans__section-header">
                  <I18nText id="pricing.plans.section_two.header" />
                </div>
                <div className="grid">
                  <div className="col-lg-6">
                    <div className="platform-plans__section-header-sub">
                      <I18nText id="pricing.plans.section_two.header_one" />
                    </div>
                    <div>
                      <ul className="platform-plans__section-list">
                        <li>
                          <I18nText id="pricing.plans.section_two.list_one" />
                        </li>
                        <li>
                          <I18nText id="pricing.plans.section_two.list_two" />
                        </li>
                        <li>
                          <I18nText id="pricing.plans.section_two.list_three" />
                        </li>
                        <li>
                          <I18nText id="pricing.plans.section_two.list_four" />
                        </li>
                        <li>
                          <I18nText id="pricing.plans.section_two.list_five" />
                        </li>
                        <li>
                          <I18nText id="pricing.plans.section_two.list_six" />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="platform-plans__section-header-sub">
                      <I18nText id="pricing.plans.section_two.header_two" />
                    </div>
                    <div>
                      <ul className="platform-plans__section-list">
                        <li>
                          <I18nText id="pricing.plans.section_two.list_seven" />
                        </li>
                        <li>
                          <I18nText id="pricing.plans.section_two.list_eight" />
                        </li>
                        <li>
                          <I18nText id="pricing.plans.section_two.list_nine" />
                        </li>
                        <li>
                          <I18nText id="pricing.plans.section_two.list_ten" />
                        </li>
                        <li>
                          <I18nText id="pricing.plans.section_two.list_eleven" />
                        </li>
                        <li>
                          <I18nText id="pricing.plans.section_two.list_twelve" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="platform-plans__subscription-section">
        <div className="grid justify-center">
          <div className="col-xlg-10 col-lg-10 col-md-10 col-12">
            <div className="justify-center">
              <div className="platform-plans__section-header">
                <I18nText id="pricing.plans.section_three.header" />
              </div>
              <div className="grid">
                <div className="col-lg-6">
                  <div>
                    <img src={cost} alt="subscription_benefits" />
                  </div>
                  <div>
                    <p className="platform-plans__subscription-section-header">
                      <I18nText id="pricing.plans.section_three.one_header" />
                    </p>
                  </div>
                  <div className="platform-plans__section-description">
                    <I18nText id="pricing.plans.section_three.one_description" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div>
                    <img src={pay} alt="subscription_benefits" />
                  </div>
                  <div>
                    <p className="platform-plans__subscription-section-header">
                      <I18nText id="pricing.plans.section_three.two_header" />
                    </p>
                  </div>
                  <div className="platform-plans__section-description">
                    <I18nText id="pricing.plans.section_three.two_description" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div>
                    <img src={cancel} alt="subscription_benefits" />
                  </div>
                  <div>
                    <p className="platform-plans__subscription-section-header">
                      <I18nText id="pricing.plans.section_three.three_header" />
                    </p>
                  </div>
                  <div className="platform-plans__section-description">
                    <I18nText id="pricing.plans.section_three.three_description" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div>
                    <img src={support} alt="subscription_benefits" />
                  </div>
                  <div className="platform-plans__subscription-section-header">
                    <p>
                      <I18nText id="pricing.plans.section_three.four_header" />
                    </p>
                  </div>
                  <div className="platform-plans__section-description">
                    <I18nText id="pricing.plans.section_three.four_description" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div>
                    <img src={update} alt="subscription_benefits" />
                  </div>
                  <div className="platform-plans__subscription-section-header">
                    <p>
                      <I18nText id="pricing.plans.section_three.five_header" />
                    </p>
                  </div>
                  <div className="platform-plans__section-description">
                    <I18nText id="pricing.plans.section_three.five_description" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div>
                    <img src={device} alt="subscription_benefits" />
                  </div>
                  <div className="platform-plans__subscription-section-header">
                    <p>
                      <I18nText id="pricing.plans.section_three.six_header" />
                    </p>
                  </div>
                  <div className="platform-plans__section-description">
                    <I18nText id="pricing.plans.section_three.six_description" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div>
                    <img src={location} alt="subscription_benefits" />
                  </div>
                  <div className="platform-plans__subscription-section-header">
                    <p>
                      <I18nText id="pricing.plans.section_three.seven_header" />
                    </p>
                  </div>
                  <div className="platform-plans__section-description">
                    <I18nText id="pricing.plans.section_three.seven_description" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div>
                    <img src={reward} alt="subscription_benefits" />
                  </div>
                  <div className="platform-plans__subscription-section-header">
                    <p>
                      <I18nText id="pricing.plans.section_three.eight_header" />
                    </p>
                  </div>
                  <div className="platform-plans__section-description">
                    <I18nText id="pricing.plans.section_three.eight_description" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="platform-plans__faq-section container">
        <div className="grid justify-center">
          <div className="col-xlg-10 col-lg-10 col-md-10 col-12">
            <div className="justify-center">
              <div className="platform-plans__section-header">
                <I18nText id="pricing.plans.section_four.header" />
              </div>
              <div>
                <FAQPricing />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SupportSection
        signInUrl={additionalProps.routes.signInUrl}
        recaptchaKey={additionalProps.recaptchaKey}
        recaptchaWidgetSrc={additionalProps.recaptchaWidgetSrc}
      />
    </div>
  );
};

PlatformPricingBody.defaultProps = {
  className: '',
  additionalProps: PropTypes.shape({
    routes: null,
    signUpUrl: null,
    signedIn: true,
  }),
};

PlatformPricingBody.propTypes = {
  className: PropTypes.string,
  additionalProps: PropTypes.shape({
    routes: PropTypes.shape({
      features: {
        platform: PropTypes.string,
      },
      pricing: {
        platform: PropTypes.string,
      },
      resources: {
        faqs: PropTypes.string,
        tutorials: PropTypes.string,
        glossary: PropTypes.string,
        documentation: PropTypes.string,
      },
      company: {
        about_us: PropTypes.string,
        careers: PropTypes.string,
        logos: PropTypes.string,
      },
      policies: {
        term: PropTypes.string,
        privacy: PropTypes.string,
        security: PropTypes.string,
      },
      signInUrl: PropTypes.string.isRequired,
    }),
    signedIn: PropTypes.bool.isRequired,
    signUpUrl: PropTypes.string,
    currentPlan: PropTypes.shape({
      name: PropTypes.string,
      identifier: PropTypes.string,
      id: PropTypes.number,
    }).isRequired,
    recaptchaKey: PropTypes.string.isRequired,
    recaptchaWidgetSrc: PropTypes.string.isRequired,
  }),
};

export default PlatformPricingBody;
