import React from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';

function MarketingCard(props) {
  const { data } = props;
  const hasDownload = !!data[0].link;

  const cards = data.map(card => (
    <div
      className="col-xlg-4 col-lg-4 col-md-4 col-12 col-grid align-start marketing-card"
      key={card.id}
    >
      <div className="marketing-card__image">
        <img src={card.logo} alt={card.logo} />
      </div>
      <div className="marketing-card__title">{card.title}</div>
      <div className="marketing-card__text">{card.text}</div>
      {hasDownload && (
        <a
          className="button button--v2 button--small button--primary"
          rel="noopener noreferrer"
          target="_blank"
          href={card.link}
        >
          <I18nText id="documentation.marketingCard.download" />
        </a>
      )}
    </div>
  ));
  return <div className="grid">{cards}</div>;
}

MarketingCard.defaultProps = {
  data: PropTypes.shape({
    link: null,
  }),
};

MarketingCard.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      logo: PropTypes.node.isRequired,
      title: PropTypes.node.isRequired,
      text: PropTypes.node.isRequired,
      link: PropTypes.string,
    }),
  ),
};

export default MarketingCard;
