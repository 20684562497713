import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

const SanitizedHTML = ({ html }) => (
  // eslint-disable-next-line react/no-danger
  <span className="sanitized-html" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }} />
);

SanitizedHTML.propTypes = {
  html: PropTypes.string.isRequired,
};

export default SanitizedHTML;
