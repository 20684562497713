import React from 'react';
import { I18nText } from '@wtag/react-comp-lib';
import PropTypes from 'prop-types';
import welltravelLogo from 'affiliateImages/welltravel_full.png';
import ContactPage from '../ContactPage';

export default function Footer(props) {
  const {
    routes,
    platformFeaturesRoutes,
    recaptchaKey,
    recaptchaWidgetSrc,
  } = props;
  const getCurrentYear = () => new Date().getFullYear();
  const links = {
    features: [
      {
        id: 0,
        title: <I18nText id="nav.top.link.platform_features" />,
        url: platformFeaturesRoutes.platformFeaturesProfiles,
      },
    ],
    pricing: [
      {
        id: 0,
        title: <I18nText id="nav.top.link.platform_pricing" />,
        url: routes.pricing.platform,
      },
    ],
    resources: [
      {
        id: 0,
        title: <I18nText id="heroTab.resources.faq.title" />,
        url: routes.resources.faqs,
      },
      {
        id: 1,
        title: <I18nText id="heroTab.resources.tutorials.title" />,
        url: routes.resources.tutorials,
      },
      {
        id: 2,
        title: <I18nText id="heroTab.resources.glossary.title" />,
        url: routes.resources.glossary,
      },
      {
        id: 3,
        title: <I18nText id="heroTab.resources.documentation.title" />,
        url: routes.resources.documentation,
      },
    ],
    company: [
      {
        id: 0,
        title: <I18nText id="heroTab.company.aboutus.title" />,
        url: routes.company.aboutUs,
      },
      {
        id: 1,
        title: <I18nText id="heroTab.company.careers.title" />,
        url: routes.company.careers,
      },
      {
        id: 2,
        title: <I18nText id="heroTab.company.logos.title" />,
        url: routes.company.logos,
      },
    ],
    secondary: [
      {
        id: 0,
        title: <I18nText id="footer.footer_links.secondary.title2" />,
        url: routes.company.careers,
      },
      {
        id: 1,
        title: <I18nText id="footer.footer_links.primary.title1" />,
        url: routes.policies.term,
      },
      {
        id: 2,
        title: <I18nText id="footer.footer_links.primary.title2" />,
        url: routes.policies.privacy,
      },
      {
        id: 3,
        title: <I18nText id="footer.footer_links.primary.title3" />,
        url: routes.policies.security,
      },
    ],
    copyright: I18n.t('components.footer.copyright', {
      year: getCurrentYear(),
    }),
  };

  return (
    <div className="footer">
      <div className="container footer__primary">
        <div className="grid">
          <div className="col-12">
            <div className="footer__primary-logo col-lg-4 col-12 col-bleed-x">
              <img src={welltravelLogo} alt="Welltravel Logo" />
            </div>
            <div className="footer__primary-tabs col-lg-8 col-12 col-bleed-x">
              <div className="footer__primary-tabs-tab col-lg-3 col-md-3 col-6 col-bleed">
                <div className="footer__primary-tabs-tab-title">
                  <I18nText id="nav.top.link.features" />
                </div>
                {links.features.map(({ url, title, id }) => (
                  <a
                    key={id}
                    className="footer__primary-tabs-tab-link"
                    href={url}
                  >
                    {title}
                  </a>
                ))}
              </div>
              <div className="footer__primary-tabs-tab col-lg-3 col-md-3 col-6 col-bleed">
                <div className="footer__primary-tabs-tab-title">
                  <I18nText id="nav.top.link.pricing" />
                </div>
                {links.pricing.map(({ url, title, id }) => (
                  <a
                    key={id}
                    className="footer__primary-tabs-tab-link"
                    href={url}
                  >
                    {title}
                  </a>
                ))}
              </div>
              <div className="footer__primary-tabs-tab col-lg-3 col-md-3 col-6 col-bleed">
                <div className="footer__primary-tabs-tab-title">
                  <I18nText id="nav.top.link.resources" />
                </div>
                {links.resources.map(({ url, title, id }) => (
                  <a
                    key={id}
                    className="footer__primary-tabs-tab-link"
                    href={url}
                  >
                    {title}
                  </a>
                ))}
              </div>
              <div className="footer__primary-tabs-tab col-lg-3 col-md-3 col-6 col-bleed">
                <div className="footer__primary-tabs-tab-title">
                  <I18nText id="nav.top.link.company" />
                </div>
                {links.company.map(({ url, title, id }) => (
                  <a
                    key={id}
                    className="footer__primary-tabs-tab-link"
                    href={url}
                  >
                    {title}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-full footer__secondary">
        <div className="container">
          <div className="grid">
            <div className="footer__secondary-links col-lg-9 col-md-7 col-12">
              <div className="footer__secondary-links-link contact-us__wrapper">
                <ContactPage
                  linkText={
                    <I18nText id="footer.footer_links.secondary.title1" />
                  }
                  signInUrl={routes.signInUrl}
                  recaptchaKey={recaptchaKey}
                  recaptchaWidgetSrc={recaptchaWidgetSrc}
                />
              </div>
              {links.secondary.map(({ url, title, id }) => (
                <a key={id} className="footer__secondary-links-link" href={url}>
                  {title}
                </a>
              ))}
            </div>
            <div className="footer__secondary-copyright-text col-lg-3 col-md-5 col-12">
              &copy; {links.copyright}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Footer.defaultProps = {
  platformFeaturesRoutes: null,
};

Footer.propTypes = {
  routes: PropTypes.shape({
    features: PropTypes.shape({
      platform: PropTypes.string,
    }).isRequired,
    pricing: PropTypes.shape({
      platform: PropTypes.string,
    }).isRequired,
    resources: PropTypes.shape({
      faqs: PropTypes.string,
      glossary: PropTypes.string,
      tutorials: PropTypes.string,
      documentation: PropTypes.string,
    }).isRequired,
    company: PropTypes.shape({
      aboutUs: PropTypes.string,
      logos: PropTypes.string,
      careers: PropTypes.string,
    }).isRequired,
    policies: PropTypes.shape({
      security: PropTypes.string.isRequired,
      privacy: PropTypes.string.isRequired,
      term: PropTypes.string.isRequired,
    }).isRequired,
    sitemap: PropTypes.string.isRequired,
    signInUrl: PropTypes.string.isRequired,
  }).isRequired,
  platformFeaturesRoutes: PropTypes.shape({
    platformFeaturesProfiles: PropTypes.string,
    platformFeaturesSearch: PropTypes.string,
    platformFeaturesBooking: PropTypes.string,
    platformFeaturesMobileApp: PropTypes.string,
    platformFeaturesInvoicing: PropTypes.string,
    platformFeaturesOnlineBookingTool: PropTypes.string,
    platformFeaturesReporting: PropTypes.string,
    platformFeaturesTaskManagement: PropTypes.string,
    platformFeaturesVisaAdvisory: PropTypes.string,
    platformFeaturesCorporateTravel: PropTypes.string,
    platformFeaturesLeisurePackages: PropTypes.string,
    platformFeaturesChat: PropTypes.string,
  }),
  recaptchaKey: PropTypes.string.isRequired,
  recaptchaWidgetSrc: PropTypes.string.isRequired,
};
