import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import play from 'affiliateImages/play-button.svg';

const OrderManagement = ({ items }) => {
  const [videoIDToShow, setVideoIDToShow] = useState();

  return (
    <div className="order-management">
      {items.map(({ video, label, time, description, id }) => (
        <div key={id} className="grid">
          <div
            className={classNames('col-12 tutorials__videos', {
              'tutorials__videos-active': videoIDToShow === id,
            })}
          >
            <div
              className={classNames('tutorials__videos-player', {
                'tutorials__videos-player-animate': videoIDToShow === id,
              })}
            >
              {videoIDToShow === id ? (
                <iframe
                  src={`${video}?autoplay=1`}
                  title="WellTravel"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen={true}
                  autoPlay={false}
                />
              ) : (
                <div className="tutorials__image-wrapper">
                  <div className="tutorials__image-thumb">
                    <img
                      src={`https://img.youtube.com/vi/${id}/0.jpg`}
                      alt="tutorial"
                      className="img-responsive"
                    />

                    <div className="tutorials__image-hover">
                      <div className="tutorials__image-hover--content">
                        <img
                          id={id}
                          src={play}
                          alt="tutorial"
                          role="presentation"
                          className="tutorials__icon img-responsive"
                          onClick={() => {
                            setVideoIDToShow(id);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div
              className={classNames('tutorials__videos-title', {
                'tutorials__videos-title-animate': videoIDToShow === id,
              })}
            >
              <div>
                <a
                  href={video}
                  className="tutorials__videos-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {label}
                </a>
              </div>
              <div
                className={classNames('tutorials__time', {
                  'tutorials__time-active': videoIDToShow === id,
                })}
              >
                {time}
              </div>
              <div className="tutorials__videos-description">{description}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

OrderManagement.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      video: PropTypes.string.isRequired,
      label: PropTypes.element.isRequired,
      name: PropTypes.string.isRequired,
      time: PropTypes.element.isRequired,
      description: PropTypes.element.isRequired,
    }),
  ).isRequired,
};

export default OrderManagement;
