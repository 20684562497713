import React from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';
import returnIcon from 'affiliateIcons/return.svg';
import clipboardIcon from 'affiliateIcons/clipboard.svg';
import hierarchyIcon from 'affiliateIcons/hierarchy.svg';
import MarketingCard from '../../MarketingCard';
import BrochureCard from '../../BrochureCard';

// eslint-disable-next-line react/prop-types
const DocumentationBody = ({ className }) => {
  const data = [
    {
      id: 0,
      logo: returnIcon,
      title: <I18nText id="documentation.marketingCard.card1.title" />,
      text: <I18nText id="documentation.marketingCard.card1.text" />,
      link: '/pdf/agency_setup_documentation.pdf',
    },
    {
      id: 1,
      logo: clipboardIcon,
      title: <I18nText id="documentation.marketingCard.card2.title" />,
      text: <I18nText id="documentation.marketingCard.card2.text" />,
      link: '/pdf/corporate_policy_documentation.pdf',
    },
    {
      id: 2,
      logo: hierarchyIcon,
      title: <I18nText id="documentation.marketingCard.card3.title" />,
      text: <I18nText id="documentation.marketingCard.card3.text" />,
      link: '/pdf/customer_profiles_documentation.pdf',
    },
  ];

  return (
    <div className={`container-full ${className}`}>
      <div className="grid justify-center">
        <div className="col-xlg-10 col-lg-10 col-md-10 col-12">
          <MarketingCard data={data} />
          <BrochureCard />
        </div>
      </div>
    </div>
  );
};

DocumentationBody.defaultProps = {
  className: '',
};

DocumentationBody.propTypes = {
  className: PropTypes.string,
};

export default DocumentationBody;
