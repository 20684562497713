import React, { useEffect, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import classNames from 'classnames';

import Input from '@wtag/rcl-input';
import Button from '@wtag/rcl-button';
import {
  AccordionSidePanel,
  AccordionSidePanelItem,
  Spinner,
  I18nText,
} from '@wtag/react-comp-lib';

import { ArrayParam, useQueryParams, withDefault } from 'use-query-params';
import withQueryParamsProvider from '../../../../../shared/components/withQueryParamsProvider';
import httpClient from '../../../../../shared/libraries/httpClient';
import routes from '../../../../../shared/routes';

import SanitizedHTML from '../../../../../shared/components/SanitizedHTML';

const MIN_LENGTH_TO_SEARCH = 1;

const FaqBody = ({ additionalProps }) => {
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [searchParam, setSearchParam] = useState('');
  const [rootItems, setRootItems] = useState([]);
  const [description, setDescription] = useState(null);
  const [isDescriptionLoading, setIsDescriptionLoading] = useState(false);
  const [currentFaqId, setCurrentFaqId] = useState(null);
  const [isFeedBackTaken, setIsFeedBackTaken] = useState(false);
  const [isDescriptionDrawerVisible, setIsDescriptionDrawerVisible] = useState(
    false,
  );
  const [urlQuery, setUrlQuery] = useQueryParams({
    faqID: withDefault(ArrayParam, []),
  });
  const { faqID: faqIDs } = urlQuery;

  const updateFaq = async thumbsUp => {
    setIsFeedBackTaken(true);

    if (thumbsUp) {
      await httpClient.put(routes.faqs.update.thumbUp(), {
        id: currentFaqId,
      });
    } else {
      await httpClient.put(routes.faqs.update.thumbDown(), {
        id: currentFaqId,
      });
    }
  };

  const fetchFaq = async id => {
    setIsFeedBackTaken(false);
    setIsDescriptionLoading(true);
    setCurrentFaqId(id);
    const { data } = await httpClient.get(routes.faqs.fetch({ id }));

    setDescription(data.faq.description);

    if (data.faq.description) {
      setIsDescriptionDrawerVisible(true);
    }

    setIsDescriptionLoading(false);
  };

  const mapFaqs = (data, itemLevel) => {
    return data.map(faq => (
      <AccordionSidePanelItem
        key={faq.id}
        itemID={faq.id}
        title={faq.title}
        itemLevel={itemLevel ? itemLevel + 1 : 1}
        hasChildItems={faq.nestedFaqs.length > 0}
        fetchChildItems={faq.nestedFaqs.length > 0 ? findFaqsById : null} // eslint-disable-line
        isInitiallyExplored={true}
        onClick={async ({ itemID, pathFromRoot }) => {
          setUrlQuery({ faqID: [...pathFromRoot] }, 'push');
          await fetchFaq(itemID);
        }}
      />
    ));
  };

  const findFaqsById = async (id, itemLevel) => {
    const { data } = await httpClient.get(routes.faqs.fetch({ id }));
    return mapFaqs(data.faq.nestedFaqs, itemLevel);
  };

  const initializeFaqIdArrayFromUrlQuery = (data, inputParam) => {
    if (faqIDs.length > 0 && !inputParam) return faqIDs;
    if (data.faqs.length > 0) return [data.faqs[0].id];
    return [];
  };

  const findFaqsBySearch = async inputParam => {
    setIsFeedBackTaken(false);

    const { data } = await httpClient.get(
      routes.public.staticPages.faq.search({ q: inputParam }),
    );

    const exploredFaqIdsPath = initializeFaqIdArrayFromUrlQuery(
      data,
      inputParam,
    );

    const currentFaqId =
      exploredFaqIdsPath.length > 0
        ? parseInt(exploredFaqIdsPath[exploredFaqIdsPath.length - 1], 10)
        : null;

    setUrlQuery({ faqID: exploredFaqIdsPath }, 'push');
    await fetchFaq(currentFaqId);
    return mapFaqs(data.faqs);
  };

  const fetchFaqs = useRef(
    debounce(1000, async inputParam => {
      const data = await findFaqsBySearch(inputParam);
      setIsFirstLoad(false);
      setRootItems(data);
    }),
  );

  useEffect(() => {
    if (searchParam === '' || searchParam.length > MIN_LENGTH_TO_SEARCH) {
      fetchFaqs.current(searchParam);
    }
  }, [searchParam]);

  const handleInputChange = value => {
    setSearchParam(value);
  };

  const exploredPathForFaqs = useMemo(
    () => faqIDs.map(faqItemID => parseInt(faqItemID, 10)),
    [faqIDs],
  );

  const feedBackContent = isFeedBackTaken ? (
    <div className="faq-wrapper__content-description-action">
      <I18nText id="heroTab.resources.faq.feedback_text" />
    </div>
  ) : (
    <div className="faq-wrapper__content-description-action">
      <div className="faq-wrapper__content-description-action-text">
        <I18nText id="heroTab.resources.faq.help_text" />
      </div>
      <div className="faq-wrapper__content-description-action-field">
        <Button
          label={<I18nText id="heroTab.resources.faq.action.thumbs_up" />}
          onClick={() => updateFaq(true)}
          size="small"
          version="v2"
          type="primary"
        />
        <Button
          label={<I18nText id="heroTab.resources.faq.action.thumbs_down" />}
          onClick={() => updateFaq(false)}
          size="small"
          version="v2"
        />
      </div>
    </div>
  );

  const descriptionContent = () => {
    let content;
    if (isDescriptionLoading) {
      content = (
        <div className="faq-wrapper__content-description-spinner">
          <Spinner size="huge" bgColor="neutral" />
        </div>
      );
    } else if (description) {
      content = (
        <div className="faq-wrapper__content-description-container">
          <SanitizedHTML html={description} />
          {additionalProps.signedIn && feedBackContent}
        </div>
      );
    } else {
      content = (
        <div className="faq-wrapper__content-description-empty-text">
          <I18nText id="heroTab.resources.faq.empty_text" />
        </div>
      );
    }
    return content;
  };

  const content = isFirstLoad ? (
    <div className="faq-wrapper__spinner">
      <Spinner size="huge" bgColor="neutral" />
    </div>
  ) : (
    <div className="grid faq-wrapper__content">
      <div className="col-12 col-md-3">
        <div className="faq-wrapper__content-side-panel">
          {rootItems.length > 0 && (
            <AccordionSidePanel exploredPath={exploredPathForFaqs}>
              {rootItems}
            </AccordionSidePanel>
          )}
        </div>
      </div>
      <div className="col-9 hidden-xxs hidden-xs hidden-sm">
        <div className="faq-wrapper__content-description">
          {descriptionContent()}
        </div>
      </div>
    </div>
  );

  return (
    <div className="container-full">
      <div className="grid justify-center faq-wrapper">
        <div className="col-xlg-10 col-lg-10 col-md-10 col-12">
          <div className="grid">
            <div className="col-12 col-md-3">
              <Input
                placeholder={I18n.t('components.features.platform.search')}
                value={searchParam}
                onChange={handleInputChange}
                isClearable={true}
              />
            </div>
          </div>
          {content}
        </div>
      </div>
      <div
        className={classNames(
          'col-12',
          'col-bleed',
          'faq-wrapper-description-drawer',
          {
            'faq-wrapper-description-drawer--visible': isDescriptionDrawerVisible,
          },
        )}
      >
        <div className="faq-wrapper__content-description">
          <Button
            onClick={() => setIsDescriptionDrawerVisible(false)}
            label={<I18nText id="support.back" />}
            version="v2"
            size="small"
          />
          {descriptionContent()}
        </div>
      </div>
    </div>
  );
};

FaqBody.propTypes = {
  additionalProps: PropTypes.shape({
    signedIn: PropTypes.bool.isRequired,
  }).isRequired,
};

export default withQueryParamsProvider(FaqBody);
