import { useEffect } from 'react';
import {
  APPCUES_KEYS,
  APPCUES_SOURCE,
  PATH_TO_REMOVE_APPCUES_REGEX,
} from '../constants/appcues';

const useAppcues = user => {
  const { id, first_name: firstName, last_name: lastName, email } = user || {};

  if (!id) {
    return;
  }

  const handleScriptLoad = () => {
    window.Appcues.identify(id, {
      name: firstName + lastName,
      email,
    });
  };

  useEffect(() => {
    const { pathname: currentPathName } = window.location;
    let script = null;

    if (PATH_TO_REMOVE_APPCUES_REGEX.test(currentPathName)) {
      APPCUES_KEYS.forEach(key => {
        delete window[key];
      });
    } else {
      window.AppcuesSettings = { enableURLDetection: true };

      script = document.createElement('script');
      script.src = APPCUES_SOURCE;
      script.async = true;

      script.addEventListener('load', handleScriptLoad);
      document.body.appendChild(script);
    }

    return () => {
      if (script) {
        script.removeEventListener('load', handleScriptLoad);
        document.body.removeChild(script);
      }
    };
  }, [id, window.location.pathname]);
};

export default useAppcues;
