import React from 'react';
import PropTypes from 'prop-types';
import Button from '@wtag/rcl-button';
import { I18nText, Link } from '@wtag/react-comp-lib';
import mobileapp from 'affiliateImages/homepage-mobileapp.svg';
import travelapp from 'affiliateImages/homepage-travelapp.svg';
import amadeus from 'affiliateImages/amadeus.svg';
import sabre from 'affiliateImages/Sabre.svg';
import travelport from 'affiliateImages/travelport.svg';
// uncomment after IATA certification
// import iata from 'affiliateImages/iata.png';
import atpco from 'affiliateImages/atpco.png';

const HeroImage = ({ routes, homePageRef, onButtonClick }) => {
  return (
    <div className="homepage__heroimage" ref={homePageRef}>
      <div className="container">
        <div className="grid">
          <div className="col-xlg-6 col-lg-6 col-md-7 col-sm-12 col-xs-12 col-xxs-12 col-grid align-self-center">
            <div>
              <p className="homepage__heroimage-header">
                <I18nText id="homepage.hero_image.title1" />
              </p>
              <p className="homepage__heroimage-sub-header">
                <I18nText id="homepage.hero_image.title2" />
              </p>
              <p className="homepage__heroimage-sales-text">
                <I18nText id="homepage.hero_image.title3" />
              </p>
              <Link
                href={routes.signUpUrl}
                type="button"
                openNewTab={true}
                size="small"
              >
                <I18nText id="homepage.hero_image.account" />
              </Link>
              <Button
                label={<I18nText id="homepage.hero_image.show_more" />}
                version="v2"
                size="small"
                onClick={() => onButtonClick(homePageRef)}
              />
            </div>
            <div className="homepage__partnership align-self-start">
              <div className="homepage__partnership-text">
                <I18nText id="homepage.hero_image.partnership" />
              </div>
              <div className="homepage__partnership-logos">
                <div>
                  <img
                    src={amadeus}
                    alt="amadeus"
                    className="homepage__partnership-logos--amadeus"
                  />
                </div>
                <div>
                  <img
                    src={sabre}
                    alt="sabre"
                    className="homepage__partnership-logos--sabre"
                  />
                </div>
                <div>
                  <img
                    src={travelport}
                    alt="travelport"
                    className="homepage__partnership-logos--travelport"
                  />
                </div>
              </div>
              <div className="homepage__partnership-logos">
                <div>
                  <img
                    src={atpco}
                    alt="atpco"
                    className="homepage__partnership-logos--atpco"
                  />
                </div>
                {/* TODO: remove comment when IATA certification is done <div>
                  <img
                    src={iata}
                    alt="iata"
                    className="homepage__partnership-logos--iata"
                  />
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-xlg-6 col-lg-6 col-md-5 col-grid hidden-sm hidden-xs hidden-xxs homepage__heroimage-section">
            <img
              src={travelapp}
              alt="hero"
              className="homepage__heroimage-section-image homepage__heroimage-section-image--travelapp align-self-center"
            />
            <img
              src={mobileapp}
              alt="hero"
              className="homepage__heroimage-section-image homepage__heroimage-section-image--mobileapp align-self-center"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

HeroImage.defaultProps = {
  homePageRef: PropTypes.shape({
    current: null,
  }),
  onButtonClick: null,
};

HeroImage.propTypes = {
  routes: PropTypes.shape({
    signUpUrl: PropTypes.string.isRequired,
  }).isRequired,
  homePageRef: PropTypes.shape({
    current: PropTypes.node,
  }),
  onButtonClick: PropTypes.func,
};

export default HeroImage;
