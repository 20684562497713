import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { CountryItem, PhoneNumber } from '@wtag/react-comp-lib';

import httpClient from '../../libraries/httpClient';
import routes from '../../routes';

const PhoneNumberInput = props => {
  const [countryCodeOptions, setCountryCodeOptions] = useState([]);
  const [countryCode, setCountryCode] = useState(props.initialCountryCode);
  const [phoneNumber, setPhoneNumber] = useState(props.initialPhoneNumber);

  const fetchCountryCodeOptions = async () => {
    const response = await httpClient.get(routes.countries.list());
    if (response.data && response.data.length > 0) {
      setCountryCodeOptions(response.data);
    }
  };

  const mappedCountryCodeOptions = countryCodeOptions.map(([label, value]) => ({
    value,
    label: <CountryItem countryName={label} countryCode={`+${value}`} />,
  }));

  useEffect(() => {
    fetchCountryCodeOptions();
  }, []);

  useEffect(() => {
    const countryCodeValue = countryCode ? countryCode.value : '';
    const formattedPhoneNumber =
      countryCodeValue && phoneNumber.length
        ? `${countryCodeValue}${phoneNumber}`
        : '';

    props.onChange(formattedPhoneNumber);
  }, [countryCode && countryCode.value, phoneNumber]);

  useEffect(() => {
    props.onCountryCodeChange(countryCode);
  }, [countryCode]);

  return (
    <PhoneNumber
      {...props}
      options={mappedCountryCodeOptions}
      selectedCountry={countryCode}
      onCountryChange={setCountryCode}
      inputValue={phoneNumber}
      onChange={setPhoneNumber}
    />
  );
};

PhoneNumberInput.defaultProps = {
  initialCountryCode: null,
  initialPhoneNumber: '',
  onCountryCodeChange: () => {},
};

PhoneNumberInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialCountryCode: PropTypes.string,
  initialPhoneNumber: PropTypes.string,
  onCountryCodeChange: PropTypes.func,
};

export default PhoneNumberInput;
