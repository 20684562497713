/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { Avatar, EmphasisTag, Link, I18nText } from '@wtag/react-comp-lib';
import { MenuItem } from '@wtag/rcl-menu';
import Popover from '@wtag/rcl-popover';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@wtag/rcl-button';
import logo from '../../../../assets/images/logo.svg';
import downArrow from '../../../../assets/images/arrow_down-24px.svg';
import menuIcon from '../../../../assets/images/menu.svg';
import menuOpenIcon from '../../../../assets/images/menu_open.svg';
import languageIcon from '../../../../assets/images/language.svg';
import affiliatesThreeSixty from '../../../../assets/images/af-360.svg';
import dashboard from '../../../../assets/images/dashboard.svg';
import headsetMic from '../../../../assets/images/headset_mic.svg';
import person from '../../../../assets/images/person.svg';
import pieChart from '../../../../assets/images/pie_chart.svg';
import widgets from '../../../../assets/images/widgets.svg';
import Registration from '../Registration/Registration';
import useAppcues from '../../hooks/useAppcues';
import SideDrawer from './SideDrawer';
import WorkspaceSettings from './WorkspaceSettings';
import ProfileMenu from '../ProfileMenu';

const Navbar = props => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isWorkspaceVisible, setIsWorkspaceVisible] = useState(false);
  const [isLanguageVisible, setIsLanguageVisible] = useState(false);
  const [isProfileMenuVisible, setIsProfileMenuVisible] = useState(false);
  const { signedIn, signedInAsAdmin } = props.signedInProps;
  const { currentUser } = props;
  const { renderLogo } = props;

  const [isScrolling, setIsScrolling] = useState(false);

  useAppcues(currentUser);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setIsScrolling(window.scrollY > 150);
    });
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navItems = [
    {
      label: <I18nText id="nav.top.link.features" />,
      link: '#',
      subMenu: [
        {
          label: <I18nText id="nav.top.link.platform_features" />,
          link: props.navbarRoutes.publicApplications,
        },
        {
          label: (
            <>
              <I18nText id="nav.top.link.api_features" />
              <EmphasisTag
                className="navbar__coming-soon-tag"
                text={<I18nText id="nav.top.link.coming_soon_tag" />}
                size="tiny"
              />
            </>
          ),
          link: undefined,
          // TODO: Add the link when the API is available
          // link: props.navbarRoutes.publicApiPricing,
        },
      ],
    },
    {
      label: <I18nText id="nav.top.link.pricing" />,
      link: '#',
      subMenu: [
        {
          label: <I18nText id="nav.top.link.platform_pricing" />,
          link: props.navbarRoutes.publicApplicationPricing,
        },
        {
          label: (
            <>
              <I18nText id="nav.top.link.api_pricing" />
              <EmphasisTag
                className="navbar__coming-soon-tag"
                text={<I18nText id="nav.top.link.coming_soon_tag" />}
                size="tiny"
              />
            </>
          ),
          link: undefined,
          // TODO: Add the link when the API is available
          // link: props.navbarRoutes.publicApiPricing,
        },
      ],
    },
    {
      label: <I18nText id="nav.top.link.resources" />,
      link: '#',
      subMenu: [
        {
          label: <I18nText id="nav.top.link.faqs" />,
          link: props.navbarRoutes.publicFaqs,
        },
        {
          label: <I18nText id="nav.top.link.tutorials" />,
          link: props.navbarRoutes.publicTutorials,
        },
        {
          label: <I18nText id="nav.top.link.glossary" />,
          link: props.navbarRoutes.publicGlossary,
        },
        // TODO: Enable documentation tab when it's ready to show
        // {
        //   label: <I18nText id="nav.top.link.documentation" />,
        //   link: props.navbarRoutes.publicDocumentation,
        // },
      ],
    },
    {
      label: <I18nText id="nav.top.link.company" />,
      link: '#',
      subMenu: [
        {
          label: <I18nText id="nav.top.link.about_us" />,
          link: props.navbarRoutes.publicAboutUs,
        },
        {
          label: <I18nText id="nav.top.link.careers" />,
          link: props.navbarRoutes.publicCareers,
        },
        {
          label: <I18nText id="nav.top.link.media" />,
          link: props.navbarRoutes.publicLogos,
        },
      ],
    },
  ];

  const sidebarLinks = [
    {
      icon: dashboard,
      label: <I18nText id="sideNav.dashboard" />,
      link: props.sidebarRoutes.dashboard,
      subMenu: [],
    },
    {
      icon: widgets,
      label: <I18nText id="sideNav.my_workspaces" />,
      link: '#',
      subMenu: [
        {
          label: signedIn && (
            <div className="navbar__my-workspace">
              <WorkspaceSettings affiliateId={props.currentUser.affiliate_id} />
            </div>
          ),
        },
      ],
    },
    {
      icon: pieChart,
      label: <I18nText id="sideNav.billing" />,
      link: props.sidebarRoutes.billingIndex,
      subMenu: [],
    },
    {
      icon: headsetMic,
      label: <I18nText id="sideNav.support" />,
      link: props.sidebarRoutes.supportTickets,
      subMenu: [],
    },
    {
      icon: widgets,
      label: <I18nText id="sideNav.workspaces" />,
      link: props.sidebarRoutes.workspaces,
      subMenu: [],
    },
    {
      icon: person,
      label: <I18nText id="sideNav.users" />,
      link: props.sidebarRoutes.users,
      subMenu: [],
    },
  ];

  const adminLinks = [
    {
      icon: affiliatesThreeSixty,
      menuItem: <I18nText id="sideNav.affiliates" />,
      url: props.sidebarRoutes.affiliatesThreeSixty,
    },
  ];

  const profileMenuLinks = {
    signOutUrl: props.homepageRoutes.signOutUrl,
    privacyUrl: props.navbarRoutes.publicPrivacy,
    settingsUrl: props.sidebarRoutes.settings,
    termsUrl: props.navbarRoutes.publicTerms,
  };

  return (
    <nav
      className={isScrolling ? 'navbar navbar--small' : 'navbar navbar--normal'}
    >
      <img
        src={isMenuOpen ? menuOpenIcon : menuIcon}
        alt="menu"
        className="navbar__menu-icon"
        onClick={toggleMenu}
        role="presentation"
      />

      <div
        className={classNames('navbar__logo', {
          'navbar__logo--small': isScrolling,
          'navbar__logo--normal': !isScrolling,
          'navbar__logo--private': !renderLogo,
        })}
      >
        <a href="/">
          <span>
            <img src={logo} alt="logo" />
          </span>
        </a>
      </div>

      <ul className="navbar__menu">
        {navItems.map(({ label, link, subMenu }, index) => (
          <li className="navbar__menu-item" key={index}>
            <a
              className={
                isScrolling
                  ? 'navbar__menu-link navbar__menu-link--small'
                  : 'navbar__menu-link navbar__menu-link--normal'
              }
              href={link}
            >
              {label}
            </a>
            {subMenu && (
              <img
                src={downArrow}
                className="navbar__menu-item-arrow"
                alt="arrow"
              />
            )}
            {subMenu && (
              <ul
                className={
                  isScrolling
                    ? 'navbar__sub-menu navbar__sub-menu--small'
                    : 'navbar__sub-menu navbar__sub-menu--normal'
                }
              >
                {subMenu.map(({ label, link }, index) => (
                  <li key={index} className="navbar__sub-menu-item">
                    <a
                      className={
                        isScrolling
                          ? 'navbar__sub-menu-link navbar__sub-menu-link--small'
                          : 'navbar__sub-menu-link navbar__sub-menu-link--normal'
                      }
                      href={link}
                    >
                      {label}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>

      <ul className="navbar__right">
        <div className="navbar__menu-item navbar__menu-item-dashboard navbar__tooltip navbar__right-item">
          {signedIn && (
            <li className="navbar__sign-options">
              <Link
                href={sidebarLinks[0].link}
                size="small"
                type="button"
                modifier="neutral"
              >
                <I18nText id="sideNav.dashboard" />
              </Link>
            </li>
          )}
        </div>
        <div className="navbar__menu-item navbar__tooltip navbar__right-item">
          {signedIn ? (
            <li className="navbar__right-item-workspaces navbar__sign-options">
              <Popover
                size="small"
                isVisible={isWorkspaceVisible}
                onOutsideClick={() => setIsWorkspaceVisible(false)}
                className="navbar__menu-item-locale-wrapper"
                direction="bottom-right"
                content={
                  <WorkspaceSettings
                    affiliateId={props.currentUser.affiliate_id}
                  />
                }
              >
                <Button
                  className="navbar__right-item-workspaces--button"
                  onClick={() => {
                    setIsWorkspaceVisible(
                      previousIsVisibleState => !previousIsVisibleState,
                    );
                  }}
                  label={<I18nText id="sideNav.workspaces" />}
                  type="primary"
                  size="small"
                />
              </Popover>
            </li>
          ) : (
            <li className="navbar__right-item-language">
              <Popover
                size="small"
                isVisible={isLanguageVisible}
                onOutsideClick={() => setIsLanguageVisible(false)}
                className="navbar__menu-item-language-wrapper"
                content={props.availableLocales.map(language => (
                  <MenuItem linkUrl={language.url}>
                    {language.humanName}
                  </MenuItem>
                ))}
              >
                <div
                  aria-hidden="true"
                  onClick={() => {
                    setIsLanguageVisible(
                      previousIsVisibleState => !previousIsVisibleState,
                    );
                  }}
                >
                  <img src={languageIcon} alt="language" />
                </div>
              </Popover>
            </li>
          )}

          {!signedIn && (
            <span className="navbar__tooltip-text">
              <I18nText id="navbar.navbar_links.language.title" />
            </span>
          )}
        </div>
        <div className="navbar__menu-item navbar__tooltip navbar__right-item navbar__user-area">
          {signedIn ? (
            <li className="navbar__user-settings">
              <Popover
                size="medium"
                isVisible={isProfileMenuVisible}
                onOutsideClick={() => setIsProfileMenuVisible(false)}
                className="navbar__menu-item-locale-wrapper"
                content={
                  <ProfileMenu
                    links={profileMenuLinks}
                    currentUser={props.currentUser}
                    currentUsersAvatar={props.currentUsersAvatar}
                  />
                }
              >
                <div
                  aria-hidden="true"
                  onClick={() => {
                    setIsProfileMenuVisible(
                      previousIsVisibleState => !previousIsVisibleState,
                    );
                  }}
                >
                  <Avatar
                    src={props.currentUsersAvatar}
                    firstName={currentUser.first_name}
                    lastName={currentUser.last_name}
                    size="normal"
                  />
                </div>
              </Popover>
            </li>
          ) : (
            <li className="navbar__sign-options">
              <Registration routes={props.homepageRoutes} />
            </li>
          )}

          {signedIn && (
            <span className="navbar__tooltip-text">
              <I18nText id="navbar.navbar_links.profile.title" />
            </span>
          )}
        </div>
      </ul>
      <SideDrawer
        open={isMenuOpen}
        navItems={navItems}
        sideBarItems={sidebarLinks}
        adminLinks={adminLinks}
        signedIn={signedIn}
        signedInAsAdmin={signedInAsAdmin}
        routes={props.homepageRoutes}
        settingsRoute={props.sidebarRoutes.settings}
      />
    </nav>
  );
};

Navbar.defaultProps = {
  currentUser: PropTypes.shape({
    avatar_uid: null,
  }),
  currentUsersAvatar: null,
};

Navbar.propTypes = {
  homepageRoutes: PropTypes.shape({
    contact: PropTypes.string,
    faqs: PropTypes.string,
    featuresUrl: PropTypes.string,
    signInUrl: PropTypes.string,
    signUpUrl: PropTypes.string,
    signOutUrl: PropTypes.string,
  }).isRequired,
  sidebarRoutes: PropTypes.shape({
    affiliatesThreeSixty: PropTypes.string,
    billingIndex: PropTypes.string,
    dashboard: PropTypes.string,
    settings: PropTypes.string,
    supportTickets: PropTypes.string,
    users: PropTypes.string,
    workspaces: PropTypes.string,
  }).isRequired,
  navbarRoutes: PropTypes.shape({
    publicAboutUs: PropTypes.string,
    publicApi: PropTypes.string,
    publicApiPricing: PropTypes.string,
    publicApplicationPricing: PropTypes.string,
    publicApplications: PropTypes.string,
    publicCareers: PropTypes.string,
    publicDocumentation: PropTypes.string,
    publicFaqs: PropTypes.string,
    publicGlossary: PropTypes.string,
    publicTutorials: PropTypes.string,
    publicLogos: PropTypes.string,
    public: PropTypes.string,
    publicPrivacy: PropTypes.string,
    publicTerms: PropTypes.string,
  }).isRequired,
  availableLocales: PropTypes.arrayOf({
    code: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    humanName: PropTypes.string.isRequired,
  }).isRequired,
  signedInProps: PropTypes.shape({
    signedIn: PropTypes.bool,
    signedInAsAdmin: PropTypes.bool,
  }).isRequired,
  currentUser: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    avatar_uid: PropTypes.string,
    affiliate_id: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
  }),
  currentUsersAvatar: PropTypes.string,
  renderLogo: PropTypes.bool.isRequired,
};

export default Navbar;
