import React from 'react';
import { I18nText, EmphasisTag } from '@wtag/react-comp-lib';

const gettingStarted = [
  {
    id: 'RkssZdDgc9k',
    video: 'https://www.youtube.com/embed/RkssZdDgc9k',
    label: <I18nText id="tutorials.label1" />,
    name: I18n.t('components.tutorials.label1'),
    time: <EmphasisTag type="neutral" size="small" text="11:07" />,
    description: <I18nText id="tutorials.webinar.description_one" />,
  },
  {
    id: 'PYF0VTeReE8',
    video: 'https://www.youtube.com/embed/PYF0VTeReE8',
    label: <I18nText id="tutorials.label23" />,
    name: I18n.t('components.tutorials.label23'),
    time: <EmphasisTag type="neutral" size="small" text="11:29" />,
    description: <I18nText id="tutorials.webinar.description_two" />,
  },
  {
    id: 'e_waCaNW0CE',
    video: 'https://www.youtube.com/embed/e_waCaNW0CE',
    label: <I18nText id="tutorials.label24" />,
    name: I18n.t('components.tutorials.label24'),
    time: <EmphasisTag type="neutral" size="small" text="12:14" />,
    description: <I18nText id="tutorials.webinar.description_three" />,
  },
  {
    id: 'XgFsltE99BM',
    video: 'https://www.youtube.com/embed/XgFsltE99BM',
    label: <I18nText id="tutorials.label25" />,
    name: I18n.t('components.tutorials.label25'),
    time: <EmphasisTag type="neutral" size="small" text="11:32" />,
    description: <I18nText id="tutorials.webinar.description_four" />,
  },
  {
    id: '4r68ORI4eAY',
    video: 'https://www.youtube.com/embed/4r68ORI4eAY',
    label: <I18nText id="tutorials.label26" />,
    name: I18n.t('components.tutorials.label26'),
    time: <EmphasisTag type="neutral" size="small" text="11:16" />,
    description: <I18nText id="tutorials.webinar.description_five" />,
  },
  {
    id: 'meAvQ204vW4',
    video: 'https://www.youtube.com/embed/meAvQ204vW4',
    label: <I18nText id="tutorials.label27" />,
    name: I18n.t('components.tutorials.label27'),
    time: <EmphasisTag type="neutral" size="small" text="11:04" />,
    description: <I18nText id="tutorials.webinar.description_six" />,
  },
  {
    id: 'TGTaT75bQnc',
    video: 'https://www.youtube.com/embed/TGTaT75bQnc',
    label: <I18nText id="tutorials.label28" />,
    name: I18n.t('components.tutorials.label28'),
    time: <EmphasisTag type="neutral" size="small" text="10:08" />,
    description: <I18nText id="tutorials.webinar.description_seven" />,
  },
  {
    id: '20qBakR0UeE',
    video: 'https://www.youtube.com/embed/20qBakR0UeE',
    label: <I18nText id="tutorials.label29" />,
    name: I18n.t('components.tutorials.label29'),
    time: <EmphasisTag type="neutral" size="small" text="9:13" />,
    description: <I18nText id="tutorials.webinar.description_eight" />,
  },
  {
    id: 'Lv8xiqc6uvY',
    video: 'https://www.youtube.com/embed/Lv8xiqc6uvY',
    label: <I18nText id="tutorials.label30" />,
    name: I18n.t('components.tutorials.label30'),
    time: <EmphasisTag type="neutral" size="small" text="11:18" />,
    description: <I18nText id="tutorials.webinar.description_nine" />,
  },
];

const corporateBooking = [
  {
    id: 'zKIJ69DWkd0',
    video: 'https://www.youtube.com/embed/zKIJ69DWkd0',
    label: <I18nText id="tutorials.label2" />,
    name: I18n.t('components.tutorials.label2'),
    time: <EmphasisTag type="neutral" size="small" text="30:36" />,
    description: <I18nText id="tutorials.webinar.description_ten" />,
  },
  {
    id: 'RmFvY-ZjYHw',
    video: 'https://www.youtube.com/embed/RmFvY-ZjYHw',
    label: <I18nText id="tutorials.label3" />,
    name: I18n.t('components.tutorials.label3'),
    time: <EmphasisTag type="neutral" size="small" text="21:50" />,
    description: <I18nText id="tutorials.webinar.description_eleven" />,
  },
  {
    id: 'izqERVEF1cQ',
    video: 'https://www.youtube.com/embed/izqERVEF1cQ',
    label: <I18nText id="tutorials.label4" />,
    name: I18n.t('components.tutorials.label4'),
    time: <EmphasisTag type="neutral" size="small" text="11:15" />,
    description: <I18nText id="tutorials.webinar.description_twelve" />,
  },
  {
    id: 'uwU5MLdJvwc',
    video: 'https://www.youtube.com/embed/uwU5MLdJvwc',
    label: <I18nText id="tutorials.label5" />,
    name: I18n.t('components.tutorials.label5'),
    time: <EmphasisTag type="neutral" size="small" text="29:51" />,
    description: <I18nText id="tutorials.webinar.description_thirteen" />,
  },
];

const onlineBookingEngine = [
  {
    id: 'wEHhYLH6MHE',
    video: 'https://www.youtube.com/embed/wEHhYLH6MHE',
    label: <I18nText id="tutorials.label20" />,
    name: I18n.t('components.tutorials.label20'),
    time: <EmphasisTag type="neutral" size="small" text="22:27" />,
    description: <I18nText id="tutorials.webinar.description_fourteen" />,
  },
  {
    id: 'isWihBiFGXg',
    video: 'https://www.youtube.com/embed/isWihBiFGXg',
    label: <I18nText id="tutorials.label21" />,
    name: I18n.t('components.tutorials.label21'),
    time: <EmphasisTag type="neutral" size="small" text="18:06" />,
    description: <I18nText id="tutorials.webinar.description_fifteen" />,
  },
  {
    id: 'YAQWcx20QX4',
    video: 'https://www.youtube.com/embed/YAQWcx20QX4',
    label: <I18nText id="tutorials.label22" />,
    name: I18n.t('components.tutorials.label22'),
    time: <EmphasisTag type="neutral" size="small" text="11:08" />,
    description: <I18nText id="tutorials.webinar.description_sixteen" />,
  },
];

const orderManagement = [
  {
    id: 'qZCmBP9Rmmk',
    video: 'https://www.youtube.com/embed/qZCmBP9Rmmk',
    label: <I18nText id="tutorials.label17" />,
    name: I18n.t('components.tutorials.label17'),
    time: <EmphasisTag type="neutral" size="small" text="34:26" />,
    description: <I18nText id="tutorials.webinar.description_seventeen" />,
  },
  {
    id: 'M-S-BWCXJCo',
    video: 'https://www.youtube.com/embed/M-S-BWCXJCo',
    label: <I18nText id="tutorials.label18" />,
    name: I18n.t('components.tutorials.label18'),
    time: <EmphasisTag type="neutral" size="small" text="23:45" />,
    description: <I18nText id="tutorials.webinar.description_eighteen" />,
  },
];



export {
  corporateBooking,
  gettingStarted,
  onlineBookingEngine,
  orderManagement,
};

