import React from 'react';
import { I18nText } from '@wtag/react-comp-lib';

import chantell from 'affiliateImages/chantell.jpg';

import quotation from 'affiliateImages/quotation.svg';

const speakers = {
  chantell: {
    name: <I18nText id="homepage.client_section.name" />,
    image: (
      <img src={chantell} alt="Chantell Van den Heever" className="flex-img" />
    ),
    quote: <I18nText id="homepage.client_section.say" />,
    position: <I18nText id="homepage.client_section.position" />,
  },
};

const ClientQuotes = () => {
  return (
    <section className="full-width secondary-bg homepage__clients">
      <div className="container">
        <div className="grid">
          <div className="col-grid offset-xlg-0 offset-lg-0 offset-md-3 offset-3 col-xlg-2 col-lg-2 col-md-6 col-6 align-center homepage__clients-image">
            {speakers.chantell.image}
          </div>
          <div className="col-grid offset-xlg-1 offset-lg-1 offset-md-0 offset-0 col-xlg-9 col-lg-9 col-md-12 col-12">
            <img
              className="homepage__clients-quotation-sign"
              src={quotation}
              alt="quotaion"
            />
            <div className="homepage__clients-quote">
              {speakers.chantell.quote}
            </div>
            <div className="homepage__clients-name">
              {speakers.chantell.name} {speakers.chantell.position}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientQuotes;
