import React from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';

const Registration = ({ routes }) => {
  return (
    <div className="navbar__registration">
      <a
        href={routes.signUpUrl}
        className="button button--v2 button--primary button--small btn--sign-up"
      >
        <I18nText id="nav.top.sign_up" />
      </a>
      <a
        href={routes.signInUrl}
        className="button button--v2 button--small btn--sign-in"
      >
        <I18nText id="nav.top.sign_in" />
      </a>
    </div>
  );
};

Registration.propTypes = {
  routes: PropTypes.shape({
    signInUrl: PropTypes.string,
    signUpUrl: PropTypes.string,
  }).isRequired,
};

export default Registration;
