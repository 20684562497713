import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { animated, useTransition, config } from 'react-spring';
import HeroContent from './HeroContent';
import TabBody from './TabBody';

export default function HeroTab({
  currentTab,
  tabs,
  onChange,
  additionalProps,
  recaptchaKey,
  recaptchaWidgetSrc,
}) {
  const [previousTab, setPreviousTab] = useState(currentTab);
  const pathName = window.location.href.split('/').reverse()[0];
  const unhashedPath = pathName.split('#')[0];
  const tabSelector = tabs.map((tab, index) => (
    <Link to={tab.url} key={tab.tabNum}>
      <button
        className={classNames('hero-selector__tab', {
          'hero-selector__tab--active': currentTab === index,
        })}
        key={tab.tabNum}
        type="submit"
        onClick={() => {
          onChange(index);
        }}
      >
        {tab.title}
      </button>
    </Link>
  ));

  const tabBody = tabs.map((tab, index) => (
    <Route path={tab.url} key={tab.url}>
      <TabBody
        selectedIndex={index}
        tabs={tabs}
        additionalProps={additionalProps}
        recaptchaKey={recaptchaKey}
        recaptchaWidgetSrc={recaptchaWidgetSrc}
      />
    </Route>
  ));

  const getTabNum = () => {
    let tabNum;
    switch (unhashedPath) {
      case 'tutorials':
      case 'careers':
      case 'privacy': {
        tabNum = 1;
        break;
      }
      case 'glossary':
      case 'logos':
      case 'security': {
        tabNum = 2;
        break;
      }
      case 'documentation': {
        tabNum = 3;
        break;
      }
      default: {
        tabNum = 0;
      }
    }
    return tabNum;
  };

  useEffect(() => {
    onChange(getTabNum());
  });

  const trans = useTransition(tabs[currentTab], item => item.tabNum, {
    unique: true,
    from: () => {
      return {
        transform: `translate3d(${(currentTab - previousTab) * 100}%,0,0)`,
        position: 'static',
      };
    },
    enter: {
      transform: 'translate3d(0%,0,0)',
      position: 'static',
    },
    leave: () => ({
      transform: `translate3d(${(previousTab - currentTab) * 100}%,0,0)`,
      position: 'absolute',
    }),
    config: config.molasses,
  });

  if (currentTab !== previousTab) setPreviousTab(currentTab);

  return (
    <div className="hero-container">
      <div className="hero-tab">
        {trans.map(({ item: tab, props, key }, index) => (
          <animated.div
            key={key}
            style={{
              ...props,
              width: '100%',
              height: '100%',
            }}
            onClick={() => onChange(currentTab)}
          >
            <HeroContent
              isSelected={currentTab === index}
              id={index}
              key={tab.tabNum}
              additionalProps={additionalProps}
              recaptchaKey={recaptchaKey}
              recaptchaWidgetSrc={recaptchaWidgetSrc}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...tab}
            />
          </animated.div>
        ))}
      </div>

      <Router>
        <div className="hero-selector">{tabSelector}</div>

        <Switch>{tabBody}</Switch>
      </Router>
    </div>
  );
}

HeroTab.defaultProps = {
  additionalProps: PropTypes.shape({
    routes: null,
    platformFeaturesRoutes: null,
  }),
};

HeroTab.propTypes = {
  currentTab: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabNum: PropTypes.number.isRequired,
      title: PropTypes.node.isRequired,
      slogan: PropTypes.node,
      logo: PropTypes.node.isRequired,
      body: PropTypes.function, // react functional component
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  additionalProps: PropTypes.shape({
    routes: PropTypes.shape({
      features: {
        platform: PropTypes.string,
      },
      pricing: {
        platform: PropTypes.string,
      },
      resources: {
        faqs: PropTypes.string,
        tutorials: PropTypes.string,
        glossary: PropTypes.string,
        documentation: PropTypes.string,
      },
      company: {
        about_us: PropTypes.string,
        careers: PropTypes.string,
        logos: PropTypes.string,
      },
      policies: {
        term: PropTypes.string,
        privacy: PropTypes.string,
        security: PropTypes.string,
      },
    }),
    recaptchaKey: PropTypes.string.isRequired,
    recaptchaWidgetSrc: PropTypes.string.isRequired,
    platformFeaturesRoutes: PropTypes.shape({
      platformFeaturesProfiles: PropTypes.string,
      platformFeaturesSearch: PropTypes.string,
      platformFeaturesBooking: PropTypes.string,
      platformFeaturesMobileApp: PropTypes.string,
      platformFeaturesInvoicing: PropTypes.string,
      platformFeaturesOnlineBookingTool: PropTypes.string,
      platformFeaturesReporting: PropTypes.string,
      platformFeaturesTaskManagement: PropTypes.string,
      platformFeaturesVisaAdvisory: PropTypes.string,
      platformFeaturesCorporateTravel: PropTypes.string,
      platformFeaturesLeisurePackages: PropTypes.string,
      platformFeaturesChat: PropTypes.string,
    }),
  }),
  recaptchaKey: PropTypes.string.isRequired,
  recaptchaWidgetSrc: PropTypes.string.isRequired,
};
