/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import downArrow from '../../../../assets/images/arrow_down-24px.svg';
import topArrow from '../../../../assets/images/arrow_top-24px.svg';
import Registration from '../Registration/Registration';
import sideNavSettings from '../Sidebar/settingsLinks';

const SideDrawer = props => {
  const [openTab, setOpenTab] = useState(null);

  const {
    open,
    navItems,
    sideBarItems,
    adminLinks,
    routes,
    settingsRoute,
  } = props;

  const { signedIn, signedInAsAdmin } = props;

  const toggleSubMenu = label => {
    if (openTab === label) {
      setOpenTab(null);
    } else {
      setOpenTab(label);
    }
  };

  return (
    <div
      className={classnames('side-drawer', {
        open,
      })}
    >
      <div className="side-drawer__menu">
        {signedIn && (
          <ul className="side-drawer__menu-items side-drawer__menu-items--side-menu">
            {sideBarItems.map(({ label, link, subMenu }, index) =>
              !subMenu.length ? (
                <li className="side-drawer__menu-item" key={index}>
                  <a href={link}>{label}</a>
                </li>
              ) : (
                <li
                  className="side-drawer__menu-item side-drawer__menu-item--my-wrokspace"
                  key={index}
                  onClick={() => toggleSubMenu(label)}
                  onKeyDown={() => toggleSubMenu(label)}
                  role="menuitem"
                >
                  <a
                    href={link}
                    className={classnames({
                      'side-drawer__menu-item--link': openTab === label,
                      'side-drawer__menu-item--label': openTab !== label,
                    })}
                  >
                    {label}
                    {openTab === label ? (
                      <img
                        src={topArrow}
                        className="side-drawer__menu-item-arrow--open"
                        alt="Down Arrow"
                      />
                    ) : (
                      <img
                        src={downArrow}
                        className="side-drawer__menu-item-arrow"
                        alt="Down Arrow"
                      />
                    )}
                  </a>

                  <ul
                    className={classnames('side-drawer__sub-menu-items', {
                      'side-drawer__sub-menu-items--open': openTab === label,
                    })}
                  >
                    {subMenu.map(({ label }, index) => (
                      <li key={index} className="side-drawer__sub-menu-item">
                        {label}
                      </li>
                    ))}
                  </ul>
                </li>
              ),
            )}
            {signedInAsAdmin &&
              adminLinks.map(({ menuItem, url }, index) => (
                <li className="side-drawer__menu-item" key={index}>
                  <a href={url}>{menuItem}</a>
                </li>
              ))}
            {sideNavSettings.map(({ name }) => (
              <li className="side-drawer__menu-item" key={name}>
                <a href={settingsRoute}>{name}</a>
              </li>
            ))}
          </ul>
        )}

        <ul className="side-drawer__menu-items">
          {navItems.map(({ label, link, subMenu }, index) => (
            <li
              className="side-drawer__menu-item"
              key={index}
              onClick={() => toggleSubMenu(label)}
              onKeyDown={() => toggleSubMenu(label)}
              role="menuitem"
            >
              <a
                href={link}
                className={
                  openTab === label
                    ? 'side-drawer__menu-item--link'
                    : 'side-drawer__menu-item--label'
                }
              >
                {label}
                {subMenu &&
                  (openTab === label ? (
                    <img
                      src={topArrow}
                      className="side-drawer__menu-item-arrow--open"
                      alt="Down Arrow"
                    />
                  ) : (
                    <img
                      src={downArrow}
                      className="side-drawer__menu-item-arrow"
                      alt="Down Arrow"
                    />
                  ))}
              </a>
              {subMenu && (
                <ul
                  className={classnames('side-drawer__sub-menu-items', {
                    'side-drawer__sub-menu-items--open': openTab === label,
                  })}
                >
                  {subMenu.map(({ label, link }, index) => (
                    <li key={index} className="side-drawer__sub-menu-item">
                      <a href={link}>{label}</a>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
      {!signedIn && (
        <div className="side-drawer__sign-options">
          <Registration routes={routes} />
        </div>
      )}
    </div>
  );
};

export default SideDrawer;

SideDrawer.defaultProps = {
  signedInAsAdmin: null,
};

SideDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.shape().isRequired,
      link: PropTypes.string.isRequired,
      subMenu: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.shape().isRequired,
          link: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  sideBarItems: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      menuItem: PropTypes.shape().isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  adminLinks: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      menuItem: PropTypes.shape().isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  signedIn: PropTypes.bool.isRequired,
  signedInAsAdmin: PropTypes.bool,
  routes: PropTypes.shape().isRequired,
  settingsRoute: PropTypes.string.isRequired,
};
