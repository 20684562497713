import React from 'react';
import { I18nText } from '@wtag/react-comp-lib';
import ceoHalfBody from 'affiliateImages/ceo-half-body.png';
import ceo from 'affiliateImages/ceo.png';
import cto from 'affiliateImages/cto.png';
import cpo from 'affiliateImages/cpo.png';
import cqso from 'affiliateImages/cqso.png';
import cfo from 'affiliateImages/cfo.png';
import headOfApplication from 'affiliateImages/HeadOfApplication.png';
import headOfFrontend from 'affiliateImages/HeadOfFrontend.png';
import headOfDesign from 'affiliateImages/HeadOfDesign.png';
import headOfMobileApps from 'affiliateImages/HeadOfMobileApps.png';
import headOfSupplier from 'affiliateImages/HeadOfSupplier.png';
import headOfQualityAssurance from 'affiliateImages/HeadOfQualityAssurance.png';
import teamWork from 'affiliateImages/teamwork.jpg';
import travelWithPlane from 'affiliateImages/travelWithPlane.jpg';
import flyingPlane from 'affiliateImages/flyingPlane.png';
import quotation from 'affiliateImages/quotation.svg';
import Timeline from '../Timeline';

const data = [
  {
    id: 1,
    time: <I18nText id="about_us.timeline.time_9" />,
    details: <I18nText id="about_us.timeline.detail_9" />,
  },
  {
    id: 2,
    time: <I18nText id="about_us.timeline.time_8" />,
    details: (
      <>
        <I18nText id="about_us.timeline.detail_8.sub_detail_1" />
        <I18nText id="about_us.timeline.detail_8.sub_detail_2" />
      </>
    ),
  },
  {
    id: 3,
    time: <I18nText id="about_us.timeline.time_7" />,
    details: <I18nText id="about_us.timeline.detail_7" />,
  },
  {
    id: 4,
    time: <I18nText id="about_us.timeline.time_6" />,
    details: (
      <>
        <I18nText id="about_us.timeline.detail_6.sub_detail_1" />
        <I18nText id="about_us.timeline.detail_6.sub_detail_2" />
      </>
    ),
  },
  {
    id: 5,
    time: <I18nText id="about_us.timeline.time_5" />,
    details: <I18nText id="about_us.timeline.detail_5" />,
  },
  {
    id: 6,
    time: <I18nText id="about_us.timeline.time_4" />,
    details: (
      <>
        <I18nText id="about_us.timeline.detail_4.sub_detail_1" />
        <I18nText id="about_us.timeline.detail_4.sub_detail_2" />
      </>
    ),
  },
  {
    id: 7,
    time: <I18nText id="about_us.timeline.time_3" />,
    details: (
      <>
        <I18nText id="about_us.timeline.detail_3.sub_detail_1" />
        <I18nText id="about_us.timeline.detail_3.sub_detail_2" />
        <I18nText id="about_us.timeline.detail_3.sub_detail_3" />
        <I18nText id="about_us.timeline.detail_3.sub_detail_4" />
      </>
    ),
  },
  {
    id: 8,
    time: <I18nText id="about_us.timeline.time_2" />,
    details: <I18nText id="about_us.timeline.detail_2" />,
  },
  {
    id: 9,
    time: <I18nText id="about_us.timeline.time_1" />,
    details: <I18nText id="about_us.timeline.detail_1" />,
  },
];

const speakers = {
  ceo: {
    name: 'Philipp Wellstein',
    image: <img src={ceo} alt="CEO" className="flex-img" />,
    quote: <I18nText id="about_us.ceo_quote" />,
  },
};

const executiveLeadershipTeamMembers = [
  {
    name: 'Philipp Wellstein',
    image: <img src={ceoHalfBody} alt="CEO" className="flex-img" />,
    designation: 'CEO',
  },
  {
    name: 'Mujadded Al Rabbani Alif',
    image: <img src={cto} alt="CTO" className="flex-img" />,
    designation: 'CTO',
  },
  {
    name: 'Simon Benz',
    image: <img src={cpo} alt="CPO" className="flex-img" />,
    designation: 'CPO',
  },
  {
    name: 'Raffael Schmid',
    image: <img src={cqso} alt="CQSO" className="flex-img" />,
    designation: 'CQSO',
  },
  {
    name: 'Andrew Adhikary',
    image: <img src={cfo} alt="CFO" className="flex-img" />,
    designation: 'CFO',
  },
];

const leadershipTeamMembers = [
  {
    name: 'Laila Raha',
    image: (
      <img
        src={headOfApplication}
        alt="Head of Applications"
        className="flex-img"
      />
    ),
    designation: 'Head of Applications',
  },
  {
    name: 'Noel Mannan',
    image: (
      <img src={headOfFrontend} alt="Head of Frontend" className="flex-img" />
    ),
    designation: 'Head of Frontend',
  },
  {
    name: 'Redwan Rahat',
    image: <img src={headOfDesign} alt="Head of Design" className="flex-img" />,
    designation: 'Head of Design',
  },
  {
    name: 'Kazi Faisal Arefin',
    image: (
      <img
        src={headOfMobileApps}
        alt="Head of Mobile Apps"
        className="flex-img"
      />
    ),
    designation: 'Head of Mobile Apps',
  },
  {
    name: 'Mostafizur Rahman',
    image: (
      <img
        src={headOfSupplier}
        alt="Head of Supplier Integration"
        className="flex-img"
      />
    ),
    designation: 'Head of Supplier Integration',
  },
  {
    name: 'Jacob Arinda',
    image: (
      <img
        src={headOfQualityAssurance}
        alt="Head of Quality Assurance"
        className="flex-img"
      />
    ),
    designation: 'Head of Quality Assurance',
  },
];

const AboutUs = () => {
  return (
    <div className="about-us">
      <div className="container-full">
        <section className="full-width about-us__mission">
          <div className="grid">
            <div className="col-grid offset-1 col-xlg-4 col-lg-4 col-md-4 col-10 justify-center">
              <div className="about-us__mission-header">
                <I18nText id="about_us.mission.header" />
              </div>
              <div className="about-us__mission-sub-header">
                <I18nText id="about_us.mission.sub_header" />
              </div>
            </div>
            <div className="col-grid offset-1 col-xlg-5 col-lg-5 col-md-5 col-10 justify-center">
              <div className="about-us__mission-details">
                <I18nText id="about_us.mission.details" />
              </div>
            </div>
          </div>
        </section>
        <section className="full-width primary-bg about-us__timeline">
          <img
            src={travelWithPlane}
            alt="Team work"
            className="img-responsive careers__remote-work-section-cover"
          />
          <div className="secondary-overlay" />
          <div className="grid">
            <div className="col-grid offset-1 col-xlg-4 col-lg-4 col-md-4 col-10">
              <div className="about-us__timeline-header">
                <I18nText id="about_us.timeline.header" />
              </div>
              <div className="about-us__timeline-sub-header">
                <I18nText id="about_us.timeline.sub_header" />
              </div>
              <img
                src={flyingPlane}
                alt="Fly with plane"
                className="img-responsive justify-end"
              />
            </div>
            <div className="col-grid offset-1 col-xlg-5 col-lg-5 col-md-5 col-10">
              <Timeline data={data} />
            </div>
          </div>
        </section>
        <section className="full-width primary-bg about-us__quote">
          <div className="grid">
            <div className="col-grid offset-xlg-1 offset-lg-1 offset-md-1 offset-3 col-xlg-2 col-lg-2 col-md-3 col-6 align-center about-us__quote-image">
              {speakers.ceo.image}
            </div>
            <div className="col-grid offset-1 col-xlg-7 col-lg-7 col-md-6 col-10">
              <img
                className="about-us__quote-quotation-sign"
                src={quotation}
                alt="quotaion"
              />
              <div className="about-us__quote-detail">{speakers.ceo.quote}</div>
              <div className="about-us__quote-speaker-name">
                {speakers.ceo.name} <br /> CEO
              </div>
            </div>
          </div>
        </section>
        <section className="full-width primary-bg about-us__principles">
          <img
            src={teamWork}
            alt="Team work"
            className="img-responsive careers__remote-work-section-cover"
          />
          <div className="secondary-overlay" />
          <div className="grid">
            <div className="col-grid offset-1 col-xlg-4 col-lg-4 col-md-4 col-10">
              <div className="about-us__principles-header">
                <I18nText id="about_us.principles.header" />
              </div>
              <div className="about-us__principles-details">
                <I18nText id="about_us.principles.sub_header" />
              </div>
            </div>
            <div className="col-grid offset-1 col-xlg-5 col-lg-5 col-md-5 col-10">
              <div className="about-us__principles-principle">
                <div className="about-us__principles-principle-title">
                  <I18nText id="about_us.principles.principle.leadership.title" />
                </div>
                <div className="about-us__principles-principle-description">
                  <I18nText id="about_us.principles.principle.leadership.description" />
                </div>
              </div>
              <div className="about-us__principles-principle">
                <div className="about-us__principles-principle-title">
                  <I18nText id="about_us.principles.principle.innovation.title" />
                </div>
                <div className="about-us__principles-principle-description">
                  <I18nText id="about_us.principles.principle.innovation.description" />
                </div>
              </div>
              <div className="about-us__principles-principle">
                <div className="about-us__principles-principle-title">
                  <I18nText id="about_us.principles.principle.service.title" />
                </div>
                <div className="about-us__principles-principle-description">
                  <I18nText id="about_us.principles.principle.service.description" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="full-width">
          <div className="col-grid align-center">
            <div className="col-xlg-10 col-lg-10 col-md-10 col-12">
              <div className="about-us__team-header">
                <I18nText id="about_us.executive_team.header" />
              </div>
            </div>
            <div className="col-xlg-10 col-lg-10 col-md-10 col-12">
              <div className="grid justify-center">
                {executiveLeadershipTeamMembers.map(member => (
                  <div className="col-grid col-xlg-2 col-lg-3 col-md-6 col-12 about-us__team-member">
                    <div className="about-us__team-member-image">
                      {member.image}
                    </div>
                    <div className="about-us__team-member-name">
                      {member.name}
                    </div>
                    <div className="about-us__team-member-designation">
                      {member.designation}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className="full-width tertiary-light-bg">
          <div className="col-grid align-center">
            <div className="col-xlg-10 col-lg-10 col-md-10 col-12">
              <div className="about-us__team-header">
                <I18nText id="about_us.leadership_team.header" />
              </div>
              <div className="about-us__team-sub-header">
                <I18nText id="about_us.leadership_team.sub_header" />
              </div>
            </div>
            <div className="col-xlg-10 col-lg-10 col-md-10 col-12">
              <div className="grid justify-center">
                {leadershipTeamMembers.map(member => (
                  <div className="col-grid col-xlg-3 col-lg-4 col-md-6 col-12 about-us__team-member">
                    <div className="about-us__team-member-image">
                      {member.image}
                    </div>
                    <div className="about-us__team-member-name">
                      {member.name}
                    </div>
                    <div className="about-us__team-member-designation">
                      {member.designation}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className="full-width about-us__query">
          <div className="grid">
            <div className="col-grid offset-1 col-xlg-4 col-lg-4 col-md-4 col-10 justify-center">
              <div className="about-us__query-header">
                <I18nText id="about_us.query.header" />
              </div>
            </div>
            <div className="col-grid offset-1 col-xlg-5 col-lg-5 col-md-5 col-10 justify-center">
              <div className="about-us__query-details">
                <I18nText id="about_us.query.sub_header" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;
