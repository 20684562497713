import React from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';
import chat from 'affiliateImages/chat-bubble.svg';
import welltravelDark from 'affiliateImages/welltravel_logo_dark.png';

const Sitemap = props => {
  return (
    <div className="sitemap">
      <div className="container">
        <div className="sitemap__logo">
          <img
            src={welltravelDark}
            alt="welltravel-logo"
            className="img-responsive"
          />
        </div>
        <div className="grid">
          <div className="col-xlg-2 col-lg-2 col-md-4 col-sm-4 col-xs-6 col-xss-12 sitemap__platform">
            <p>
              <I18nText id="homepage.features.header.title" />
            </p>
            <a href={props.sitemapRoutes.publicApplications}>
              <I18nText
                id="features.platform.profiles"
                className="platform-features__profiles-tab"
              />
            </a>
            <a href={props.sitemapRoutes.publicApplications}>
              <I18nText
                id="features.platform.search"
                className="platform-features__search-tab"
              />
            </a>
            <a href={props.sitemapRoutes.publicApplications}>
              <I18nText
                id="features.platform.booking"
                className="platform-features__booking-tab"
              />
            </a>
            <a href={props.sitemapRoutes.publicApplications}>
              <I18nText
                id="features.platform.mobile_app"
                className="platform-features__mobile-tab"
              />
            </a>
            <a href={props.sitemapRoutes.publicApplications}>
              <I18nText
                id="features.platform.invoice"
                className="platform-features__invoice-tab"
              />
            </a>
            <a href={props.sitemapRoutes.publicApplications}>
              <I18nText
                id="features.platform.obt"
                className="platform-features__obt-tab"
              />
            </a>
            <a href={props.sitemapRoutes.publicApplications}>
              <I18nText
                id="features.platform.report"
                className="platform-features__report-tab"
              />
            </a>
            <a href={props.sitemapRoutes.publicApplications}>
              <I18nText
                id="features.platform.task"
                className="platform-features__task-tab"
              />
            </a>
            <a href={props.sitemapRoutes.publicApplications}>
              <I18nText
                id="features.platform.visa"
                className="platform-features__visa-tab"
              />
            </a>
            <a href={props.sitemapRoutes.publicApplications}>
              <I18nText
                id="features.platform.corporate_travel"
                className="platform-features__corporate-tab"
              />
            </a>
            <a href={props.sitemapRoutes.publicApplications}>
              <I18nText
                id="features.platform.leisure_packages"
                className="platform-features__leisure-tab"
              />
            </a>
            <a href={props.sitemapRoutes.publicApplications}>
              <I18nText
                id="features.platform.chat"
                className="platform-features__chat-tab"
              />
            </a>
          </div>
          <div className="col-xlg-2 col-lg-2 col-md-4 col-sm-4 col-xs-6 col-xss-12  sitemap__features">
            <p>
              <I18nText id="nav.top.link.features" />
            </p>
            <a href={props.sitemapRoutes.publicApplications}>
              <I18nText id="nav.top.link.platform_features" />
            </a>
            <a href="/">
              <I18nText id="nav.top.link.api_features" />
            </a>
          </div>
          <div className="col-xlg-2 col-lg-2 col-md-4 col-sm-4 col-xs-6 col-xss-12  sitemap__pricing">
            <p>
              <I18nText id="nav.top.link.pricing" />
            </p>
            <a href={props.sitemapRoutes.publicApplicationPricing}>
              <I18nText id="nav.top.link.platform_features" />
            </a>
            <a href="/">
              <I18nText id="nav.top.link.api_features" />
            </a>
          </div>
          <div className="col-xlg-2 col-lg-2 col-md-4 col-sm-4 col-xs-6 col-xss-12  sitemap__resources">
            <p>
              <I18nText id="nav.top.link.resources" />
            </p>
            <a href={props.sitemapRoutes.publicFaqs}>
              <I18nText id="nav.top.link.faqs" />
            </a>
            <a href={props.sitemapRoutes.publicTutorials}>
              <I18nText id="nav.top.link.tutorials" />
            </a>
            <a href={props.sitemapRoutes.publicGlossary}>
              <I18nText id="nav.top.link.glossary" />
            </a>
            <a href={props.sitemapRoutes.publicDocumentation}>
              <I18nText id="nav.top.link.documentation" />
            </a>
          </div>
          <div className="col-xlg-2 col-lg-2 col-md-4 col-sm-4 col-xs-6 col-xss-12  sitemap__company">
            <p>
              <I18nText id="nav.top.link.company" />
            </p>
            <a href={props.sitemapRoutes.publicAboutUs}>
              <I18nText id="nav.top.link.about_us" />
            </a>
            <a href={props.sitemapRoutes.publicCareers}>
              <I18nText id="nav.top.link.careers" />
            </a>
            <a href={props.sitemapRoutes.publicLogos}>
              <I18nText id="nav.top.link.media" />
            </a>
          </div>
          <div className="col-xlg-2 col-lg-2 col-md-4 col-sm-4 col-xs-6 col-xss-12  sitemap__policy">
            <p>
              <I18nText id="nav.top.link.policy" />
            </p>
            <a href={props.sitemapRoutes.term}>
              <I18nText id="footer.footer_links.primary.title1" />
            </a>
            <a href={props.sitemapRoutes.privacy}>
              <I18nText id="footer.footer_links.primary.title2" />
            </a>
            <a href={props.sitemapRoutes.security}>
              <I18nText id="footer.footer_links.primary.title3" />
            </a>
          </div>
        </div>
      </div>
      <div className="about-us__contact-section">
        <span>
          <img src={chat} alt="contact" />
        </span>
        <span>
          <div className="about-us__contact-header">
            <I18nText id="about_us.marketingCard.contact.header" />
          </div>
          <div className="about-us__contact-mail">
            <a href="mailto:contact@welltravel.com">
              <I18nText id="about_us.marketingCard.contact.mail" />
            </a>
            <div>
              <I18nText id="about_us.marketingCard.contact.address" />
            </div>
          </div>
        </span>
      </div>
    </div>
  );
};

Sitemap.propTypes = {
  sitemapRoutes: PropTypes.shape({
    publicApplications: PropTypes.string.isRequired,
    publicApplicationPricing: PropTypes.string.isRequired,
    publicFaqs: PropTypes.string.isRequired,
    publicTutorials: PropTypes.string.isRequired,
    publicGlossary: PropTypes.string.isRequired,
    publicDocumentation: PropTypes.string.isRequired,
    publicAboutUs: PropTypes.string.isRequired,
    publicCareers: PropTypes.string.isRequired,
    publicLogos: PropTypes.string.isRequired,
    term: PropTypes.string.isRequired,
    privacy: PropTypes.string.isRequired,
    security: PropTypes.string.isRequired,
  }).isRequired,
};

export default Sitemap;
