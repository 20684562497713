import React from 'react';
import PropTypes from 'prop-types';

const TermsBody = ({ className }) => {
  return (
    <div className={`container-full policy ${className}`}>
      <div className="grid justify-center">
        <div className="col-xlg-10 col-lg-10 col-md-10 col-12">
          <div className="policy__text">
            <h1>Welltravel Terms of Service</h1>
            <p>Date of Last Revision: June 1st, 2020</p>
            <p>
              This page outlines the Terms of Service under which we provide the
              use of Welltravel service (hereinafter, the &quot;Service&quot;).
              By using the Service, you are indicating your acceptance to be
              bound by these Terms of Service. If you are unwilling to be bound
              these Terms of Service, please do not use Welltravel’ service. By
              entering into these Terms of Service, you further acknowledge your
              understanding and acceptance of our Privacy Policy. Please note
              that these Terms of Service are subject to change. Welltravel, in
              its sole discretion, may revise the Terms of Service at any time
              by updating this page. You should visit this page periodically to
              review the Terms of Service, as it is binding upon you.
            </p>
            <div>
              <h3>Account Terms</h3>
              <ul>
                <li>You must be 18 years or older to use this Service.</li>
                <li>
                  You must be a human. Accounts registered by “bots” or other
                  automated methods are not permitted.
                </li>
                <li>
                  You must provide your legal full name, a valid email address,
                  and any other information requested in order to complete the
                  signup process.
                </li>
                <li>
                  Your login may only be used by one person - a single login
                  shared by multiple people is not permitted. You may create
                  separate logins for as many people as you would like.
                </li>
                <li>
                  You are responsible for maintaining the security of your
                  account and password. Welltravel cannot and will not be liable
                  for any loss or damage from your failure to comply with this
                  security obligation.
                </li>
                <li>
                  You are responsible for all Content posted and activity that
                  occurs under your account (even when Content is posted by
                  others who have sub-accounts under your account).
                </li>
                <li>
                  You may not use the Service for any illegal or unauthorized
                  purpose. You must not, in the use of the Service, violate any
                  laws in your jurisdiction (including but not limited to
                  copyright laws). You agree that if you engage in illegal or
                  unauthorized use of the Service, you will be solely
                  responsible for your illegal or unauthorized use, and will
                  indemnify Welltravel from any claims arising out of your
                  illegal or unauthorized use.
                </li>
              </ul>
              <h3>PAYMENT, REFUNDS, UPGRADING AND DOWNGRADING TERMS</h3>
              <ul>
                <li>A valid credit card is required for any account.</li>
                <li>
                  If you initially sign up for an account and do not cancel that
                  account within 30 days, you will be billed monthly starting on
                  the 30th day after your account was initially created. If you
                  cancel prior to the processing of your first invoice on the
                  30th day, you will not be charged.
                </li>
                <li>
                  An upgrade from the free trial plan to any paying plan will
                  end your free trial. You will be billed for your first month
                  immediately upon upgrading.
                </li>
                <li>
                  The Service is billed in advance on a monthly basis and is
                  non-refundable. There will be no refunds or credits for
                  partial months of service, upgrade/downgrade refunds, or
                  refunds for unused months of the Service. In order to treat
                  everyone equally, no exceptions will be made.
                </li>
                <li>
                  All fees are exclusive of all taxes, levies, or duties imposed
                  by taxing authorities, and you shall be responsible for
                  payment of all such taxes, levies, or duties, excluding only
                  Swiss (federal or state) taxes.
                </li>
                <li>
                  For any upgrade or downgrade in plan level, the credit card
                  you provided will automatically be charged the new rate on
                  your next billing cycle.
                </li>
                <li>
                  Downgrading your Service may cause the loss of Content,
                  features, or capacity of your Account. Welltravel does not
                  accept any liability for such loss.
                </li>
              </ul>
              <h3>Cancellation and Termination</h3>
              <ul>
                <li>
                  You are solely responsible for properly cancelling your
                  account. An email or phone request to cancel your account is
                  not considered cancellation. You can cancel your account at
                  any time by clicking on the billing link upon login. The
                  Account screen provides a simple no questions asked
                  cancellation link.
                </li>
                <li>
                  All of your Content will be immediately deactivated from the
                  Service upon cancellation.
                </li>
                <li>
                  If you cancel the Service before the end of the month that you
                  currently paid for, your cancellation will take effect
                  immediately and you will not be charged again.
                </li>
                <li>
                  Welltravel, in its sole discretion, has the right to suspend
                  or terminate your account and refuse any and all current or
                  future use of the Service, or any other Welltravel service,
                  for any reason at any time. Such termination of the Service
                  will result in the deactivation or deletion of your Account or
                  your access to your Account, and the forfeiture and
                  relinquishment of all Content in your Account. Welltravel
                  reserves the right to refuse service to anyone, for any
                  reason, at any time.
                </li>
                <h3>Modifications to the Service and Prices</h3>
                <ul>
                  <li>
                    Welltravel reserves the right to modify or discontinue,
                    temporarily or permanently, the Service (or any part
                    thereof), with or without notice.
                  </li>
                  <li>
                    Prices for all Services, including but not limited to
                    monthly subscription fees to the Service, are subject to
                    change upon 30 days notice from us. Such notice may be
                    provided at any time by posting the changes to the
                    Welltravel website (  welltravel.com ) or the Service
                    itself.
                  </li>
                  <li>
                    Welltravel shall not be liable to you or to any third party
                    for any modification, price change, suspension or
                    discontinuance of the Service.
                  </li>
                </ul>

                <h3>Security Rules</h3>

                <ul>
                  <li>
                    Users are prohibited from violating or attempting to violate
                    the security of the Service, including, without limitation:
                    accessing data not intended for such user or logging into a
                    server or account which the user is not authorized to
                    access; attempting to probe, scan or test the vulnerability
                    of a system or network or to breach security or
                    authentication measures without proper authorization;
                    attempting to interfere with any user’s service, host or
                    network, including, without limitation, via means of
                    submitting a virus, overloading, &quot;flooding&quot;,
                    &quot;mail bombing&quot; or &quot;crashing&quot;; sending
                    unsolicited e-mail, including promotions and/or advertising
                    of products or services; forging any TCP/IP packet header or
                    any part of the header information in any e-mail or
                    newsgroup posting.
                  </li>
                  <li>
                    Use of any device, software or routine to interfere or
                    attempt to interfere with the proper working of the Service
                    or any activity being conducted on the Service, or taking
                    any action which imposes an unreasonable or
                    disproportionately large load on the Service’s
                    infrastructure, is expressly prohibited.
                  </li>
                  <li>
                    Violations of these Security Rules may result in civil or
                    criminal liability. We will investigate occurrences that may
                    involve such violations and may involve, and cooperate with,
                    law enforcement authorities in prosecuting users who are
                    involved in such violations.
                  </li>
                </ul>

                <h3>Intellectual property rights and content ownership</h3>

                <ul>
                  <li>
                    The Service’s designs, graphics and logos, and certain other
                    names or logos are copyrights, service marks, or trademarks
                    of Welltravel. In addition, the &quot;look&quot; and
                    &quot;feel&quot; of the Service (including color
                    combinations, button shapes, layout, design and all other
                    graphical elements) are also protected trademarks, service
                    marks and copyrights. All other product and service marks
                    are the trademarks of their respective owners.
                  </li>
                  <li>
                    We claim no intellectual property rights over the material
                    you provide to the Service. Your profile and the materials
                    you upload remain yours. However, in sharing your Content,
                    you agree to allow others to view and share your Content.
                  </li>
                  <li>
                    Welltravel does not pre-screen Content, but Welltravel has
                    the right (but not the obligation), in its sole discretion,
                    to refuse or remove any Content that is available via the
                    Service.
                  </li>
                  <li>
                    You agree to be bound by third party licenses of software or
                    content that is available or part of the Site and to abide
                    by the terms of use of any such third party software or
                    content.
                  </li>
                </ul>

                <h3>General Conditions</h3>

                <ul>
                  <li>
                    Your use of the Service is at your sole risk. The service is
                    provided on an “as is” and “as available” basis.
                  </li>
                  <li>
                    Technical support is only provided to paying account holders
                    and is only available via support center on welltravel.com
                    (we try to respond within 24/48 hours).
                  </li>
                  <li>
                    You must not modify, adapt or hack the Service or modify
                    another website so as to falsely imply that it is associated
                    with the Service, Welltravel, or any other Welltravel
                    service.
                  </li>
                  <li>
                    You agree not to reproduce, duplicate, copy, sell, resell or
                    exploit any portion of the Service, use of the Service, or
                    access to the Service without the express written permission
                    by Welltravel.
                  </li>
                  <li>
                    We may, but have no obligation to, remove Content and
                    Accounts containing Content that we determine in our sole
                    discretion is unlawful, offensive, threatening, libellous,
                    defamatory, pornographic, obscene or otherwise
                    objectionable, or violates any party’s intellectual property
                    or these Terms of Service.
                  </li>
                  <li>
                    Verbal, physical, written or other abuse (including threats
                    of abuse or retribution) of any Welltravel customer,
                    employee, member, or officer will result in immediate
                    account termination.
                  </li>
                  <li>
                    You understand that the technical processing and
                    transmission of the Service, including your Content, may be
                    transferred unencrypted and involve (a) transmissions over
                    various networks; and (b) changes to conform and adapt to
                    technical requirements of connecting networks or devices.
                  </li>
                  <li>
                    You must not upload, post, host, or transmit unsolicited
                    email, SMSs, or “spam” messages.
                  </li>
                  <li>
                    You must not transmit any worms or viruses or any code of a
                    destructive nature.
                  </li>
                  <li>
                    Welltravel does not warrant that (i) the service will meet
                    your specific requirements, (ii) the service will be
                    uninterrupted, timely, secure, or error-free, (iii) the
                    results that may be obtained from the use of the service
                    will be accurate or reliable, (iv) the quality of any
                    products, services, information, or other material purchased
                    or obtained by you through the service will meet your
                    expectations, and (v) any errors in the Service will be
                    corrected.
                  </li>
                  <li>
                    You expressly understand and agree that Welltravel shall not
                    be liable for any direct, indirect, incidental, special,
                    consequential or exemplary damages, including but not
                    limited to, damages for loss of profits, goodwill, use, data
                    or other intangible losses (even if Welltravel has been
                    advised of the possibility of such damages), resulting from:
                    (i) the use or the inability to use the service; (ii) the
                    cost of procurement of substitute goods and services
                    resulting from any goods, data, information or services
                    purchased or obtained or messages received or transactions
                    entered into through or from the service; (iii) unauthorized
                    access to or alteration of your transmissions or data; (iv)
                    statements or conduct of any third party on the service; (v)
                    or any other matter relating to the service.
                  </li>
                  <li>
                    The failure of Welltravel to exercise or enforce any right
                    or provision of the Terms of Service shall not constitute a
                    waiver of such right or provision. The Terms of Service
                    constitutes the entire agreement between you and Welltravel
                    and governs your use of the Service, superseding any prior
                    agreements between you and Welltravel (including, but not
                    limited to, any prior versions of the Terms of Service).
                  </li>
                  <li>
                    Partial Validity: If any provision of these Terms of Service
                    is found to be invalid by any court having competent
                    jurisdiction, the invalidity of such provision shall not
                    affect the validity of the remaining provisions of the Terms
                    of Service, which shall remain in full force and effect. No
                    waiver of any term of the Terms of Service shall be deemed a
                    further or continuing waiver of such term or any other term.
                  </li>
                  <li>
                    Arbitration: You agree that any dispute related to these
                    Terms of Service, your use of the Services, or any dispute
                    related to your relationship to Welltravel or our
                    affiliates, directors, officers, agents, shareholders,
                    members, subsidiaries, parents, shall be submitted to
                    binding arbitration under Swiss Law.
                  </li>
                  <li>
                    Equitable Relief: You understand and agree that due to the
                    nature of these Terms of Service, in addition to money
                    damages, we will be entitled to equitable relief upon a
                    breach of the Terms of Service by you.
                  </li>
                  <li>
                    Governing Law: These Terms of Service are governed by the
                    laws of the State of Switzerland without respect to its
                    conflict of laws principles. Jurisdiction for any claims
                    arising under these Terms of Service shall be exclusively
                    brought by arbitration in Zurich 8001, Switzerland.
                  </li>
                  <li>
                    Entire Agreement: Except as expressly provided in other
                    notices or Content published on the Welltravel Website,
                    these terms constitute Welltravel the entire binding Terms
                    of Service, and supersede any and all prior understanding,
                    statements or representations, whether electronic, oral or
                    written, regarding Welltravel Services.
                  </li>
                  <li>
                    Digital Admissibility: You hereby agree that a printed
                    version of these Terms of Service shall be admissible in
                    judicial or administrative proceedings and is subject to the
                    same conditions as other agreements, business documents or
                    records originally generated, entered into, signed or
                    maintained in printed form.
                  </li>
                  <li>
                    Transferability: The Services and its users can at any time
                    be transferred to any other company (e.g. from Welltravel
                    International LTD to Welltravel South Africa LTD)
                  </li>
                  <li>
                    Cost occurred at third parties: Welltravel reserves the
                    right to forward all costing to the client that has occurred
                    at third parties (e.g. Transaction charges caused by the
                    client at a Reservation System that are billed towards
                    Welltravel). These charges will be in particular forwarded
                    and billed towards the client when they can be directly
                    attributed to a specific Account/PCC of the client.
                  </li>
                </ul>
              </ul>

              <div>
                By using the Service you agree to defend, indemnify and hold
                harmless us from and against any claims, actions or demands,
                including without limitation reasonable legal and accounting
                fees, alleging or resulting from your breach of these Terms and
                Conditions, or your use of the Site and Service for unauthorized
                or illegal purposes. DISCLAIMERS AND Warranties The information
                contained in Welltravel website and Service is offered as-is and
                without warranty of any kind. Welltravel make no claims,
                promises or guarantees about the accuracy, completeness, or
                adequacy of the information contained in or linked to or from
                its Service, this website, and its associated sites.
              </div>
            </div>
            <br />
            <p>
              Nothing in the website or Service shall be considered an
              endorsement, representation, assumption of responsibility or
              warranty with respect to any third party, whether in regards to
              their web site, products, technologies, services, business
              practices or otherwise.
              <br />
              <br />
              WELLTRAVEL DOES NOT WARRANT THAT THIS SITE OR SERVICE WILL OPERATE
              ERROR-FREE OR THAT THE SITE OR ITS SERVICES ARE FREE OF COMPUTER
              VIRUSES OR OTHER HARMFUL MECHANISMS. IF YOUR USE OF THE SITE OR
              THE SERVICE RESULTS IN THE NEED FOR SERVICING OR REPLACING
              EQUIPMENT OR DATA, WELLTRAVEL IS NOT RESPONSIBLE FOR THOSE COSTS.
              WELLTRAVEL DOES NOT ACCEPT ANY RESPONSIBILY OR ACCOUNTABILITY IN
              ANY FORM FOR ANY LOST OR NOT OCCURRED BUSINESS OR ANY LOSS. YOU
              ASSUME FULL RESPONSIBILITY AND RISK FOR USE OF WELLTRAVEL SITE,
              SERVICE, AND THE INTERNET IN GENERAL. THE SITE AND SERVICE ARE
              PROVIDED ON AN &quot;AS IS&quot; BASIS WITHOUT ANY WARRANTIES OF
              ANY KIND. WELLTRAVEL, TO THE FULLEST EXTENT PERMITTED BY LAW,
              DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING
              THE WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE
              AND NON-INFRINGEMENT. WELLTRAVEL MAKES NO WARRANTIES ABOUT THE
              ACCURACY, RELIABILITY, COMPLETENESS, OR TIMELINESS OF THE CONTENT,
              SERVICES, SOFTWARE, TEXT, GRAPHICS AND LINKS.
            </p>
            <h3>Questions</h3>

            <p>
              Questions about these Terms of Service should be addressed to{' '}
              <a href="mailto:legal@welltravel.com">legal@welltravel.com</a> or
              by mail at:
            </p>

            <address>
              Welltravel International Limited <br />
              Prime Center 1, 8060 <br />
              Zurich Airport, Switzerland
            </address>
          </div>
        </div>
      </div>
    </div>
  );
};

TermsBody.defaultProps = {
  className: '',
};

TermsBody.propTypes = {
  className: PropTypes.string,
};

export default TermsBody;
