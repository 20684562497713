import React from 'react';
import PropTypes from 'prop-types';

const SignUpFormHeader = ({ text, step }) => {
  return (
    <div className="sign-up-header">
      <div className="sign-up-header__text">{text}</div>
      {step && (
        <div className="sign-up-header__step">
          {I18n.t('components.sign_up.step', { step_number: step })}
        </div>
      )}
    </div>
  );
};

SignUpFormHeader.defaultProps = {
  step: null,
};

SignUpFormHeader.propTypes = {
  text: PropTypes.node.isRequired,
  step: PropTypes.number,
};

export default SignUpFormHeader;
