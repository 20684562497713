import '../../public/stylesheets/index.scss';

import WebpackerReact from 'webpacker-react';
import HeroTabWrapper from '../../public/components/HeroSlider/HeroTabWrapper';
import HomePage from '../../public/components/HomePage';
import Sitemap from '../../public/components/Sitemap';
import Navbar from '../../shared/components/Navbar';
import Footer from '../../public/components/Footer/index';
import SideNav from '../../shared/components/Sidebar/SideNav';
import Alert from '../../shared/components/Alert/Alert';
import SignUpConfigurator from '../../shared/components/SignUp/SignUpConfigurator';
import SignUpConfirmation from '../../shared/components/SignUp/SignUpConfirmation';
import SignUpOrganization from '../../shared/components/SignUp/SignUpOrganization';

WebpackerReact.setup({
  HeroTabWrapper,
  HomePage,
  Sitemap,
  Navbar,
  Footer,
});

// Shared Files
WebpackerReact.setup({
  SideNav,
  Alert,
  SignUpConfigurator,
  SignUpConfirmation,
  SignUpOrganization,
});
