import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';
import Alert from '@wtag/rcl-alert';
import AmericanAir from 'affiliateImages/logo-slider/american_airlines.svg';
import lufthansa from 'affiliateImages/logo-slider/lufthansa.svg';
import UnitedAir from 'affiliateImages/logo-slider/united_airlines.svg';
import britishAirways from 'affiliateImages/logo-slider/britishAirways.svg';
import scoot from 'affiliateImages/logo-slider/scoot.svg';
import swiss from 'affiliateImages/logo-slider/swiss_air.svg';
import emirates from 'affiliateImages/logo-slider/emirates.svg';
import qatarAirways from 'affiliateImages/logo-slider/qatar_airways.svg';
import flySafair from 'affiliateImages/logo-slider/flysafair.svg';
import HeroImage from './HeroImage';
import ClientQuotes from './ClientQuotes';
import Platform from './Platform';
import Services from './Services';
import Customer from './Customer';

const HomePage = ({ additionalProps, recaptchaKey, recaptchaWidgetSrc }) => {
  const { routes } = additionalProps;
  const scrollToDiv = ref => {
    window.scrollTo(0, ref.current.offsetTop);
  };
  const heroImageRef = useRef();
  const platformRef = useRef();

  return (
    <div className="homepage">
      <Alert
        isVisible={!!additionalProps.errorMessage}
        type="danger"
        hideClose={true}
        isIconVisible={false}
      >
        {additionalProps.errorMessage}
      </Alert>

      <HeroImage
        routes={routes}
        homePageRef={heroImageRef}
        onButtonClick={() => scrollToDiv(platformRef)}
      />
      <ClientQuotes />
      <section className="full-width tertiary-light-bg homepage__airelines">
        <div className="container">
          <div className="grid">
            <div className="col-grid col-lg-6 col-12 justify-center">
              <div className="homepage__airelines-header">
                <I18nText id="homepage.airlines_section.header" />
              </div>
            </div>
            <div className="col-grid offset-xlg-1 offset-0 col-xlg-5 col-lg-6 col-12 justify-center">
              <div className="col-12 col-bleed align-center">
                <div className="col-4">
                  <img
                    src={lufthansa}
                    alt="lufthansa"
                    className="homepage__airelines-img flex-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    src={AmericanAir}
                    alt="AmericanAir"
                    className="homepage__airelines-img flex-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    src={UnitedAir}
                    alt="UnitedAir"
                    className="homepage__airelines-img flex-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    src={britishAirways}
                    alt="britishAirways"
                    className="homepage__airelines-img flex-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    src={scoot}
                    alt="scoot"
                    className="homepage__airelines-img flex-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    src={swiss}
                    alt="swiss"
                    className="homepage__airelines-img flex-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    src={qatarAirways}
                    alt="qatarAirways"
                    className="homepage__airelines-img flex-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    src={emirates}
                    alt="emirates"
                    className="homepage__airelines-img flex-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    src={flySafair}
                    alt="flySafair"
                    className="homepage__airelines-img flex-img"
                  />
                </div>
              </div>
              <div className="col-12 col-bleed homepage__airelines-sub-header">
                <I18nText id="homepage.airlines_section.sub_header" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Platform
        routes={routes}
        platformFeaturesRoutes={additionalProps.platformFeaturesRoutes}
        homePageRef={platformRef}
      />
      <Services
        routes={routes}
        recaptchaKey={recaptchaKey}
        recaptchaWidgetSrc={recaptchaWidgetSrc}
      />
      <Customer />
    </div>
  );
};

HomePage.defaultProps = {
  additionalProps: PropTypes.shape({
    routes: null,
    errorMessage: null,
    platformFeaturesRoutes: null,
  }),
};

HomePage.propTypes = {
  additionalProps: PropTypes.shape({
    routes: PropTypes.shape({
      features: {
        platform: PropTypes.string,
      },
      pricing: {
        platform: PropTypes.string,
      },
      resources: {
        faqs: PropTypes.string,
        tutorials: PropTypes.string,
        glossary: PropTypes.string,
        documentation: PropTypes.string,
      },
      company: {
        about_us: PropTypes.string,
        careers: PropTypes.string,
        logos: PropTypes.string,
      },
      policies: {
        term: PropTypes.string,
        privacy: PropTypes.string,
        security: PropTypes.string,
      },
    }),
    platformFeaturesRoutes: PropTypes.shape({
      platformFeaturesProfiles: PropTypes.string,
      platformFeaturesSearch: PropTypes.string,
      platformFeaturesBooking: PropTypes.string,
      platformFeaturesMobileApp: PropTypes.string,
      platformFeaturesInvoicing: PropTypes.string,
      platformFeaturesOnlineBookingTool: PropTypes.string,
      platformFeaturesReporting: PropTypes.string,
      platformFeaturesTaskManagement: PropTypes.string,
      platformFeaturesVisaAdvisory: PropTypes.string,
      platformFeaturesCorporateTravel: PropTypes.string,
      platformFeaturesLeisurePackages: PropTypes.string,
      platformFeaturesChat: PropTypes.string,
    }),
    errorMessage: PropTypes.string,
  }),
  recaptchaKey: PropTypes.string.isRequired,
  recaptchaWidgetSrc: PropTypes.string.isRequired,
};

export default HomePage;
