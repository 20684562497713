import React from 'react';
import PropTypes from 'prop-types';

const Timeline = props => {
  const { data } = props;

  return (
    <div className="timeline">
      {data.map(event => (
        <div key={event.id} className="timeline__data">
          <div className="timeline__data-time">{event.time}</div>
          <div className="timeline__data-line">
            <div className="timeline__data-line-dot" />
            <div className="timeline__data-line-dash" />
          </div>
          <div className="timeline__data-details">{event.details}</div>
        </div>
      ))}
    </div>
  );
};

Timeline.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      time: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      details: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
        .isRequired,
    }),
  ).isRequired,
};

export default Timeline;
