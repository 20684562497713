import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Input from '@wtag/rcl-input';
import Button from '@wtag/rcl-button';
import { I18nText } from '@wtag/react-comp-lib';
import SignUpFormHeader from '../SignUpFormHeader';

const WorkspaceConfiguratorForm = ({ signUp, updateSignUp, errors }) => {
  const [legalAddress, setLegalAddress] = useState(signUp.legal_address);
  const [tenantName, setTenantName] = useState(signUp.tenant_name);
  const [tenantIdentifier, setTenantIdentifier] = useState(
    signUp.tenant_identifier,
  );

  const onClick = () => {
    const params = {
      locale: I18n.locale,
      sign_up: {
        tenant_name: tenantName,
        tenant_identifier: tenantIdentifier,
        legal_address: legalAddress,
      },
    };
    updateSignUp(params);
  };

  return (
    <div className="sign-up__form-container">
      <SignUpFormHeader
        step={2}
        text={<I18nText id="sign_up.tenant.form.header" />}
      />
      <div>
        <div className="sign-up__field">
          <Input
            onChange={value => setTenantName(value)}
            value={tenantName}
            label={
              <div className="required-field-wrapper">
                <I18nText id="sign_up.label.tenant_name" />
              </div>
            }
            error={errors.tenantName}
            touched={true}
          />
        </div>
        <div className="sign-up__field">
          <Input
            onChange={value => setLegalAddress(value)}
            value={legalAddress}
            label={
              <div className="required-field-wrapper">
                <I18nText id="sign_up.label.organizational_address" />
              </div>
            }
            error={errors.legalAddress}
            touched={!!errors.legalAddress}
          />
        </div>
        <div className="sign-up__field">
          <div className="sign-up__field-hint">
            <I18nText id="sign_up.tenant.form.hint.tenant_identifier" />
          </div>
          <div>
            <Input
              onChange={value => setTenantIdentifier(value)}
              value={tenantIdentifier}
              label={
                <div className="required-field-wrapper">
                  <I18nText id="sign_up.label.tenant_identifier" />
                </div>
              }
              error={errors.tenantIdentifier}
              touched={true}
            />
          </div>
        </div>
        <div>
          <Button
            version="v2"
            label={<I18nText id="sign_up.tenant.form.button" />}
            onClick={onClick}
            type="primary"
            size="small"
          />
        </div>
      </div>
    </div>
  );
};

WorkspaceConfiguratorForm.defaultProps = {
  errors: {},
};

WorkspaceConfiguratorForm.propTypes = {
  signUp: PropTypes.shape({
    tenant_name: PropTypes.string.isRequired,
    tenant_identifier: PropTypes.string.isRequired,
    legal_address: PropTypes.string.isRequired,
  }).isRequired,
  updateSignUp: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.string),
};

export default WorkspaceConfiguratorForm;
