import React from 'react';
import { createBrowserHistory } from 'history';
import { QueryParamProvider } from 'use-query-params';

const history = createBrowserHistory();

const withQueryParamsProvider = WrappedComponent => props => {
  const [, forceUpdate] = React.useReducer(x => x + 1, 0);
  React.useEffect(() => {
    // listen for changes to the URL (e.g. through an input field) and force the app to re-render
    history.listen(() => {
      forceUpdate();
    });
  }, []);

  return (
    <QueryParamProvider history={history}>
      <WrappedComponent {...props} />
    </QueryParamProvider>
  );
};

export default withQueryParamsProvider;
