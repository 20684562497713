export const APPCUES_SOURCE = '//fast.appcues.com/204956.js';
export const APPCUES_KEYS = [
  'Appcues',
  'AppcuesSettings',
  'AppcuesBundleSettings',
];
// PATH_TO_REMOVE_APPCUES_REGEX removes Appcues if the url
// ends with either one of [ billing.html , billing.html/ , billing , billing/ ]
// has either one of [billing? , billing/? , billing.html? , billing.html/? ] in it
export const PATH_TO_REMOVE_APPCUES_REGEX =
  /(billing\.html\/?$)|(billing\/?$)|(billing\??\/?$)|(billing\.html\??\/?$)/;
