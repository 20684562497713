import React from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';
import profile from 'affiliateImages/person-24px.svg';
import search from 'affiliateImages/search-24px.svg';
import booking from 'affiliateImages/flight-24px.svg';
import mobile from 'affiliateImages/mobile_friendly-24px.svg';
import invoicing from 'affiliateImages/description-24px.svg';
import obt from 'affiliateImages/flight_takeoff-24px.svg';
import report from 'affiliateImages/assignment-24px.svg';
import task from 'affiliateImages/format_list_bulleted-24px.svg';
import visa from 'affiliateImages/visa.svg';
import travel from 'affiliateImages/work-24px.svg';
import leisurePackage from 'affiliateImages/leisure.svg';
import chat from 'affiliateImages/chat-24px.svg';
import airplane from 'affiliateImages/airplane.svg';

const Platform = ({ routes, homePageRef, platformFeaturesRoutes }) => {
  const features = [
    {
      id: 0,
      icon: profile,
      name: <I18nText id="homepage.features.profiles.title" />,
      description: <I18nText id="homepage.features.profiles.description" />,
      link: platformFeaturesRoutes.platformFeaturesProfiles,
    },
    {
      id: 1,
      icon: search,
      name: <I18nText id="homepage.features.search.title" />,
      description: <I18nText id="homepage.features.search.description" />,
      link: platformFeaturesRoutes.platformFeaturesSearch,
    },
    {
      id: 2,
      icon: booking,
      name: <I18nText id="homepage.features.booking.title" />,
      description: <I18nText id="homepage.features.booking.description" />,
      link: platformFeaturesRoutes.platformFeaturesBooking,
    },
    {
      id: 3,
      icon: mobile,
      name: <I18nText id="homepage.features.mobile.title" />,
      description: <I18nText id="homepage.features.mobile.description" />,
      link: platformFeaturesRoutes.platformFeaturesMobileApp,
    },
    {
      id: 4,
      icon: invoicing,
      name: <I18nText id="homepage.features.invoice.title" />,
      description: <I18nText id="homepage.features.invoice.description" />,
      link: platformFeaturesRoutes.platformFeaturesInvoicing,
    },
    {
      id: 5,
      icon: obt,
      name: <I18nText id="homepage.features.obt.title" />,
      description: <I18nText id="homepage.features.obt.description" />,
      link: platformFeaturesRoutes.platformFeaturesOnlineBookingTool,
    },
    {
      id: 6,
      icon: report,
      name: <I18nText id="homepage.features.report.title" />,
      description: <I18nText id="homepage.features.report.description" />,
      link: platformFeaturesRoutes.platformFeaturesReporting,
    },
    {
      id: 7,
      icon: task,
      name: <I18nText id="homepage.features.task.title" />,
      description: <I18nText id="homepage.features.task.description" />,
      link: platformFeaturesRoutes.platformFeaturesTaskManagement,
    },
    {
      id: 8,
      icon: visa,
      name: <I18nText id="homepage.features.visa.title" />,
      description: <I18nText id="homepage.features.visa.description" />,
      link: platformFeaturesRoutes.platformFeaturesVisaAdvisory,
    },
    {
      id: 9,
      icon: travel,
      name: <I18nText id="homepage.features.corporate.title" />,
      description: <I18nText id="homepage.features.corporate.description" />,
      link: platformFeaturesRoutes.platformFeaturesCorporateTravel,
    },
    {
      id: 10,
      icon: leisurePackage,
      name: <I18nText id="homepage.features.leisure.title" />,
      description: <I18nText id="homepage.features.leisure.description" />,
      link: platformFeaturesRoutes.platformFeaturesLeisurePackages,
    },
    {
      id: 11,
      icon: chat,
      name: <I18nText id="homepage.features.chat.title" />,
      description: <I18nText id="homepage.features.chat.description" />,
      link: platformFeaturesRoutes.platformFeaturesChat,
    },
  ];

  return (
    <div className="homepage__platform-section" ref={homePageRef}>
      <div className="container">
        <div className="homepage__platform-section-header">
          <I18nText id="homepage.features.header.title" />
          <a
            href={platformFeaturesRoutes.platformFeaturesProfiles}
            className="button button--v2 button--small button--primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            <I18nText id="homepage.features.header.link" />
          </a>
        </div>
        <div className="grid">
          {features.map(({ icon, name, description, link, id }) => (
            <div className="col-grid col-xlg-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 col-xxs-12 align-stretch direction-row">
              <div
                className="col-grid col-12 homepage__platform-feature-card"
                key={id}
              >
                <div className="homepage__platform-feature-card-header">
                  <img
                    src={icon}
                    alt="icon"
                    className="homepage__platform-feature-card-icon"
                  />
                  <span className="homepage__platform-feature-card-name">
                    {name}
                  </span>
                </div>
                <div className="homepage__platform-feature-card-description">
                  {description}
                </div>
                <div className="homepage__platform-feature-card-link">
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    <I18nText id="homepage.features.read.link" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="homepage__platform-section-contact">
          <div className="homepage__platform-section-contact-header">
            <img
              src={airplane}
              alt="airplane"
              className="homepage__platform-section-takeoff-img img-responsive"
            />
            <I18nText id="homepage.features.sign_up.text" />
          </div>
          <div>
            <a
              href={routes.signUpUrl}
              className="button button--v2 button--small button--primary"
              target="_blank"
              rel="noopener noreferrer"
            >
              <I18nText id="homepage.features.sign_up.link" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

Platform.defaultProps = {
  homePageRef: PropTypes.shape({
    current: null,
  }),
  platformFeaturesRoutes: null,
};

Platform.propTypes = {
  routes: PropTypes.shape({
    signUpUrl: PropTypes.string.isRequired,
  }).isRequired,
  homePageRef: PropTypes.shape({
    current: PropTypes.node,
  }),
  platformFeaturesRoutes: PropTypes.shape({
    platformFeaturesProfiles: PropTypes.string,
    platformFeaturesSearch: PropTypes.string,
    platformFeaturesBooking: PropTypes.string,
    platformFeaturesMobileApp: PropTypes.string,
    platformFeaturesInvoicing: PropTypes.string,
    platformFeaturesOnlineBookingTool: PropTypes.string,
    platformFeaturesReporting: PropTypes.string,
    platformFeaturesTaskManagement: PropTypes.string,
    platformFeaturesVisaAdvisory: PropTypes.string,
    platformFeaturesCorporateTravel: PropTypes.string,
    platformFeaturesLeisurePackages: PropTypes.string,
    platformFeaturesChat: PropTypes.string,
  }),
};

export default Platform;
