import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SpinnerIcon from 'affiliateIcons/spinner.svg';
import routes from '../../routes';
import httpClient from '../../libraries/httpClient';
import WorkspaceTag from '../../../private/components/Workspace/WorkspaceTag';

const WorkspaceSettings = props => {
  const [workspaces, setWorkspaces] = useState([]);
  const [superAdmin, setSuperAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const ACTIVE_STATE = 'active';

  const fetchWorkspaces = async () => {
    setIsLoading(true);
    const { data } = await httpClient.get(
      routes.workspace.fetch({ affiliate_id: props.affiliateId, state: ACTIVE_STATE }),
    );
    setIsLoading(false);
    setWorkspaces(data.workspaces);
    setSuperAdmin(data.superAdmin);
  };

  useEffect(() => {
    fetchWorkspaces();
  }, []);

  return (
    <div className="navbar__workspaces">
      {isLoading && (
        <div className="navbar__sub-menu--workspaces-spinner">
          <img src={SpinnerIcon} alt="spinner" />
        </div>
      )}
      {workspaces && (
        <ul className="navbar__workspaces-content">
          {workspaces.map(({ acronym, name, agentUrl, identifier }, index) => (
            <li
              className="navbar__sub-menu-item navbar__sub-menu--workspaces-link"
              key={identifier}
            >
              <a className="navbar__sub-menu-link" href={agentUrl} target="_blank" rel="noreferrer">
                <WorkspaceTag
                  superAdmin={superAdmin}
                  workspaceIndex={index}
                  acronym={acronym}
                />
                <span className="navbar__sub-menu--workspaces-name">
                  {name}
                </span>
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

WorkspaceSettings.propTypes = {
  affiliateId: PropTypes.number.isRequired,
};

export default WorkspaceSettings;
