import React from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';
import headphone from 'affiliateImages/headphone.png';
import ContactPage from '../ContactPage';

const SupportSection = ({ signInUrl, recaptchaKey, recaptchaWidgetSrc }) => {
  return (
    <div className="support-section">
      <div className="support-section__info container-full">
        <div className="container">
          <div className="grid">
            <div className="col-lg-3 col-md-5 col-sm-3 col-12">
              <div className="support-section__logo">
                <img src={headphone} alt="support_headphone" />
              </div>
            </div>
            <div className="col-lg-9 col-md-7 col-sm-9 col-12">
              <div className="support-section__title">
                <I18nText id="supportSection.title" />
              </div>
              <br />
              <div className="support-section__details">
                <I18nText id="supportSection.text" />
              </div>
              <br />

              <div className="support-section__actions contact-us__wrapper">
                <ContactPage
                  linkText={
                    <div className="button button--v2 button--small button--primary">
                      <I18nText id="homepage.features.support.contact_us" />
                    </div>
                  }
                  signInUrl={signInUrl}
                  recaptchaKey={recaptchaKey}
                  recaptchaWidgetSrc={recaptchaWidgetSrc}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SupportSection.propTypes = {
  signInUrl: PropTypes.string.isRequired,
  recaptchaKey: PropTypes.string.isRequired,
  recaptchaWidgetSrc: PropTypes.string.isRequired,
};

export default SupportSection;
