import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { I18nText, Link } from '@wtag/react-comp-lib';
import tenantIcon from 'affiliateImages/sign-up/tenant.svg';
import userIcon from 'affiliateImages/sign-up/user.svg';
import SignUpIconHeader from '../SignUpIconHeader';
import WorkspaceConfiguratorForm from './WorkspaceConfiguratorForm';
import SignUpFormHeader from '../SignUpFormHeader';
import routes from '../../../routes';
import httpClient from '../../../libraries/httpClient';

const SignUpConfigurator = props => {
  const [currentState, setCurrentState] = useState(props.currentState);
  const [isPollingInitiated, setIsPollingInitiated] = useState(false);
  const [errors, setErrors] = useState({});

  const CONFIRMED_STATE = 'confirmed';
  const PREPARING_APPLICATIONS_STATE = 'preparing_applications';
  const APPLICATION_PREPARED_STATE = 'applications_prepared';
  const SIGNED_UP_WITH_EXISTING_ACCOUNT = 'account_exists';

  const updateSignUp = params => {
    httpClient
      .put(routes.signUp.update({ id: props.signUp.id }), params)
      .then(({ data }) => {
        if (data.redirectUrl) {
          window.location.replace(data.redirectUrl);
        }
        setCurrentState(data.currentState);
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          setCurrentState(response.data.currentState);
          setErrors(response.data.errors);
        }
      });
  };

  const executeFetchCall = async () => {
    const data = await httpClient.get(
      routes.signUp.fetch({ id: props.signUp.id }),
    );

    return data;
  };

  const fetchSignUpStatus = async () => {
    const { data } = await executeFetchCall();
    if (currentState !== data.currentState) {
      setCurrentState(data.currentState);
    }
    if (data.applicationPrepared) {
      window.location.replace(data.redirectUrl);
    } else {
      setTimeout(fetchSignUpStatus, 3000);
    }
  };

  useEffect(() => {
    if (currentState === PREPARING_APPLICATIONS_STATE && !isPollingInitiated) {
      fetchSignUpStatus();
      setIsPollingInitiated(true);
    }
  }, [currentState]);

  const content = {};
  switch (currentState) {
    case CONFIRMED_STATE:
      content.iconHeader = (
        <SignUpIconHeader
          icon={tenantIcon}
          text={<I18nText id="sign_up.tenant.header" />}
        />
      );
      content.form = (
        <WorkspaceConfiguratorForm
          signUp={props.signUp}
          updateSignUp={updateSignUp}
          errors={errors}
        />
      );
      break;
    case PREPARING_APPLICATIONS_STATE:
    case APPLICATION_PREPARED_STATE:
      content.iconHeader = (
        <SignUpIconHeader
          text={<I18nText id="sign_up.preparing_organization.header" />}
        />
      );
      content.form = (
        <div className="sign-up__form-container">
          <div className="sign-up__pending-header">
            <SignUpFormHeader
              text={<I18nText id="sign_up.preparing_organization.header" />}
            />
          </div>
          <div className="sign-up__pending-hint">
            <I18nText id="sign_up.preparing_organization.hint" />
          </div>
        </div>
      );
      break;
    case SIGNED_UP_WITH_EXISTING_ACCOUNT:
      content.iconHeader = (
        <SignUpIconHeader
          text={<I18nText id="sign_up.user_configuration.header" />}
          icon={userIcon}
        />
      );
      content.form = (
        <div className="sign-up__form-container--fixed-width">
          <div className="sign-up__error-header">
            <SignUpFormHeader
              text={<I18nText id="sign_up.user_configuration.subheader" />}
            />
          </div>
          <div className="sign-up__error-hint">
            <div className="sign-up__error-hint-header">
              <I18nText id="sign_up.user_configuration.hint_header" />
            </div>
            <br />
            {props.userEmail &&
              I18n.t('components.sign_up.user_configuration.hint', {
                email: props.userEmail,
              })}
          </div>
          {props.redirectUrl && (
            <Link
              href={props.redirectUrl}
              type="button"
              size="small"
              modifier="default"
            >
              <I18nText id="sign_up.user_configuration.button" />
            </Link>
          )}
        </div>
      );
      break;
    default:
  }

  return (
    <div className="sign-up">
      <div className="sign-up__container">
        <div className="col-6 sign-up__icon">{content.iconHeader}</div>
        <div className="col-xlg-6 col-lg-6 col-md-6 col-12 sign-up__form">
          {content.form}
        </div>
      </div>
    </div>
  );
};

SignUpConfigurator.defaultProps = {
  userEmail: null,
  redirectUrl: null,
  signUp: {},
};

SignUpConfigurator.propTypes = {
  signUp: PropTypes.shape({
    id: PropTypes.number.isRequired,
    user_first_name: PropTypes.string.isRequired,
    user_last_name: PropTypes.string.isRequired,
    user_email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
  }),
  currentState: PropTypes.string.isRequired,
  userEmail: PropTypes.string,
  redirectUrl: PropTypes.string,
};

export default SignUpConfigurator;
