import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { I18nText, Toggle } from '@wtag/react-comp-lib';
import routes from '../../../shared/routes';
import httpClient from '../../../shared/libraries/httpClient';
import PlanCard from '../../../private/components/Billing/PlanCard';

const MONTH_INDICATOR_KEY = 'monthly';
const YEAR_INDICATOR_KEY = 'yearly';

const PlatformPlans = ({ signUpUrl }) => {
  const [plans, setPlans] = useState([]);
  const [freePlans, setFreePlans] = useState([]);
  const [paidPlans, setPaidPlans] = useState([]);
  const [showYear, setShowYear] = useState(false);

  const fetchPlans = async () => {
    const { data } = await httpClient.get(routes.plans.list());
    setFreePlans(data.plans.filter(plan => parseFloat(plan.price) <= 0));
    setPaidPlans(data.plans.filter(plan => parseFloat(plan.price) > 0));
  };

  useEffect(() => {
    if (showYear) {
      setPlans(() => [
        ...freePlans,
        ...paidPlans.filter(plan => plan.period === YEAR_INDICATOR_KEY),
      ]);
    } else {
      setPlans(() => [
        ...freePlans,
        ...paidPlans.filter(plan => plan.period === MONTH_INDICATOR_KEY),
      ]);
    }
  }, [freePlans, paidPlans, showYear]);

  useEffect(() => {
    fetchPlans();
  }, []);

  const plansCard = plans.map(plan => (
    <div className="col-12 col-md-6 col-lg-4 col-xlg-3" key={plan.id}>
      <PlanCard
        className="platform-plans__plan-card"
        id={plan.id}
        name={plan.name}
        price={plan.price}
        currency={plan.currencyCode}
        period={plan.period}
        identifier={plan.identifier}
        searches={plan.searches}
        bookings={plan.bookings}
        travelers={plan.travelers}
        supportTicket={plan.supportTicket}
        phone={plan.phone}
        channels={plan.channels}
        workspaces={plan.workspaces}
        domain={plan.domain}
        affiliateDeactivated={false}
        isDowngrading={false}
        canChangePlan={false}
        signUpUrl={signUpUrl}
        showSignUpLink={true}
      />
    </div>
  ));

  return (
    <div className="platform-plans__wrapper">
      <div className="grid">
        <div className="platform-plans__header col-12">
          <I18nText id="pricing.plans.header" />
        </div>
        <div className="col-12">
          <Toggle
            firstOption={<I18nText id="pricing.plans.toggle_label.left" />}
            secondOption={<I18nText id="pricing.plans.toggle_label.right" />}
            onChange={() => setShowYear(!showYear)}
            isChecked={showYear}
            size="tiny"
          />
        </div>
      </div>
      <div className="platform-plans">
        <div className="col-10 col-offset-1">
          <div className="grid grid-gap-20">{plansCard}</div>
        </div>
      </div>
    </div>
  );
};

PlatformPlans.propTypes = {
  signUpUrl: PropTypes.string.isRequired,
};

export default PlatformPlans;
