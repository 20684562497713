import React from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';
import welltravelWhite from 'affiliateImages/welltravel_logo_white.png';
import welltravelDark from 'affiliateImages/welltravel_logo_dark.png';
import welltravelIconWhite from 'affiliateImages/welltravel_icon_white.png';
import welltravelIconWhiteSmall from 'affiliateImages/welltravel_icon_white_small.png';
import welltravelIconDark from 'affiliateImages/welltravel_icon_dark.png';
import welltravelIconDarkSmall from 'affiliateImages/welltravel_icon_dark_small.png';
import plane from 'affiliateImages/plane.svg';
import ContactPage from '../../../public/components/ContactPage';

const logoData = [
  {
    logo: welltravelWhite,
    name: 'Welltravel Logo — Horizontal.png',
    link: (
      <a
        href={welltravelWhite}
        download={true}
        className="button button--v2 button--primary button--small"
      >
        <I18nText id="logo.link" />
      </a>
    ),
  },
  {
    logo: welltravelIconWhite,
    name: 'Welltravel — Icon.png',
    link: (
      <a
        href={welltravelIconWhite}
        download={true}
        className="button button--v2 button--primary button--small"
      >
        <I18nText id="logo.link" />
      </a>
    ),
  },
  {
    logo: welltravelIconWhiteSmall,
    name: 'Welltravel — favicon.png',
    link: (
      <a
        href={welltravelIconWhiteSmall}
        download={true}
        className="button button--v2 button--primary button--small"
      >
        <I18nText id="logo.link" />
      </a>
    ),
  },
  {
    logo: welltravelDark,
    name: 'Welltravel Logo — Horizontal - Dark.png',
    link: (
      <a
        href={welltravelDark}
        download={true}
        className="button button--v2 button--primary button--small"
      >
        <I18nText id="logo.link" />
      </a>
    ),
  },
  {
    logo: welltravelIconDark,
    name: 'Welltravel — Icon — Dark.png',
    link: (
      <a
        href={welltravelIconDark}
        download={true}
        className="button button--v2 button--primary button--small"
      >
        <I18nText id="logo.link" />
      </a>
    ),
  },
  {
    logo: welltravelIconDarkSmall,
    name: 'Welltravel — favicon — Dark.png',
    link: (
      <a
        href={welltravelIconDarkSmall}
        download={true}
        className="button button--v2 button--primary button--small"
      >
        <I18nText id="logo.link" />
      </a>
    ),
  },
];

const Media = ({ additionalProps, recaptchaKey, recaptchaWidgetSrc }) => {
  return (
    <div className="media__wrapper">
      <div className="media__contact-us-section">
        <div className="grid justify-center">
          <div className="col-xlg-10 col-lg-10 col-md-10 col-12">
            <div className="company-logos justify-center">
              <div className="grid">
                <div className="col-xlg-6 col-lg-6 col-md-5 col-sm-12 col-xs-12 col-xxs-12">
                  <span className="media__header">
                    <I18nText id="media.header" />
                  </span>
                </div>
                <div className="col-xlg-6 col-lg-6 col-md-7 col-sm-12 col-xs-12 col-xxs-12">
                  <div>
                    <span className="media__sub-header">
                      <I18nText id="media.sub_header" />
                    </span>
                  </div>
                  <div>
                    <p className="media__description media__description-contact">
                      <I18nText id="media.description" />{' '}
                      <a
                        href={additionalProps.routes.resources.faqs}
                        className="media__description-link"
                      >
                        <I18nText id="media.faq" />
                      </a>{' '}
                      <I18nText id="media.search_description" />{' '}
                      <a
                        href={additionalProps.routes.signInUrl}
                        className="media__description-link"
                      >
                        <I18nText id="media.sign_in" />
                      </a>{' '}
                      <I18nText id="media.ticket_description" />
                    </p>
                  </div>
                  <div className="support-section__actions contact-us__wrapper">
                    <ContactPage
                      linkText={
                        <div className="button button--v2 button--medium button--primary">
                          <I18nText id="homepage.features.support.contact_us" />
                        </div>
                      }
                      signInUrl={additionalProps.routes.signInUrl}
                      recaptchaKey={recaptchaKey}
                      recaptchaWidgetSrc={recaptchaWidgetSrc}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="media__company-overview-section">
        <div className="media__company-overview-section-bg">
          <div className="grid justify-center">
            <div className="col-xlg-10 col-lg-10 col-md-10 col-12">
              <div className="justify-center">
                <div className="media__header">
                  <I18nText id="media.overview.header" />
                </div>
                <div className="grid">
                  <div className="col-xlg-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-xxs-12">
                    <div className="media__overview-header">
                      <I18nText id="media.overview.header_one" />
                    </div>
                    <div className="media__overview-description">
                      <I18nText id="media.overview.description_two" />
                    </div>
                  </div>
                  <div className="col-xlg-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-xxs-12">
                    <div className="media__overview-header">
                      <I18nText id="media.overview.header_four" />
                    </div>
                    <div className="media__overview-description">
                      <ul>
                        <li>• Philipp Wellstein (CEO)</li>
                        <li>• Mujadded Al Rabbani Alif (CTO)</li>
                        <li>• Simon Benz (CPO)</li>
                        <li>• Raffael Schmid (CQSO)</li>
                        <li>• Andrew Adhikary (CFO)</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="media__company-details-section">
        <div className="grid justify-center">
          <div className="col-xlg-10 col-lg-10 col-md-10 col-12">
            <div className="justify-center">
              <div className="media__header">
                <I18nText id="media.details.header" />
              </div>
              <div className="grid">
                <div className="col-xlg-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-xxs-12">
                  <div className="media__overview-header">
                    <I18nText id="media.details.header_one" />
                  </div>
                  <div className="media__overview-description">
                    <ul>
                      <li>
                        <span>
                          <I18nText id="media.details.company" />:{' '}
                        </span>
                        <span>Welltravel International Ltd.</span>
                      </li>
                      <li>
                        <span>
                          <I18nText id="media.details.industry" />:{' '}
                        </span>

                        <span>
                          SaaS <I18nText id="media.details.travel" />
                        </span>
                      </li>
                      <li>
                        <span>
                          <I18nText id="media.details.hq" />:{' '}
                        </span>
                        <span>Zurich Airport, Switzerland</span>
                      </li>
                      <li>
                        <span>
                          <I18nText id="media.details.size" />:{' '}
                        </span>
                        <span>
                          60+ <I18nText id="media.details.employees" />
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xlg-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-xxs-12">
                  <div className="media__overview-header">
                    <I18nText id="media.details.header_two" />
                  </div>
                  <div className="media__overview-description">
                    <ul>
                      <li>
                        <span>
                          <I18nText id="media.details.products" />:{' '}
                        </span>
                        <span>The Welltravel Ecosystem</span>
                      </li>
                      <li>
                        <span>
                          <I18nText id="media.details.platforms" />:{' '}
                        </span>
                        <span>Agent Desktop, Travelapp, Affiliates</span>
                      </li>
                      <li>
                        <span>
                          <I18nText id="media.details.mobile_apps" />:{' '}
                        </span>
                        <span>iOS & Android</span>
                      </li>
                    </ul>
                    <div>
                      <p>
                        <I18nText id="media.details.description" />{' '}
                        <span>
                          <a
                            href={additionalProps.routes.features.platform}
                            className="media__description-anchor"
                          >
                            <I18nText id="media.features" />
                          </a>
                        </span>{' '}
                        <I18nText id="media.details.description_ecosystem" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="media__contact-us-section media__serve-section">
        <div className="grid justify-center">
          <div className="col-xlg-10 col-lg-10 col-md-10 col-12">
            <div className="company-logos justify-center">
              <div className="media__header">
                <I18nText id="media.serve.header" />
              </div>
              <div className="grid">
                <div className="col-xlg-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-xxs-12">
                  <div className="media__overview-header">
                    <I18nText id="media.serve.header_one" />
                  </div>
                  <div className="media__overview-description">
                    <ul>
                      <li>
                        <I18nText id="media.serve.list_item_one" />
                      </li>
                      <li>
                        <I18nText id="media.serve.list_item_two" />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xlg-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-xxs-12">
                  <div className="media__overview-header">
                    <I18nText id="media.serve.header_two" />
                  </div>
                  <div className="media__overview-description">
                    <ul>
                      <li>
                        <I18nText id="media.serve.list_two.one" />
                      </li>
                      <li>
                        <I18nText id="media.serve.list_two.two" />
                      </li>
                      <li>
                        <I18nText id="media.serve.list_two.four" />
                      </li>
                      <li>
                        <I18nText id="media.serve.list_two.five" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="media__get-epk-section">
        <div className="grid justify-center">
          <div className="col-xlg-10 col-lg-10 col-md-10 col-12">
            <div className="company-logos justify-center">
              <div className="grid">
                <div className="col-xlg-6 col-lg-6 col-md-5 col-sm-12 col-xs-12 col-xxs-12">
                  <span className="media__header">
                    <I18nText id="media.epk.header" />
                  </span>
                </div>
                <div className="col-xlg-6 col-lg-6 col-md-7 col-sm-12 col-xs-12 col-xxs-12">
                  <div>
                    <span className="media__sub-header">
                      <I18nText id="media.epk.sub_header" />
                    </span>
                  </div>
                  <div className="media__link">
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="/p/resources/electronic_press_kit"
                      className="button button--v2 button--medium"
                    >
                      <I18nText id="media.epk.link" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="media__company-story-section">
        <div className="media__company-story-section-bg">
          <div className="grid justify-center">
            <div className="col-xlg-10 col-lg-10 col-md-10 col-12">
              <div className="justify-center">
                <div className="grid">
                  <div className="col-xlg-6 col-lg-6 col-md-5 col-sm-12 col-xs-12 col-xxs-12">
                    <div className="media__header">
                      <I18nText id="media.story.header_one" />
                    </div>
                    <div className="media__sub-header">
                      <I18nText id="media.story.sub_header" />
                    </div>
                    <div className="media__image">
                      <img src={plane} alt="plane" className="img-responsive" />
                    </div>
                  </div>
                  <div className="col-xlg-6 col-lg-6 col-md-7 col-sm-12 col-xs-12 col-xxs-12">
                    <div>
                      <div className="media__sub-header">
                        <I18nText id="media.story.sub_header_one" />
                      </div>
                      <div className="media__overview-description-links">
                        <I18nText id="media.story.description_one" />
                      </div>
                    </div>
                    <div className="media__story-description">
                      <div className="media__sub-header">
                        <I18nText id="media.story.sub_header_two" />
                      </div>
                      <div className="media__overview-description-links">
                        <I18nText id="media.story.description_two" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="media__contact-us-section">
        <div className="grid justify-center">
          <div className="col-xlg-10 col-lg-10 col-md-10 col-12">
            <div className="company-logos justify-center">
              <div className="grid">
                <div className="col-xlg-6 col-lg-6 col-md-5 col-sm-12 col-xs-12 col-xxs-12">
                  <span className="media__header">
                    <I18nText id="media.brochure.header" />
                  </span>
                </div>
                <div className="col-xlg-6 col-lg-6 col-md-7 col-sm-12 col-xs-12 col-xxs-12">
                  <div>
                    <span className="media__description">
                      <I18nText id="media.brochure.sub_header" />
                    </span>
                  </div>
                  <div className="media__brochure-button">
                    <a
                      className="button button--v2 button--small button--primary"
                      rel="noopener noreferrer"
                      target="_blank"
                      href="/p/resources/brochure"
                    >
                      <I18nText id="media.brochure.download" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="media__logos-section">
        <div className="grid justify-center">
          <div className="col-xlg-10 col-lg-10 col-md-10 col-12">
            <div className="company-logos justify-center">
              <div className="grid">
                <div className="col-xlg-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-xxs-12">
                  <div className="media__header">
                    <I18nText id="media.resource.header" />
                  </div>
                  <div>
                    <span className="media__description">
                      <I18nText id="media.resource.sub_header" />
                    </span>
                  </div>
                </div>

                <div className="col-12">
                  <div className="media__brochure-button">
                    <div>
                      <div className="grid company-logos__logos">
                        {logoData.map(({ logo, name, link }) => (
                          <div
                            className="col-xlg-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 col-xxs-12 company-logos__section"
                            key={name}
                          >
                            <div>
                              <img
                                src={logo}
                                alt="logos"
                                className="img-responsive"
                              />
                            </div>
                            <div className="company-logos__name">{name}</div>
                            {link}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Media.defaultProps = {
  additionalProps: {
    routes: {
      signInUrl: '',
      features: {
        platform: '',
      },
      resources: {
        faqs: '',
      },
    },
  },
};

Media.propTypes = {
  additionalProps: PropTypes.shape({
    routes: PropTypes.shape({
      signInUrl: PropTypes.string,
      features: PropTypes.shape({
        platform: PropTypes.string,
      }),
      resources: PropTypes.shape({
        faqs: PropTypes.string,
      }),
    }),
  }),
  recaptchaKey: PropTypes.string.isRequired,
  recaptchaWidgetSrc: PropTypes.string.isRequired,
};

export default Media;
